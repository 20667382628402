import React, { useState, useEffect, useCallback } from 'react';
import merchantService from '../../services/merchantService';
import FilterComponent from '../../components/FilterComponent';
import styles from './Payments.module.scss';
import Modal from 'react-modal';
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import stripeLogo from '../../images/stripeLogo.png';
import PaymentsTable from './components/payments/PaymentsTable';
import RefundsTable from './components/payments/RefundsTable';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

Modal.setAppElement('#root');

function Payments() {
    useEffect(() => {
        document.title = 'Payments | Qiwi';
    }, []);

    const [payments, setPayments] = useState([]);
    const [refunds, setRefunds] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [noActivePayments, setNoActivePayments] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [loading, setLoading] = useState(true);
    const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);
    const [wallet, setWallet] = useState(0);
    const [showStripeModal, setShowStripeModal] = useState(false);
    const [showAmountModal, setShowAmountModal] = useState(false);
    const [amountToAdd, setAmountToAdd] = useState(null);
    const [option, setOptions] = useState(null);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successTotalSum, setSuccessTotalSum] = useState(0);
    const [selectedCardType, setSelectedCardType] = useState('visa_mastercard');
    const [options] = useState(['Active', 'Archived', 'Refunds']);
    const [activeOption, setActiveOption] = useState(options[0]); // Första alternativet är aktivt som standard
    const [appliedFilters, setAppliedFilters] = useState({
        dateRange: [],
        searchQuery: "",
      });

    const calculateNetAmountInEURO = (amountInEURO, cardType) => {
        let feePercentage = 0;
        let fixedFeeEURO = 0.18; // Fixed fee in EURO

        // Set the fee percentage based on the card type
        switch (cardType) {
            case "visa_mastercard":
                feePercentage = 0.019; // 1.9% for Visa/Mastercard
                break;
            case "amex":
                feePercentage = 0.015; // 1.5% for American Express
                break;
            default:
                return amountInEURO; // If no card type is selected, return the entered amount in EURO
        }

        // Calculate the fee in EURO
        const feeEURO = amountInEURO * feePercentage + fixedFeeEURO;
        const netAmountEURO = amountInEURO - feeEURO;

        return netAmountEURO > 0 ? netAmountEURO : 0; // Ensure that net amount is never negative
    };

    useEffect(() => {
        const checkAndFetch = async () => {
            const query = new URLSearchParams(window.location.search);
            const sessionIdFromUrl = query.get('session_id');

            if (sessionIdFromUrl) {
                await checkPaymentStatus(sessionIdFromUrl);
            }
            fetchInfo();
        };
        setLoadingInitialInfo(true)
        checkAndFetch();
    }, []);

    useEffect(() => {
        if (activeOption === 'Refunds') {
            fetchRefundData();
        } else {
            fetchPaymentData();
        }
    }, [currentPage, itemsPerPage, activeOption, appliedFilters]);

    const checkPaymentStatus = async (sessionId) => {
        try {
            const data = await merchantService.checkPaymentStatus(sessionId);
            if (data.status === 'complete') {
                fetchPaymentData();
                setSuccessTotalSum(data.amount_total);
                setShowSuccessModal(true);
                const url = new URL(window.location);
                url.searchParams.delete('session_id');
                window.history.replaceState({}, document.title, url.toString());
            }
        } catch (error) {
            console.error('Error checking payment status:', error);
        }
    };

    const fetchPaymentData = async () => {
        try {
            setLoading(true);
            const { dateRange, searchQuery } = appliedFilters;
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const showArchived = activeOption === 'Archived';
            const data = await merchantService.fetchPaymentsByPageAndFilters(currentPage, itemsPerPage, startDate, endDate, showArchived, searchQuery);
            setPayments(data.payments);
            setTotalPages(data.totalPages);

            if (initialLoad) {
                if (!data.payments.length) {
                    setNoActivePayments(true);
                    setFiltersApplied(false);
                } else {
                    setNoActivePayments(false);
                    setFiltersApplied(true);
                }
                setInitialLoad(false);
            } else {
                setFiltersApplied(true);
                setNoActivePayments(!data.payments.length);
            }

            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch payments:', error);
        }
    };

    const fetchRefundData = async () => {
        try {
            setLoading(true);
            const { dateRange, searchQuery } = appliedFilters;
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const data = await merchantService.fetchRefundsByPageAndFilters(currentPage, itemsPerPage, startDate, endDate, searchQuery);
            setRefunds(data.refunds);
            setTotalPages(data.totalPages);
            if (initialLoad) {
                if (!data.refunds.length) {
                    setNoActivePayments(true);
                    setFiltersApplied(false);
                } else {
                    setNoActivePayments(false);
                    setFiltersApplied(true);
                }
                setInitialLoad(false);
            } else {
                setFiltersApplied(true);
                setNoActivePayments(!data.refunds.length);
            }
            setLoading(false);
        } catch (error) {
            console.error('Failed to fetch refunds:', error);
        }
    };

    const fetchInfo = async () => {
        try {
            setLoadingInitialInfo(true)
            const data = await merchantService.fetchPaymentInfo();
            setWallet(data.wallet);
            setLoadingInitialInfo(false)
        } catch (error) {
            console.error('Failed to fetch payment info:', error);
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...(activeOption === 'Refunds' ? refunds : payments)].sort((a, b) => {
            if (a[key] === undefined) return 0; // Safety check if key doesn't exist
            if (key === 'createdAt') {
                const dateA = new Date(a[key]);
                const dateB = new Date(b[key]);
                return direction === 'ascending' ? dateA - dateB : dateB - dateA;
            }
            if (typeof a[key] === 'string') {
                return direction === 'ascending' ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            }
            return direction === 'ascending' ? a[key] - b[key] : b[key] - a[key];
        });

        if (activeOption === 'Refunds') {
            setRefunds(sortedData);
        } else {
            setPayments(sortedData);
        }
    };

    const handleAmountClick = () => {
        setShowAmountModal(true);
    };

    const handleCloseAmountModal = () => {
        setShowAmountModal(false);
    };

    const handleStripeClick = () => {
        if (amountToAdd < 10) {
            alert("You need to at least refill your wallet with 10€");
            return;
        }
        fetchClientSecret();
        setShowAmountModal(false);
    };

    const handleCloseStripeModal = () => {
        setShowStripeModal(false);
    };

    const handleClosePaymentSuccessModal = () => {
        setShowSuccessModal(false);
    };

    const fetchClientSecret = useCallback(async () => {
        try {
            const data = await merchantService.createCheckoutSession(amountToAdd);
            setOptions(data);
        } catch (error) {
            console.error('Failed to fetch session ID:', error);
        }
    }, [amountToAdd]);

    useEffect(() => {
        if (option) {
            setShowStripeModal(true);
        }
    }, [option]);

    const handleApplyFilters = (filters) => {
        setAppliedFilters(filters);
        setCurrentPage(1);
      };

      const handleOptionClick = (option) => {
        setInitialLoad(true);
        setActiveOption(option);
          setAppliedFilters({
            dateRange: [],
            searchQuery: "",
          });
        setCurrentPage(1);
      };

    return (
        <div className={styles.container}>
            <div className={styles.infoContainers}>
                <div className={`${styles.infoBoxWithDetails} ${loadingInitialInfo && styles.loadinginfobox}`}>
                    {!loadingInitialInfo && (
                        <>
                            <div className={styles.mainInfo}>
                                <div>
                                    <span className="material-symbols-outlined">
                                        wallet
                                    </span>
                                </div>
                                <div className={styles.info}>
                                    <h2>{wallet}€</h2>
                                    <p>WALLET</p>
                                </div>
                            </div>
                            <div className={styles.refillButtonContainer}>
                                <p>FILL WALLET</p>
                                <button className={styles.stripeBtn} onClick={handleAmountClick}>
                                    <img src={stripeLogo} alt="Stripe Logo" />
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.filterHeader}>
                <div className={styles.optionContainer}>
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleOptionClick(option)}
            className={`${styles.optionButton} ${
              activeOption === option ? styles.active : ""
            }`}
            >
            {option}
          </button>
        ))}
      </div>
                    <FilterComponent
  initialFilters={appliedFilters}
  onApplyFilters={handleApplyFilters}
  filtersDisabled={(!filtersApplied && noActivePayments)}
  />
                                    </div>

                            {activeOption === 'Refunds' ? (
                <RefundsTable
                    refunds={refunds}
                    loading={loading}
                    sortConfig={sortConfig}
                    handleSort={handleSort}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={setCurrentPage}
                    filtersApplied={filtersApplied}
                />
            ) : (
                <PaymentsTable
                    payments={payments}
                    loading={loading}
                    sortConfig={sortConfig}
                    handleSort={handleSort}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={setCurrentPage}
                    showArchived={activeOption === 'Archived'}
                    filtersApplied={filtersApplied}
                />
            )}
                        
                    </div>
                    <Modal
  isOpen={showAmountModal}
  onRequestClose={handleCloseAmountModal}
  className={styles.amountModal}
  overlayClassName={styles.overlay}
>
  <div className={styles.amountModalContent}>
    <h2>Refill Wallet</h2>

    <div  className={styles.amountInputContainer}>
    <div>
        <label>Payment amount</label>
        <div className={styles.amountInput}>
        <p>EURO</p>
        <input
  type="number"
  step="0.01"
  value={amountToAdd}
  onChange={(e) => setAmountToAdd(e.target.value)} // Behandla som en sträng
  onBlur={() => setAmountToAdd(Number(amountToAdd))} // Konvertera till nummer på blur
/>

    </div>
    </div>
    {/* Dropdown for selecting the card type */}
    <div className={styles.feeContainer}>
    <div className={styles.cardSelectContainer}>
        <label>* Card Fees Estimates</label>
        <div className={styles.cardWithInfo}>
        <select
                                value={selectedCardType}
                                onChange={(e) => setSelectedCardType(e.target.value)}
                                className={styles.cardSelect}
                            >
                                <option value="visa_mastercard">
                                    <p>Visa/Mastercard EES</p>
                                </option>
                                <option value="amex">
                                    <p>AMEX EES</p>
                                </option>
                            </select>
                            <p>
                                    {selectedCardType === "visa_mastercard" ? "2% + 0.40€" : "1.6% + 0.40€"} fee
                            </p>
                            </div>                            
    </div>
    </div>
    <div className={styles.netAmount}>
      {selectedCardType && (
        <>
        <p>
          Net Amount to Wallet:{" "}
        </p>
          <p className={styles.netAmountNum}>
          EURO {calculateNetAmountInEURO(amountToAdd, selectedCardType)}
          </p>
          <span className={styles.feeInfo}>
          * The selected card type provides an estimated fee. Actual fees and wallet amounts may vary based on the actual payment method. The fee displayed is the maximum for the selected card.
</span>
        </>
      )}
    </div>

    {/* Modal actions */}
    <div className={styles.modalActions}>
      <button onClick={handleStripeClick} className={styles.submitButton}>
        Proceed to Payment
      </button>
      <button onClick={handleCloseAmountModal} className={styles.closeButton}>
        Close
      </button>
    </div>
    </div>
  </div>
</Modal>

            <Modal
                isOpen={showStripeModal}
                onRequestClose={handleCloseStripeModal}
                className={styles.stripeModal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.stripeModalContent}>
                    <button onClick={handleCloseStripeModal} className={styles.closeButtonStripe}>
                    <span class="material-symbols-outlined">
                    close
                    </span>
                    </button>
                    <EmbeddedCheckoutProvider stripe={stripePromise} options={option}>
                        <EmbeddedCheckout />
                    </EmbeddedCheckoutProvider>
                </div>
            </Modal>
            <Modal
                isOpen={showSuccessModal}
                onRequestClose={handleClosePaymentSuccessModal}
                className={styles.paymentStatusModal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.paymentStatusModalContent}>
                    <h2>Payment Successful!</h2>
                    <p>Your wallet has been topped up successfully with <strong>{successTotalSum.toFixed(2)}€</strong></p>
                    <button onClick={handleClosePaymentSuccessModal} className={styles.closeButton}>Close</button>
                </div>
            </Modal>
        </div>
    );
}

export default Payments;
