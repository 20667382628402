import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CreateSupportTicket.module.scss';
import userService from '../../services/userService';

function CreateSupportTicket() {
    useEffect(() => {
        document.title = 'Create Ticket | Qiwi';
      }, []);

    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [mail, setMail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await userService.createTicket(subject, message, mail);
            alert('Support ticket created successfully!');
            navigate('../support');
        } catch (error) {
            console.error('Error creating support ticket:', error);
            alert('Failed to create support ticket. Please try again.');
        }
    };

    const handleChange = (setter, maxLength) => (event) => {
        if (event.target.value.length <= maxLength) {
            setter(event.target.value);
        }
    };

    return (
        <div className={styles.supportContainer}>
            <div className={styles.header}>
                <h2>Create Ticket</h2>
            </div>
            <div className={styles.formContainer}>
                <form onSubmit={handleSubmit} className={styles.form}>
                    <div className={styles.formField}>
                        <label htmlFor="subject"><span style={{ color: 'red' }}>*</span>Subject:</label>
                        <input
                            type="text"
                            id="subject"
                            value={subject}
                            onChange={handleChange(setSubject, 40)} // Max 40 tecken
                            required
                        />
                    </div>
                    <div className={styles.formField}>
                        <label htmlFor="message"><span style={{ color: 'red' }}>*</span>Message:</label>
                        <textarea
                            id="message"
                            rows="4"
                            value={message}
                            onChange={handleChange(setMessage, 1000)} // Max 1000 tecken
                            required
                        />
                    </div>
                    <div className={styles.formField}>
                        <label htmlFor="mail"><span style={{ color: 'red' }}>*</span>Mail:</label>
                        <input
                            type="email"
                            id="mail"
                            value={mail}
                            onChange={handleChange(setMail, 254)} // Max längd för e-postadresser är vanligen 254 tecken
                            required
                            placeholder='Mail to which ticket is responded to...'
                        />
                    </div>
                    <button type="submit" className={styles.submitButton}>Submit</button>
                </form>
            </div>
        </div>
    );
}

export default CreateSupportTicket;
