import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from 'react-modal';
import Select from 'react-select'; // Importera react-select
import adminService from '../../services/adminService';
import styles from './QuotationDetails.module.scss';

// Sätt app-element för Modal (för tillgänglighet)
Modal.setAppElement('#root'); // Se till att ersätta '#root' med ditt appens rot-element ID

const QuotationDetails = () => {
    const { requestId } = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState({ quotations: [] }); // Initiera med quotations: []
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedback, setFeedback] = useState('');
    const [selectedQuotations, setSelectedQuotations] = useState([]);
    const [selectedQuotationId, setSelectedQuotationId] = useState(null);
    const [showMarkupModal, setShowMarkupModal] = useState(false);
    const [markupData, setMarkupData] = useState({});
    const [initialMarkupData, setInitialMarkupData] = useState({});
    const [selectedQuotationForMarkup, setSelectedQuotationForMarkup] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationData, setConfirmationData] = useState({
        selectedQuotations: [],
        missingMarkups: []
    });

    // Tillstånd för vald fraktland i modalen
    const [selectedShippingCountries, setSelectedShippingCountries] = useState({});

    // Referens för "Select All" checkbox för att hantera indeterminate state
    const selectAllRef = useRef();

    // Funktion för att hämta quotation details
    const fetchQuotationDetails = async () => {
        try {
            const response = await adminService.fetchQuotationDetails(requestId);
            setData({
                ...response,
                quotations: Array.isArray(response.quotations) ? response.quotations : []
            });
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setData({ quotations: [] });
        }
    };

    useEffect(() => {
        fetchQuotationDetails();
    }, [requestId]);

    // Funktion för att öppna feedback-modalen
    const handleGiveFeedback = (quotationId) => {
        setSelectedQuotationId(quotationId);
        setShowFeedbackModal(true);
    };

    // Funktion för att stänga feedback-modalen
    const handleFeedbackModalClose = () => {
        setShowFeedbackModal(false);
    };

    // Funktion för att skicka feedback
    const handleFeedbackSubmit = async () => {
        try {
            await adminService.giveFeedback(selectedQuotationId, feedback);
            setShowFeedbackModal(false);
            setFeedback('');
            fetchQuotationDetails();
        } catch (error) {
            console.error('Failed to submit feedback:', error);
            alert('Misslyckades med att skicka feedback. Försök igen.');
        }
    };

    // Funktion för att öppna markups-modalen
    const handleSetMarkup = (quotation) => {
        setSelectedQuotationForMarkup(quotation);
        const initialMarkup = {};
        const initialShipping = {};

        if (quotation.noVariant) {
            initialMarkup['noVariant'] = {
                productPrice: quotation.noVariant.productPrice,
                markup: quotation.noVariant.markup !== null ? quotation.noVariant.markup : '',
            };
            // Sätt ett standardland (första landet i listan) och inkludera quotation.id som nyckel
            if (quotation.noVariant.shippingPrices.length > 0) {
                initialShipping[quotation.id] = {
                    noVariant: {
                        value: quotation.noVariant.shippingPrices[0].country,
                        label: quotation.noVariant.shippingPrices[0].country
                    }
                };
            }
        } else {
            initialShipping[quotation.id] = {};
            quotation.variants.forEach(variant => {
                initialMarkup[variant.id] = {
                    productPrice: variant.productPrice,
                    markup: variant.markup !== null ? variant.markup : '',
                };
                // Sätt ett standardland (första landet i listan) och inkludera variant.id som nyckel
                if (variant.shippingPrices.length > 0) {
                    initialShipping[quotation.id][variant.id] = {
                        value: variant.shippingPrices[0].country,
                        label: variant.shippingPrices[0].country
                    };
                }
            });
        }

        setMarkupData(initialMarkup);
        setInitialMarkupData(initialMarkup); // Spara initiala markups för jämförelse
        setSelectedShippingCountries(initialShipping); // Sätt standardshipping countries
        setShowMarkupModal(true);
    };

    // Funktion för att stänga markups-modalen
    const handleMarkupModalClose = () => {
        setShowMarkupModal(false);
        setSelectedQuotationForMarkup(null);
        setMarkupData({});
        setInitialMarkupData({});
        setSelectedShippingCountries({}); // Rensa vald shipping country
    };

    // Funktion för att hantera inmatning av markup
    const handleMarkupChange = (id, value) => {
        const numericValue = value === '' ? '' : parseFloat(value);
        setMarkupData(prevData => ({
            ...prevData,
            [id]: {
                ...prevData[id],
                markup: numericValue,
            },
        }));
    };

    // Funktion för att hantera val av fraktland i modalen
    const handleShippingCountryChange = (quotationId, variantId, selectedOption) => {
        setSelectedShippingCountries(prev => ({
            ...prev,
            [quotationId]: {
                ...prev[quotationId],
                [variantId]: selectedOption
            }
        }));
    };

    // Funktion för att hitta shippingPrice baserat på valt land
    const getShippingPrice = (quotation, variantId, countryCode) => {
        if (quotation.noVariant) {
            const shipping = quotation.noVariant.shippingPrices.find(s => s.country === countryCode);
            return shipping ? shipping.shippingPrice : 0;
        } else {
            const variant = quotation.variants.find(v => v.id === variantId);
            const shipping = variant.shippingPrices.find(s => s.country === countryCode);
            return shipping ? shipping.shippingPrice : 0;
        }
    };

    // Funktion för att publicera markups
    const handlePublishMarkup = async () => {
        try {
            if (!selectedQuotationForMarkup) {
                alert('Ingen offert vald för markup.');
                return;
            }

            // Validering: säkerställ att alla markups är positiva tal
            const validateMarkup = () => {
                if (selectedQuotationForMarkup.noVariant) {
                    const markup = markupData['noVariant']?.markup;
                    return markup !== '' && markup >= 0;
                } else {
                    return selectedQuotationForMarkup.variants.every(variant => {
                        const markup = markupData[variant.id]?.markup;
                        return markup !== '' && markup >= 0;
                    });
                }
            };

            if (!validateMarkup()) {
                alert('Vänligen se till att alla markups är positiva tal.');
                return;
            }

            // Förbered data att skicka till backend
            let preparedMarkupData = {
                quotationId: selectedQuotationForMarkup.id,
                markups: {}
            };

            if (selectedQuotationForMarkup.noVariant) {
                const currentMarkup = markupData['noVariant']?.markup;
                preparedMarkupData.markups.noVariant = {
                    id: 'noVariant',
                    markup: currentMarkup !== undefined && currentMarkup !== '' ? currentMarkup : null
                };
            } else {
                Object.entries(markupData).forEach(([variantId, markupInfo]) => {
                    const currentMarkup = markupInfo.markup;
                    preparedMarkupData.markups[variantId] = {
                        id: variantId,
                        markup: currentMarkup !== undefined && currentMarkup !== '' ? currentMarkup : null
                    };
                });
            }

            // Skicka data till backend
            await adminService.setMarkups(preparedMarkupData);
            alert('Markups har uppdaterats framgångsrikt.');
            handleMarkupModalClose();
            fetchQuotationDetails(); // Uppdatera UI efter markups är publicerade
        } catch (error) {
            console.error('Failed to set markups:', error);
            alert('Misslyckades med att uppdatera markups. Försök igen.');
        }
    };

    // Funktion för att hantera checkboxar
    const handleCheckboxChange = (quotationId) => {
        setSelectedQuotations(prevSelected => {
            if (prevSelected.includes(quotationId)) {
                return prevSelected.filter(id => id !== quotationId);
            } else {
                return [...prevSelected, quotationId];
            }
        });
    };

    // Funktion för att förbereda och öppna bekräftelsemodalen vid "Submit Selected Quotations"
    const handleSubmitSelected = async () => {
        try {
            if (selectedQuotations.length === 0) {
                alert('Välj minst en quotation att skicka.');
                return;
            }

            // Hämta de valda quotations från data
            const selectedData = data.quotations.filter(q => selectedQuotations.includes(q.id));

            // Bestäm vilka quotations som saknar markups
            const missingMarkups = selectedData.filter(q => {
                if (q.noVariant) {
                    return q.noVariant.markup === null;
                } else {
                    return q.variants.some(v => v.markup === null);
                }
            });

            setConfirmationData({
                selectedQuotations: selectedData,
                missingMarkups
            });

            setShowConfirmationModal(true);
        } catch (error) {
            console.error('Failed to prepare submission:', error);
            alert('Misslyckades med att förbereda submission. Försök igen.');
        }
    };

    // Funktion för att bekräfta och skicka valda quotations
    const confirmSubmitSelected = async () => {
        try {
            // Här kan du implementera logik för att skicka valda quotations till backend
            await adminService.submitQuotations(requestId, selectedQuotations);
            alert('Quotations har skickats framgångsrikt.');
            setShowConfirmationModal(false);
            navigate('/admin/sourcing-requests/');
        } catch (error) {
            console.error('Failed to submit selected quotations:', error);
            alert('Misslyckades med att skicka valda quotations. Försök igen.');
            setShowConfirmationModal(false);
        }
    };

    // Funktion för att beräkna total per land
    const calculateTotalPerCountry = (productPrice, markup, shippingPrice) => {
        return ((parseFloat(productPrice) || 0) + (parseFloat(markup) || 0) + (parseFloat(shippingPrice) || 0)).toFixed(2);
    };

    // Funktion för att visa prisuppdelning i korten
    const renderPriceBreakdown = (quotation, variantId = null) => {
        const variant = variantId ? quotation.variants.find(v => v.id === variantId) : quotation.noVariant;
        return variant.shippingPrices.map((shipping, index) => {
            const productPrice = parseFloat(variant.productPrice) || 0;
            const markup = parseFloat(variant.markup) || 0;
            const shippingPrice = parseFloat(shipping.shippingPrice) || 0;
            const total = calculateTotalPerCountry(productPrice, markup, shippingPrice);

            return (
                <div key={index} className={styles.priceBreakdown}>
                    <p><strong>Country:</strong> {shipping.country}</p>
                    <p><strong>Product Price:</strong> €{productPrice}</p>
                    <p><strong>Markup:</strong> €{markup}</p>
                    <p><strong>Shipping Price:</strong> €{shippingPrice}</p>
                    <p className={styles.totalPrice}><strong>Total:</strong> €{total}</p>
                </div>
            );
        });
    };

    // Funktion för att hantera "Select All" checkbox
    const handleSelectAllChange = () => {
        if (selectedQuotations.length === data.quotations.length) {
            setSelectedQuotations([]);
        } else {
            const allIds = data.quotations.map(q => q.id);
            setSelectedQuotations(allIds);
        }
    };

    // Uppdatera "Select All" checkboxens indeterminate state
    useEffect(() => {
        if (selectAllRef.current) {
            const quotationsLength = Array.isArray(data.quotations) ? data.quotations.length : 0;
            selectAllRef.current.indeterminate = selectedQuotations.length > 0 && selectedQuotations.length < quotationsLength;
        }
    }, [selectedQuotations, data.quotations]);

    return (
        <div className={styles.container}>
            <h1>Quotation Details for {data.productName}</h1>
            <div className={styles.requestDetails}>
                <p><strong>Description:</strong> {data.description || 'No Description'}</p>
                <p><strong>URL:</strong> <a href={data.url} target="_blank" rel="noopener noreferrer">{data.url}</a></p>
                <p><strong>Note:</strong> {data.note || 'No Note'}</p>
                <p><strong>Requested By:</strong> {data.merchant}</p>
                {data.image && <img src={`/uploads/productImages/${data.image}`} alt={data.productName} className={styles.productImage} />}
            </div>

            {data.quotations && data.quotations.length > 0 ? (
                <div className={styles.cardsContainer}>
                    {data.quotations.map((quotation) => (
                        <div key={quotation.id} className={styles.card}>
                            <div className={styles.cardHeader}>
                                <input
                                    type="checkbox"
                                    checked={selectedQuotations.includes(quotation.id)}
                                    onChange={() => handleCheckboxChange(quotation.id)}
                                    className={styles.checkBox}
                                />
                                <h2>{quotation.supplierName}</h2>
                            </div>
                            <div className={styles.cardBody}>
                                {quotation.noVariant ? (
                                    <div className={styles.noVariantDetails}>
                                        <img src={`/uploads/productImages/${quotation.noVariant.image}`} alt="Product" className={styles.productImage} />
                                        <p><strong>Price:</strong> €{quotation.noVariant.productPrice}</p>
                                        <p><strong>Markup:</strong> {quotation.noVariant.markup !== null ? `€${quotation.noVariant.markup}` : 'Not Set'}</p>
                                        {/* Visa prisuppdelning per land från backend data */}
                                        {renderPriceBreakdown(quotation)}
                                    </div>
                                ) : (
                                    <div className={styles.variantDetails}>
                                        {quotation.variants.map((variant) => (
                                            <div key={variant.id} className={styles.variantCard}>
                                                <img src={`/uploads/productImages/${variant.image}`} alt={`Variant ${variant.id}`} className={styles.variantImage} />
                                                <p><strong>SKU:</strong> {variant.SKU}</p>
                                                <p><strong>Price:</strong> €{variant.productPrice}</p>
                                                <p><strong>Markup:</strong> {variant.markup !== null ? `€${variant.markup}` : 'Not Set'}</p>
                                                <p><strong>Category:</strong> {variant.categoryVariants.map(cv => cv.value).join(', ')}</p>
                                                {/* Visa prisuppdelning per land från backend data */}
                                                {renderPriceBreakdown(quotation, variant.id)}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            <div className={styles.cardFooter}>
                                <button onClick={() => handleSetMarkup(quotation)} className={styles.markupBtn}>
                                    {quotation.noVariant
                                        ? quotation.noVariant.markup !== null
                                            ? 'Edit Markup'
                                            : 'Set Markup'
                                        : quotation.variants.some(v => v.markup !== null)
                                            ? 'Edit Markup'
                                            : 'Set Markup'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No quotations found</p>
            )}

            {/* Select All Checkbox */}
            {data.quotations.length > 0 && (
                <div className={styles.selectAllContainer}>
                    <input
                        type="checkbox"
                        ref={selectAllRef}
                        checked={selectedQuotations.length === data.quotations.length}
                        onChange={handleSelectAllChange}
                        className={styles.checkBox}
                    />
                    <label>Select All</label>
                </div>
            )}

            <button onClick={handleSubmitSelected} className={styles.submitBtn}>Submit Selected Quotations</button>

            {/* Feedback Modal */}
            <Modal
                isOpen={showFeedbackModal}
                onRequestClose={handleFeedbackModalClose}
                contentLabel="Feedback Modal"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.modalContent}>
                    <h2>Give Feedback</h2>
                    <textarea
                        value={feedback}
                        onChange={(e) => setFeedback(e.target.value)}
                        placeholder="Enter your feedback"
                        className={styles.textarea}
                    />
                    <div className={styles.modalButtons}>
                        <button onClick={handleFeedbackSubmit} className={styles.submitModalBtn}>Submit</button>
                        <button onClick={handleFeedbackModalClose} className={styles.cancelModalBtn}>Cancel</button>
                    </div>
                </div>
            </Modal>

            {/* Markup Modal */}
            <Modal
                isOpen={showMarkupModal}
                onRequestClose={handleMarkupModalClose}
                contentLabel="Set Markup Modal"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                {selectedQuotationForMarkup && (
                    <div className={styles.modalContent}>
                        <h2>Set Markup for {selectedQuotationForMarkup.supplierName}</h2>
                        <div className={styles.markupDetails}>
                            {selectedQuotationForMarkup.noVariant ? (
                                <div className={styles.noVariantMarkup}>
                                    <p><strong>Product Name:</strong> {data.productName}</p>
                                    <label>
                                        <strong>Shipping Country:</strong>
                                        <Select
                                            className={styles.reactSelectControl}
                                            options={selectedQuotationForMarkup.noVariant.shippingPrices.map(shipping => ({
                                                value: shipping.country,
                                                label: shipping.country
                                            }))}
                                            value={selectedShippingCountries[selectedQuotationForMarkup.id]?.noVariant || null}
                                            onChange={(option) => handleShippingCountryChange(selectedQuotationForMarkup.id, 'noVariant', option)}
                                            placeholder="Select Country"
                                            isClearable={false} // Ta bort möjligheten att rensa för att säkerställa att ett land alltid är valt
                                        />
                                    </label>
                                    <p><strong>Current Price:</strong> €{selectedQuotationForMarkup.noVariant.productPrice}</p>
                                    <label>
                                        <strong>Markup (€):</strong>
                                        <input
                                            type="number"
                                            value={markupData['noVariant']?.markup !== '' ? markupData['noVariant'].markup : ''}
                                            onChange={(e) => handleMarkupChange('noVariant', e.target.value)}
                                            min="0"
                                            className={styles.markupInput}
                                        />
                                    </label>

                                    {/* Visa fraktpris och total per valt land från modal */}
                                    {selectedShippingCountries[selectedQuotationForMarkup.id]?.noVariant && (
                                        <div className={styles.countryTotal}>
                                            <p><strong>Country:</strong> {selectedShippingCountries[selectedQuotationForMarkup.id].noVariant.label}</p>
                                            <p><strong>Product Price:</strong> €{parseFloat(selectedQuotationForMarkup.noVariant.productPrice)}</p>
                                            <p><strong>Markup:</strong> €{parseFloat(markupData['noVariant']?.markup || 0)}</p>
                                            <p><strong>Shipping Price:</strong> €{getShippingPrice(selectedQuotationForMarkup, 'noVariant', selectedShippingCountries[selectedQuotationForMarkup.id].noVariant.value)}</p>
                                            <p className={styles.totalPrice}><strong>Total:</strong> €{
                                                calculateTotalPerCountry(
                                                    selectedQuotationForMarkup.noVariant.productPrice,
                                                    markupData['noVariant']?.markup || 0,
                                                    getShippingPrice(selectedQuotationForMarkup, 'noVariant', selectedShippingCountries[selectedQuotationForMarkup.id].noVariant.value)
                                                )
                                            }</p>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className={styles.variantMarkup}>
                                    {selectedQuotationForMarkup.variants.map((variant) => (
                                        <div key={variant.id} className={styles.variantMarkupItem}>
                                            <p><strong>Variant SKU:</strong> {variant.SKU}</p>
                                            <label>
                                                <strong>Shipping Country:</strong>
                                                <Select
                                                    className={styles.reactSelectControl}
                                                    options={variant.shippingPrices.map(shipping => ({
                                                        value: shipping.country,
                                                        label: shipping.country
                                                    }))}
                                                    value={selectedShippingCountries[selectedQuotationForMarkup.id]?.[variant.id] || null}
                                                    onChange={(option) => handleShippingCountryChange(selectedQuotationForMarkup.id, variant.id, option)}
                                                    placeholder="Select Country"
                                                    isClearable={false} // Ta bort möjligheten att rensa för att säkerställa att ett land alltid är valt
                                                />
                                            </label>
                                            <p><strong>Current Price:</strong> €{variant.productPrice}</p>
                                            <label>
                                                <strong>Markup (€):</strong>
                                                <input
                                                    type="number"
                                                    value={markupData[variant.id]?.markup !== '' ? markupData[variant.id].markup : ''}
                                                    onChange={(e) => handleMarkupChange(variant.id, e.target.value)}
                                                    min="0"
                                                    className={styles.markupInput}
                                                />
                                            </label>

                                            {/* Visa fraktpris och total per valt land från modal */}
                                            {selectedShippingCountries[selectedQuotationForMarkup.id]?.[variant.id] && (
                                                <div className={styles.countryTotal}>
                                                    <p><strong>Country:</strong> {selectedShippingCountries[selectedQuotationForMarkup.id][variant.id].label}</p>
                                                    <p><strong>Product Price:</strong> €{parseFloat(variant.productPrice)}</p>
                                                    <p><strong>Markup:</strong> €{parseFloat(markupData[variant.id]?.markup || 0)}</p>
                                                    <p><strong>Shipping Price:</strong> €{getShippingPrice(selectedQuotationForMarkup, variant.id, selectedShippingCountries[selectedQuotationForMarkup.id][variant.id].value)}</p>
                                                    <p className={styles.totalPrice}><strong>Total:</strong> €{
                                                        calculateTotalPerCountry(
                                                            variant.productPrice,
                                                            markupData[variant.id]?.markup || 0,
                                                            getShippingPrice(selectedQuotationForMarkup, variant.id, selectedShippingCountries[selectedQuotationForMarkup.id][variant.id].value)
                                                        )
                                                    }</p>
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className={styles.modalButtons}>
                            <button onClick={handlePublishMarkup} className={styles.publishBtn}>Publish</button>
                            <button onClick={handleMarkupModalClose} className={styles.cancelModalBtn}>Close</button>
                        </div>
                    </div>
                )}
            </Modal>

            {/* Confirmation Modal for Submit Selected Quotations */}
            <Modal
                isOpen={showConfirmationModal}
                onRequestClose={() => setShowConfirmationModal(false)}
                contentLabel="Confirmation Modal"
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <div className={styles.modalContent}>
                    <h2>Confirm Submission of Selected Quotations</h2>
                    <div className={styles.confirmationDetails}>
                        <h3>Selected Quotations:</h3>
                        <ul>
                            {confirmationData.selectedQuotations.map((quotation) => {
                                const isNoVariant = !!quotation.noVariant;
                                let markupStatus = '';

                                if (isNoVariant) {
                                    markupStatus = quotation.noVariant.markup !== null ? `€${quotation.noVariant.markup}` : 'No Markup Set';
                                } else {
                                    const totalVariants = quotation.variants.length;
                                    const setMarkups = quotation.variants.filter(v => v.markup !== null).length;

                                    if (setMarkups === totalVariants) {
                                        markupStatus = 'All Markups Set';
                                    } else if (setMarkups === 0) {
                                        markupStatus = 'No Markups Set';
                                    } else {
                                        markupStatus = `Some Markups Set (${setMarkups}/${totalVariants})`;
                                    }
                                }

                                return (
                                    <li key={quotation.id}>
                                        <strong>ID:</strong> {quotation.id} | <strong>Supplier:</strong> {quotation.supplierName} | <strong>Markup:</strong> {markupStatus}
                                    </li>
                                );
                            })}
                        </ul>

                        {confirmationData.missingMarkups.length > 0 && (
                            <div className={styles.warnings}>
                                <h3>Warnings:</h3>
                                <ul>
                                    {confirmationData.missingMarkups.map((quotation) => (
                                        <li key={quotation.id} className={styles.warningText}>
                                            Quotation ID {quotation.id} - Supplier "{quotation.supplierName}" saknar markups.
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className={styles.modalButtons}>
                        <button onClick={confirmSubmitSelected} className={styles.confirmBtn}>Confirm</button>
                        <button onClick={() => setShowConfirmationModal(false)} className={styles.cancelModalBtn}>Cancel</button>
                    </div>
                </div>
            </Modal>
        </div>
    );

};

export default QuotationDetails;