import React from 'react';
import styles from './CreatingRequestTutorial.module.scss';
import reqExample from '../../../../images/request-example.png';

const CreatingRequestTutorial = () => {
  return (
    <div className={styles.creatingRequestTutorial}>
      <h1>Guide to Creating a Sourcing Request</h1>

      <section className={styles.section}>
        <h2>1. Select the Form Type</h2>
        <p>
          When creating a sourcing request, you have two options:
        </p>
        <ul>
          <li>
            <strong>Submit with URL</strong>: Use this form if you have the product URL and name. This option is quicker with fewer fields.
          </li>
          <li>
            <strong>Submit with Details</strong>: Choose this form if you don't have a URL or wish to provide additional information. This form allows you to input more detailed data about the product.
          </li>
        </ul>
      </section>

      <section className={styles.section}>
        <h2>2. Complete the Form Fields</h2>
        <p>
          Depending on the form type you selected, fill in the corresponding fields as outlined below.
        </p>

        {/* Form example image */}
        <img
          src={reqExample}
          alt="Submit with URL and Submit with Details Forms"
          className={styles.formImage}
        />

        <div className={styles.formType}>
          <h3>Submit with URL</h3>
          <ul>
          <li>
              <strong>Countries:</strong> Select the countries you wish to ship to. Only the selected countries will receive quotations, and only these destinations will be approved when adding the product to your store and processing orders.
            </li>
            <li>
              <strong>URL:</strong> Enter the product's URL. For example: <code>http://localhost:3000/guide/product/publish-guide</code>
            </li>
            <li>
              <strong>Product Name:</strong> Provide the name of the product. For example: <em>Example Product</em>
            </li>
            <li>
              <strong>Average Monthly Orders:</strong> (Optional) Specify your average monthly orders for this product if it already exists in your store.
            </li>
            <li>
              <strong>Note:</strong> (Optional) Add any additional information or comments related to the product.
            </li>
          </ul>
        </div>

        <div className={styles.formType}>
          <h3>Submit with Details</h3>
          <ul>
          <li>
              <strong>Countries:</strong> Select the countries you wish to ship to. Only the selected countries will receive quotations, and only these destinations will be approved when adding the product to your store and processing orders.
            </li>
            <li>
              <strong>Image:</strong> Upload an image of the product to provide a visual reference.
            </li>
            <li>
              <strong>Product Name:</strong> Enter the name of the product. For example: <em>Example Product</em>
            </li>
            <li>
              <strong>Description:</strong> Provide a detailed description of the product. For example: <em>A comprehensive description of the product.</em>
            </li>
            <li>
              <strong>URL: </strong> (Optional) Enter the product's URL if available.
            </li>
            <li>
              <strong>Average Monthly Orders: (Optional)</strong> Specify your average monthly orders for this product if it already exists in your store.
            </li>
            <li>
              <strong>Note:</strong> (Optional) Add any additional information or comments related to the product.
            </li>
          </ul>
        </div>
      </section>

      <section className={styles.section}>
        <h2>3. Add Variants</h2>
        <p>
          Both forms allow you to add variants for your product, such as colors and sizes. Variants help in specifying different attributes of the product.
        </p>
        <p>
          <strong>How to Add Variants:</strong>
        </p>
        <ul>
          <li>
            Click on <strong>Add Variants</strong> to open the modal window.
          </li>
          <li>
            Enter the variant category (e.g., Color, Size).
          </li>
          <li>
            Input the variant values (e.g., Red, Blue for Color; M, L for Size). Press Enter after each value to add it.
          </li>
          <li>
            Click <strong>Add</strong> to add the variant category.
          </li>
          <li>
            Click <strong>Done</strong> to save the variant categories.
          </li>
        </ul>
        <p>
          <strong>Example of Variants:</strong>
        </p>
        <ul>
          <li>
            <strong>Color:</strong> Red, Blue
          </li>
          <li>
            <strong>Size:</strong> M, L
          </li>
        </ul>
        <div className={styles.highlight}>
          <strong>Result:</strong> The system will automatically generate combinations based on the entered variants, such as Red/M, Red/L, Blue/M, and Blue/L.
        </div>
      </section>

      <section className={styles.section}>
        <h2>4. Submit the Request</h2>
        <p>
          After completing all necessary fields and adding any variants, you can submit your request by clicking on <strong>Submit Request</strong> or <strong>Submit Request with Details</strong>, depending on the form you used.
        </p>
        <p>
          Upon submission, a confirmation modal will appear, allowing you to review and verify your request.
        </p>
        <p>
          Your request will be listed under <strong>Sourcing Requests</strong>. You can monitor the status of your request there.
        </p>
        <div className={styles.highlight}>
          <strong>Note:</strong> Since requests are sent instantly to suppliers for quotations, they cannot be edited after submission.
        </div>
      </section>
    </div>
  );
};

export default CreatingRequestTutorial;
