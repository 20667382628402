// MerchantLayout.jsx
import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate, Outlet, Link, useLocation } from 'react-router-dom';
import styles from './MerchantLayout.module.scss';
import authService from '../services/authService';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import StoreIcon from '@mui/icons-material/Store';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import PaymentsIcon from '@mui/icons-material/Payments';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import HandshakeIcon from '@mui/icons-material/Handshake';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import whatsAppLogo from '../images/whatsApp-logo.webp';
import NeedUpdate from './components/NeedUpdate'; // Import NeedUpdate
import Welcome from './components/Welcome'; // Import Welcome
import { Tooltip } from '@mui/material';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PeopleIcon from '@mui/icons-material/People';

const MerchantLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true); // Start with sidebar open
  const [showDropDown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [expiring, setExpiring] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const storedExpiring = sessionStorage.getItem('expiring');
    if (storedExpiring !== null) {
      setExpiring(storedExpiring === 'true'); // Convert string to boolean
    }
  }, []);

  const getPageTitle = () => {
    const path = location.pathname;

    if (path === '/sourcing') {
      return { title: 'Sourcing', showBackButton: false };
    } else if (path === '/products') {
      return { title: 'Products', showBackButton: false };
    } else if (/\/products\/\d+/.test(path)) {
      return { title: 'Product Info', showBackButton: true };
    } else if (path === '/orders') {
      return { title: 'Orders', showBackButton: false };
    } else if (path === '/stores') {
      return { title: 'Stores', showBackButton: false };
    } else if (path === '/billing') {
      return { title: 'Payments', showBackButton: false };
    } else if (path === '/partners') {
      return { title: 'Partners', showBackButton: false };
    } else if (path === '/sourcing/create-request') {
      return { title: 'Create Request', showBackButton: true };
    } else if (path === '/settings') {
      return { title: 'Settings', showBackButton: false };
    } else if (path === '/support') {
      return { title: 'Support', showBackButton: false };
    } else if (path === '/affiliate') {
      return { title: 'Affiliate', showBackButton: false };
    } else if (path === '/support/create-ticket') {
      return { title: 'Create Ticket', showBackButton: true };
    } else if (path.startsWith('/sourcing/quotations')) {
      return { title: 'Quotations', showBackButton: true };
    } else if (path.startsWith('/orders/merge-orders')) {
      return { title: 'Merge', showBackButton: true };
    } else {
      return { title: 'Dashboard', showBackButton: false }; // Default title
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  };

  const logout = async () => {
    await authService.logout();
    navigate('/login');
  };

  const handleLinkClick = () => {
    if (window.innerWidth <= 768) {
      setSidebarOpen(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropDown]);

  const pageTitleInfo = getPageTitle();

  return (
    <div className={styles.container}>
      <div className={`${styles.sidebar} ${sidebarOpen ? styles.open : styles.closed}`}>
        <div className={styles.header}>
          <Link to="/">
            <h1>q<span>iwi</span></h1>
          </Link>
        </div>
        <div className={styles.sideMenu}>
          <ul>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/" 
                className={({ isActive }) => isActive || window.location.pathname === '/' ? styles.active : undefined} 
                onClick={handleLinkClick}
              >
                <DashboardIcon className={styles.filled} />
                <DashboardOutlinedIcon className={styles.outlined} />
                <p>Dashboard</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink
                to="/sourcing"
                className={({ isActive }) =>
                  isActive ? styles.active : undefined
                }
                onClick={handleLinkClick}
              >
                <TravelExploreOutlinedIcon />
                <p>Sourcing</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/products" 
                className={({ isActive }) => (isActive ? styles.active : undefined)} 
                onClick={handleLinkClick}
              >
                <Inventory2Icon className={styles.filled} />
                <Inventory2OutlinedIcon className={styles.outlined} />
                <p>Products</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/orders" 
                className={({ isActive }) => isActive ? styles.active : undefined} 
                onClick={handleLinkClick}
              >
                <ShoppingBagIcon className={styles.filled} />
                <ShoppingBagOutlinedIcon className={styles.outlined} />
                <p>Orders</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/stores" 
                className={({ isActive }) => isActive ? styles.active : undefined} 
                onClick={handleLinkClick}
              >
                <StoreIcon className={styles.filled}/>
                <StoreOutlinedIcon className={styles.outlined} />
                <p>Stores</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/billing" 
                className={({ isActive }) => isActive ? styles.active : undefined} 
                onClick={handleLinkClick}
              >
                <PaymentsIcon className={styles.filled} />
                <PaymentsOutlinedIcon className={styles.outlined} />
                <p>Payments</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/partners" 
                className={({ isActive }) => isActive ? styles.active : undefined} 
                onClick={handleLinkClick}
              >
                <HandshakeIcon className={styles.filled} />
                <HandshakeOutlinedIcon className={styles.outlined} />
                <p>Partners</p>
              </NavLink>
            </li>
            <li className={styles.sideMenuItem}>
              <NavLink 
                to="/affiliate" 
                className={({ isActive }) => isActive ? styles.active : undefined} 
                onClick={handleLinkClick}
              >
                <PeopleIcon className={styles.filled} />
                <PeopleOutlineIcon className={styles.outlined} />
                <p>Affiliate</p>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className={styles.toggleSidebar} onClick={toggleSidebar}>
          <span className="material-symbols-outlined">
            chevron_left
          </span>
        </div>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.topnav}>
            <h1>
              {pageTitleInfo.showBackButton && (
                <button onClick={() => navigate(-1)} className={styles.backButton}><span class="material-symbols-outlined">
                arrow_back
                </span></button>
              )}
              {pageTitleInfo.title}
            </h1>
            <ul className={styles.topMenu}>
              {expiring && (
                <li className={`${styles.topMenuItem} ${styles.expiring}`}>
                  <span className="material-symbols-outlined">warning</span>
                  <p>Merchant expiring</p>
                </li>
              )}
              <Tooltip title={"Guide"}>
                <li className={`${styles.topMenuItem} ${styles.support}`}>
                  <a 
                    target="_blank" 
                    href="/guide" 
                    className={`${styles.guide}`}
                    rel="noopener noreferrer"
                  >
                    <span className="material-symbols-outlined">
                      developer_guide
                    </span>
                  </a>
                </li>
              </Tooltip>

              <Tooltip title={"Support"}>
                <li className={`${styles.topMenuItem} ${styles.support}`}>
                  <NavLink 
                    to="/support" 
                    className={({ isActive }) => isActive ? styles.active : undefined}
                  >
                    <span className="material-symbols-outlined">support_agent</span>
                  </NavLink>
                </li>
              </Tooltip>
              <li className={`${styles.topMenuItem} ${styles.profile}`}>
                <button onClick={toggleDropdown}>
                  <span className="material-symbols-outlined">person</span>
                </button>
                <div ref={dropdownRef} className={`${styles.dropdown} ${showDropDown ? styles.dropdownOpen : ''}`}>
                  <div className={styles.dropdownItem}>
                    <NavLink to="/settings" onClick={() => setShowDropdown(false)}>
                      <span className="material-symbols-outlined">settings</span>
                      Settings
                    </NavLink>    
                  </div>

                  <div className={styles.dropdownItem}>
                    <button onClick={logout}>
                      <span className="material-symbols-outlined">logout</span>
                      Logout
                    </button>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className={styles.main}>
            <NeedUpdate />
            <Welcome />
            <Outlet />
          </div>
          <div className={styles.whatsApp}>
            <a href="https://wa.me/+46722266021" target="_blank" rel="noopener noreferrer">
              <img src={whatsAppLogo} alt="WhatsApp Logo" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MerchantLayout;
