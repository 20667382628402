import React, { createContext, useContext, useState } from 'react';
import styles from './ErrorContext.module.scss';

// Skapa ett Context för error-meddelanden
const ErrorContext = createContext();

// Hook för att enkelt komma åt ErrorContext
export const useError = () => useContext(ErrorContext);

// Lagra errorNotification-funktionen i en variabel för att kunna använda den globalt
let globalErrorNotification = null;

export const ErrorProvider = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState(null);

    // Funktion för att visa felnotifikation
    const errorNotification = () => {
        setErrorMessage("Server error");
        setTimeout(() => setErrorMessage(null), 5000); // Dölj felmeddelandet efter 5 sekunder
    };

    // Sätt globalErrorNotification när ErrorProvider laddas
    globalErrorNotification = errorNotification;

    return (
        <ErrorContext.Provider value={{ errorNotification }}>
            {children}
            {errorMessage && (
                <div className={styles.popup}>
                  <span class="material-symbols-outlined">
error
</span>
                    <p>{errorMessage}</p>
                </div>
            )}
        </ErrorContext.Provider>
    );
};

// Funktion för att hämta errorNotification globalt
export const getGlobalErrorNotification = () => globalErrorNotification;

