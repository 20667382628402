import React from "react";
import Tooltip from "@mui/material/Tooltip";
import styles from "./HoverableList.module.scss";

const HoverableList = ({ list }) => {
  if (list.length === 0) return null;
  if (list.length === 1) return <span>{list[0]}</span>;

  return (
    <Tooltip
      title={
        <ul style={{ paddingLeft: 0, margin: 0, listStyle: "none" }}>
          {list.map((item, index) => (
            <li key={index} style={{ margin: 0 }}>
              {item}
            </li>
          ))}
        </ul>
      }
      arrow>
      <span className={styles.hoverable}>{list.length}</span>
    </Tooltip>
  );
};

export default HoverableList;
