// components/ProductDetails/ProductDetails.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import merchantService from '../../services/merchantService';
import Select from 'react-select';
import styles from './ProductDetails.module.scss';
import productPlaceHolder from '../../images/productPlaceHolder.jpeg';
import Modal from 'react-modal';

function ProductDetails() {
    const { id } = useParams();

    const [product, setProduct] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedImage, setSelectedImage] = useState('');
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);

    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    useEffect(() => {
        document.title = 'Product Details | Qiwi';
    }, []);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                setLoading(true);
                const fetchedProduct = await merchantService.fetchProduct(id);
                setProduct(fetchedProduct);

                // Collect unique shipping countries from all variants or product shipping prices
                const countries = new Set();
                if (fetchedProduct.variants?.length > 0) {
                    fetchedProduct.variants.forEach(variant => {
                        variant.shippingPrices.forEach(price => {
                            countries.add(price.country);
                        });
                    });
                } else if (fetchedProduct.shippingPrices?.length > 0) {
                    fetchedProduct.shippingPrices.forEach(price => {
                        countries.add(price.country);
                    });
                }

                const countryArray = Array.from(countries).map(country => ({ value: country, label: country }));
                setCountryOptions(countryArray);

                // Set default country to the first one in the list if no country is selected
                if (countryArray.length > 0 && !selectedCountry) {
                    setSelectedCountry(countryArray[0].value);
                }
            } catch (error) {
                console.error('Failed to fetch product details:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id, selectedCountry]);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption.value);
    };

    const getProductDetails = () => {
        if (!product) return null;

        const hasVariants = product.variants && product.variants.length > 0;
    
        // Handling the product price
        const productPrice = hasVariants
          ? `€${Math.min(...product.variants.map((v) => v.productPrice)).toFixed(2)} - €${Math.max(
              ...product.variants.map((v) => v.productPrice)
            ).toFixed(2)}`
          : `€${(product.productPrice.toFixed(2) || 0)}`; // Product-level price if no variants
    
        // Handling the shipping price
        const shippingPrice = hasVariants
          ? `€${Math.min(
              ...product.variants.flatMap((v) =>
                v.shippingPrices.map((sp) => sp.shippingPrice || 0)
              )
            ).toFixed(2)} - €${Math.max(
              ...product.variants.flatMap((v) =>
                v.shippingPrices.map((sp) => sp.shippingPrice || 0)
              )
            ).toFixed(2)}`
          : selectedCountry
          ? `€${
              product.shippingPrices.find((sp) => sp.country === selectedCountry)
                ?.shippingPrice.toFixed(2) || 'N/A'
            }`
          : 'N/A';
    
        // Handling the total price (product + shipping)
        const totalPrice = hasVariants
          ? `€${Math.min(
              ...product.variants.map(
                (v) =>
                  v.productPrice +
                  (v.shippingPrices.find((sp) => sp.country === selectedCountry)
                    ?.shippingPrice || 0)
              )
            ).toFixed(2)} - €${Math.max(
              ...product.variants.map(
                (v) =>
                  v.productPrice +
                  (v.shippingPrices.find((sp) => sp.country === selectedCountry)
                    ?.shippingPrice || 0)
              )
            ).toFixed(2)}`
          : selectedCountry
          ? `€${
              ((product.productPrice || 0) +
              (product.shippingPrices.find((sp) => sp.country === selectedCountry)
                ?.shippingPrice || 0)).toFixed(2)
            }`
          : 'N/A';
    
        return (
            <div className={styles.productDetails}>
                <div className={styles.mainInfo}>
                <div className={styles.detailsContainer}>
                    {/* Image Section */}
                    <div className={styles.imageSection}>
                        <img
                                                        src={product.image ? `/uploads/productImages/${product.image}` : productPlaceHolder}
                                                        alt={product.name}
                            className={styles.productImageSingle}
                            onClick={() => viewImage(product.image || productPlaceHolder)}
                        />
                    </div>

                    {/* Main Product Information */}
                    <div className={styles.infoSection}>
                        <h5 className={styles.productName}>Product: {product.name}</h5>
                        <p className={styles.productDescription}><strong>Description: </strong>{product.description || "N/A"}</p>

                            <div className={styles.pricingInfo}>
                                <p><strong>Product Price:</strong> {productPrice}</p>
                                <Select
                                    className={styles.countrySelect}
                                    value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                    onChange={handleCountryChange}
                                    options={countryOptions}
                                    placeholder="Select Country for Shipping Price"
                                    isSearchable
                                />
                                <p><strong>Shipping Price:</strong> {shippingPrice}</p>
                                <p><strong>Total Price:</strong> {totalPrice}</p>
                            </div>
                    </div>
                    </div>

                    {/* Supplier Information */}
                    <div className={styles.supplierInfo}>
                        <h5>Supplier: {product.supplier.alias}</h5>
                        {product.supplier ? (
                            <div>
                                <p><strong>Average Processing Time:</strong>                                                             {product.supplier.averageProcessingTimeHours
                                                                ? `${(product.supplier.averageProcessingTimeHours / 24).toFixed(1)} d`
                                                                : '1-3 d'
                                                            }</p>
                                <p><strong>Average Processing Time:</strong>                                                              {product.supplier.averageShippingTimeHours
                                                                ? `${(product.supplier.averageShippingTimeHours / 24).toFixed(1)} d`
                                                                : '1-3 d'
                                                            }</p>
                            </div>
                        ) : (
                            <p>No supplier information available.</p>
                        )}
                    </div>
                    </div>

                {/* Variants Section */}
                {hasVariants && (
                    <div className={styles.variants}>
                        <div className={styles.tableContainer}>
                            <div className={styles.filterHeader}>
                                <p>Variants</p>
                                <Select
                                    className={styles.countrySelect}
                                    value={selectedCountry ? { value: selectedCountry, label: selectedCountry } : null}
                                    onChange={handleCountryChange}
                                    options={countryOptions}
                                    placeholder="Select Country for Shipping Price"
                                    isSearchable
                                />
                            </div>
                            <table className={styles.table}>
                                <thead className={styles.mainThead}>
                                    <tr>
                                        <th>SKU</th>
                                        <th>Variant Title</th>
                                        <th>Image</th>
                                        <th>Product Price (€)</th>
                                        <th>Shipping Price (€)</th>
                                        <th>Total Price (€)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {product.variants.map((variant) => {
                                        const shippingPrice = variant.shippingPrices.find(sp => sp.country === selectedCountry)?.shippingPrice || 0;
                                        const totalPrice = variant.productPrice + shippingPrice;

                                        return (
                                            <tr key={variant.id} className={styles.rows}>
                                                <td>{variant.SKU || 'N/A'}</td>
                                                <td>{variant.variantTitle || 'N/A'}</td>
                                                <td>
                                                    <img
                                                        src={variant.image ? `/uploads/productImages/${variant.image}` : productPlaceHolder}
                                                        alt={variant.variantTitle}
                                                        className={styles.variantImage}
                                                        onClick={() => viewImage(variant.image || productPlaceHolder)}
                                                    />
                                                </td>
                                                <td>{variant.productPrice !== null ? `€${variant.productPrice.toFixed(2)}` : 'N/A'}</td>
                                                <td>{shippingPrice ? `€${shippingPrice.toFixed(2)}` : 'N/A'}</td>
                                                <td>{totalPrice ? `€${totalPrice.toFixed(2)}` : 'N/A'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}

                {/* Modal for image */}
                <Modal
                    isOpen={isImageModalOpen}
                    onRequestClose={() => setIsImageModalOpen(false)}
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                >
                    <img src={`/uploads/productImages/${selectedImage}`} alt="Selected Request Image" className={styles.largeImage} />
                </Modal>
            </div>
        );
    };

    return loading ? <p>Loading...</p> : getProductDetails();
}

export default ProductDetails;
