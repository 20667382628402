// src/components/RefundsTable.jsx
import React from "react";
import PaginationComponent from "../../../../components/PaginationComponent";
import styles from "../../Payments.module.scss";

function RefundsTable({
  refunds,
  loading,
  sortConfig,
  handleSort,
  itemsPerPage,
  setItemsPerPage,
  totalPages,
  currentPage,
  handlePageChange,
  filtersApplied,
}) {
  return (
    <table className={styles.table}>
      <thead className={styles.mainThead}>
        <tr>
          <th onClick={() => handleSort("id")}>
            <div className={styles.thHeader}>
              Refund ID
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === "id" &&
                    sortConfig.direction === "ascending"
                      ? styles.active
                      : ""
                  }`}></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === "id" &&
                    sortConfig.direction === "descending"
                      ? styles.active
                      : ""
                  }`}></span>
              </div>
            </div>
          </th>
          <th onClick={() => handleSort("amount")}>
            <div className={styles.thHeader}>
              Amount
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === "amount" &&
                    sortConfig.direction === "ascending"
                      ? styles.active
                      : ""
                  }`}></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === "amount" &&
                    sortConfig.direction === "descending"
                      ? styles.active
                      : ""
                  }`}></span>
              </div>
            </div>
          </th>
          <th>Reference</th>
          <th>Reason</th>
          <th onClick={() => handleSort("createdAt")}>
            <div className={styles.thHeader}>
              Date Issued
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === "createdAt" &&
                    sortConfig.direction === "ascending"
                      ? styles.active
                      : ""
                  }`}></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === "createdAt" &&
                    sortConfig.direction === "descending"
                      ? styles.active
                      : ""
                  }`}></span>
              </div>
            </div>
          </th>
          <th onClick={() => handleSort("processed")}>
            <div className={styles.thHeader}>
              Processed
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === "processed" &&
                    sortConfig.direction === "ascending"
                      ? styles.active
                      : ""
                  }`}></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === "processed" &&
                    sortConfig.direction === "descending"
                      ? styles.active
                      : ""
                  }`}></span>
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <tr
              key={index}
              className={`${styles.rows} ${styles.placeHolderRow}`}>
              <td colSpan={6}></td>
            </tr>
          ))
        ) : refunds.length > 0 ? (
          refunds.map((item) => (
            <tr key={item.id} className={styles.rows}>
              <td>{item.id}</td>
              <td>€{item.amount}</td>
              <td>{item.reference}</td>
              <td>{item.reason}</td>
              <td>{new Date(item.createdAt).toLocaleDateString()}</td>
              <td>
                {item.processed ? (
                  <div className={styles.checkedCircle}>
                    <span className="material-symbols-outlined">
                      check_circle
                    </span>
                  </div>
                ) : (
                  <div className={styles.unCheckedCircle}>
                    <span className="material-symbols-outlined">cancel</span>
                  </div>
                )}
              </td>
            </tr>
          ))
        ) : (
          <tr className={styles.rows}>
            <td colSpan={6}>
              <p className={styles.noFound}>
                No refunds found
                {filtersApplied && ", check filtering"}
              </p>
            </td>
          </tr>
        )}
        <tr className={styles.rows}>
          <td colSpan={6}>
            <PaginationComponent
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default RefundsTable;
