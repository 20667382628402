import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Users.module.scss';

const Users = () => {
    const [data, setData] = useState([]);
    const [roleFilter, setRoleFilter] = useState('all');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [appliedFilters, setAppliedFilters] = useState({
        statusFilter: "all",
        dateRange: [],
      });
    const roleOptions = [
        { value: 'all', label: 'All Roles' },
        { value: 'Merchant', label: 'Merchant' },
        { value: 'Supplier', label: 'Supplier' },
        { value: 'Admin', label: 'Admin' }
    ];

    const fetchUsers = async () => {
        try {
            const { statusFilter, dateRange } = appliedFilters;
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchUsersByPageAndFilters(statusFilter, currentPage, itemsPerPage, startDate, endDate);
            setData(response.users || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, [currentPage, itemsPerPage, appliedFilters]);

    const deleteUser = async (userId) => {
        const confirmed = window.confirm('Are you sure you want to delete this user? This action cannot be undone.');
    
        if (confirmed) {
            try {
                const response = await adminService.deleteUser(userId);
                fetchUsers();
            } catch (error) {
                console.error('Failed to delete user:', error);
            }
        }
    };
    

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleApplyFilters = (filters) => {
        setAppliedFilters(filters);
        setCurrentPage(1);
      };

    return (
        <div className={styles.container}>
                        <div className={styles.header}>
            <h1>Users</h1>
            </div>
            <div className={styles.tableContainer}>
            <div className={styles.filterHeader}>
                <FilterComponent
                    initialFilters={appliedFilters}
                    statusFilter={roleFilter}
                    onApplyFilters={handleApplyFilters}
                    statusOptions={roleOptions}
                />
            </div>
            <table className={styles.table}>                    
            <thead className={styles.mainThead}>
                        <tr>
                            <th onClick={() => handleSort('email')}>
                                <div className={styles.thHeader}>
                                    Email
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'email' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'email' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('phoneNumber')}>
                                <div className={styles.thHeader}>
                                    Phone Number
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'phoneNumber' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'phoneNumber' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('role')}>
                                <div className={styles.thHeader}>
                                    Role
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'role' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'role' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th onClick={() => handleSort('createdAt')}>
                                <div className={styles.thHeader}>
                                    Created At
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(user => (
                                <tr className={styles.rows}>
                                    <td>
                                        {user.email}
                                    </td>
                                    <td>
                                        {user.phoneNumber}
                                    </td>
                                    <td>
                                    {user.role} 
                                    {user.role === "Merchant" && user.merchantName && `- ${user.merchantName}`} 
                                    {user.role === "Supplier" && user.supplierName && `- ${user.supplierName}(${user.supplierAlias || ''})`}
                                    </td>
                                    <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                                    <td className={styles.buttons}>
                                        <button onClick={() => deleteUser(user.id)} className={styles.deleteButton}>Delete</button>
                                    </td>
                                </tr>
                        ))}
                        <tr className={styles.rows}>
                            <td colSpan={5}>
                                <PaginationComponent
                                    itemsPerPage={itemsPerPage}
                                    setItemsPerPage={setItemsPerPage}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
        </div>
    );
};

export default Users;
