import React, { useState, useEffect } from 'react';
import styles from './CreateQuotation.module.scss';
import supplierService from '../../services/supplierService';
import { useParams, useNavigate } from 'react-router-dom';
import NonVariantForm from './CreateQuotationForms/NonVariantForm';
import VariantForm from './CreateQuotationForms/VariantForm';

const CreateQuotation = () => {
  useEffect(() => {
    document.title = 'Create Quotation | Qiwi';
  }, []);

  const { id: requestId } = useParams();
  const navigate = useNavigate();
  const [hasVariants, setHasVariants] = useState(false);
  const [expandedRequest, setExpandedRequest] = useState(true);
  const [request, setRequest] = useState({});
  const [redirected, setRedirected] = useState(false);
  const [quotationExists, setQuotationExists] = useState(false);
  const [existingQuotation, setExistingQuotation] = useState({});
  const [supplier, setSupplier] = useState({});

  useEffect(() => {
    const checkQuotationExists = async () => {
      try {
        const response = await supplierService.checkQuotation(requestId);
        setRequest(response.request);

        // Automatically set hasVariants based on whether the request has variants
        if (response.request && response.request.SourcingRequestCategories && response.request.SourcingRequestCategories.length > 0) {
          setHasVariants(true);
        } else {
          setHasVariants(false);
        }

        if (response.exists && !redirected && !response.quotation) {
          alert('You have already submitted a quotation for this request.');
          setRedirected(true);
          navigate('/supplier/sourcing');
        } else if (!response.exists && !response.quotation) {
          setQuotationExists(false);
        } else {
          const quotation = response.quotation;
          setQuotationExists(true);
          setExistingQuotation(quotation);
          // If an existing quotation exists, set hasVariants based on it
          setHasVariants(quotation.hasVariants);
        }
      } catch (error) {
        console.error('Error checking if quotation exists:', error);
      }
    };

    const fetchSupplier = async () => {
      try {
        const response = await supplierService.fetchSupplier();
        setSupplier(response);
      } catch (error) {
        console.error('Error fetching supplier:', error);
      }
    };
    fetchSupplier();
    checkQuotationExists();
  }, [requestId, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2>{!quotationExists ? "Create" : "Edit"} Quotation</h2>
      </div>
      <div className={styles.requestContainer}>
        {expandedRequest ? (
          <>
            <div className={styles.requestData}>
              {request.image && (
                <div className={styles.requestImage}>
                  <img src={`/uploads/productImages/${request.image}`} alt="Product Image" />
                </div>
              )}
              <div className={styles.requestInfo}>
                <h2>Request</h2>
                {request.productName && (
                  <p>
                    <strong>Product name:</strong> {request.productName}
                  </p>
                )}
                {request.description && (
                  <p>
                    <strong>Product description:</strong> {request.description}
                  </p>
                )}
                {request.url && (
                  <p className={styles.url}>
                    <strong>URL:</strong> <a href={request.url} target="_blank" rel="noopener noreferrer">{request.url}</a>
                  </p>
                )}
                {request.createdAt && (
                  <p>
                    <strong>Date created:</strong> {new Date(request.createdAt).toLocaleDateString()}
                  </p>
                )}
                {typeof request.averageMonthlyOrders === 'number' && request.averageMonthlyOrders > 0 && (
                  <p>
                    <strong>Current avg. orders:</strong> {request.averageMonthlyOrders} qty
                  </p>
                )}
                {request.note && (
                  <p>
                    <strong>Note:</strong> {request.note}
                  </p>
                )}
                {/* Display variant data if available */}
                {request.SourcingRequestCategories && request.SourcingRequestCategories.length > 0 && (
                  <div className={styles.variantsContainer}>
                    {request.SourcingRequestCategories.map((category) => (
                      <div key={category.id} className={styles.variantCategory}>
                        <strong>{category.name}:</strong>{" "}
                        {category.SourcingRequestCategoryValues.map((value) => value.value).join(", ")}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.countriesSourcingContainer}>
              {request.SourcingCountries && request.SourcingCountries.map((country, index) => (
                <p key={index}>{country.country}</p>
              ))}
            </div>
          </>
        ) : (
          <p className={styles.noToggleRequest}>Request</p>
        )}
        <button
          type="button"
          onClick={() => setExpandedRequest(!expandedRequest)}
          className={styles.toggleRequestBtn}
        >
          {expandedRequest ? (
            <span className="material-symbols-outlined">keyboard_arrow_up</span>
          ) : (
            <span className="material-symbols-outlined">keyboard_arrow_down</span>
          )}
        </button>
      </div>
      {hasVariants ? (
        <VariantForm
          request={request}
          existingQuotation={existingQuotation}
          quotationExists={quotationExists}
          supplier={supplier}
        />
      ) : (
        <NonVariantForm
          supplier={supplier}
          request={request}
          existingQuotation={existingQuotation}
          quotationExists={quotationExists}
        />
      )}
    </div>
  );
};

export default CreateQuotation;
