import React, { useState,useEffect } from 'react';
import { NavLink, useNavigate, Outlet, useLocation } from 'react-router-dom';
import styles from './TutorialLayout.module.scss';
import authService from '../services/authService';

const TutorialLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [showProductDropdown, setShowProductDropdown] = useState(false);
    const [showSourcingDropdown, setShowSourcingDropdown] = useState(false);

    // Kontrollera om någon av sourcing- eller product-vägarna är aktiva
    const isProductActive = location.pathname.includes('/guide/product');
    const isSourcingActive = location.pathname.includes('/guide/sourcing');
    useEffect(() => {
        document.title = 'Guide | Qiwi';
      }, []);
    return (
        <div className={styles.container}>
            <div className={styles.topnav}>
                <div className={styles.topnavComponent}>
                    <div className={styles.header}>
                    <NavLink to="/guide" className={({ isActive }) => isActive ? styles.active : ''}>
                    <h1>qiwi</h1>
                        <h2>-Guide</h2>
                        </NavLink>
                    </div>
                    <div className={styles.navLinks}>
                        {/* Product Dropdown */}
                        <div
                            className={`${styles.dropdown} ${isProductActive ? styles.active : ''}`}
                            onMouseEnter={() => setShowProductDropdown(true)}
                            onMouseLeave={() => setShowProductDropdown(false)}
                        >
                            <div>Product</div>
                            {showProductDropdown && (
                                <div className={styles.dropdownContent}>
                                    <NavLink to="/guide/product/publish-guide" className={({ isActive }) => isActive ? styles.underline : ''}>Connect to existing Product</NavLink>
                                </div>
                            )}
                        </div>

                        {/* Sourcing Dropdown */}
                        <div
                            className={`${styles.dropdown} ${isSourcingActive ? styles.active : ''}`}
                            onMouseEnter={() => setShowSourcingDropdown(true)}
                            onMouseLeave={() => setShowSourcingDropdown(false)}
                        >
                            <div>Sourcing</div>
                            {showSourcingDropdown && (
                                <div className={styles.dropdownContent}>
                                    <NavLink to="/guide/sourcing/create-request" className={({ isActive }) => isActive ? styles.underline : ''}>Create Request</NavLink>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.main}>
                <Outlet />
            </div>
        </div>
    );
};

export default TutorialLayout;
