import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './Welcome.module.scss';

Modal.setAppElement('#root'); // Necessary for accessibility

const Welcome = () => {
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);

  useEffect(() => {
    const isNewRegister = sessionStorage.getItem('newRegister');

    if (isNewRegister) {
      setIsWelcomeModalOpen(true);
    }
  }, []);

  const closeModal = () => {
    setIsWelcomeModalOpen(false);
    sessionStorage.removeItem('newRegister');
  };

  return (
    <Modal
      isOpen={isWelcomeModalOpen}
      onRequestClose={closeModal}
      contentLabel="Welcome"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>Welcome to qiwi!</h2>
      
      <p>
        We're glad to have you on board. Take a moment to familiarize yourself with the app's features and utilize our guide to make the most out of qiwi.
      </p>

      <p>
        For detailed instructions on how to use app, please refer to our <a href="/guide" target="_blank" rel="noopener noreferrer">guide here</a>.
      </p>
      
      <button onClick={closeModal}>Get Started</button>
    </Modal>
  );
};

export default Welcome;
