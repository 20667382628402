import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import authService from '../../services/authService';
import styles from './Settings.module.scss';
import PasswordChangeModal from '../../components/PasswordChangeModal';
import Modal from 'react-modal';
import LoadingSpinner from '../../components/LoadingSpinner'; 
import LoadingButtonDots from '../../components/LoadingButtonDots'; 
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root'); // Ensure accessibility


const Settings = () => {
    useEffect(() => {
        document.title = 'Settings | Qiwi';
    }, []);
      
    const [userInfo, setUserInfo] = useState(null);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [merchantToDeleteOrders, setMerchantToDeleteOrders] = useState({});
    const [deleteConfirmation, setDeleteConfirmation] = useState('');
    const [loading, setLoading] = useState(true);
    const [loadingExpire, setLoadingExpire] = useState(false); // Loading state for expire button
    const [loadingDelete, setLoadingDelete] = useState(false); // Loading state for delete button
    const [loadingModal, setLoadingModal] = useState(false); // Loading state for modal
    const [expiring, setExpiring] = useState(false);
    const [passwordChangeError, setPasswordChangeError] = useState(null); 
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // State for editing mode
    const [editedContactEmail, setEditedContactEmail] = useState('');
    const [editedContactPhone, setEditedContactPhone] = useState(''); // New state for phone number
    const [editedIOSSNumber, setEditedIOSSNumber] = useState('');
    const [error, setError] = useState(null); // Error state for editing
    const navigate = useNavigate();

    // Fetch user info on component mount
    useEffect(() => {
        fetchUserInfo();
    }, []);

    // Check if merchant is expiring
    useEffect(() => {
        const storedExpiring = sessionStorage.getItem('expiring');
        if (storedExpiring !== null) {
            setExpiring(storedExpiring === 'true');
        }
    }, []);

    const fetchUserInfo = async () => {
        try {
            const response = await merchantService.getUserInfo();
            setUserInfo(response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching user info:', error);
            setLoading(false);
        }
    };

    const handleChangePassword = async (newPassword) => {
        try {
            setLoadingSubmit(true); // Show loading dots on the button
            await authService.changePassword(newPassword);
            alert('Password changed successfully');
            window.location.reload();
        } catch (error) {
            setPasswordChangeError(error.response?.data?.message || 'Error changing password.');
        } finally {
            setLoadingSubmit(false); // Hide loading dots on the button
        }
    };

    const deleteMerchantModal = async () => {
        setLoadingModal(true); // Start loading spinner for modal
        try {
            const orders = await merchantService.merchantDeleteStatus();
            setMerchantToDeleteOrders(orders.orders);
        } catch (error) {
            console.error('Error fetching delete status:', error);
        } finally {
            setLoadingModal(false); // Stop loading spinner for modal
            setIsDeleteModalOpen(true);
        }
    };

    const logout = async () => {
        await authService.logout();
        navigate('/login');
    };

    const expireMerchant = async () => {
        const confirmed = window.confirm("Are you sure you want to expire this Merchant? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true); // Start loading spinner
                await merchantService.expireMerchant();
                sessionStorage.setItem('expiring', 'true');
                alert("Merchant expired successfully.");
                setIsDeleteModalOpen(false);
                window.location.reload();
            } catch (error) {
                console.error("Error expiring Merchant:", error);
                alert("Failed to expire Merchant. Please try again.");
            } finally {
                setLoadingExpire(false); // Stop loading spinner
            }
        }
    };

    const deleteMerchant = async () => {
        if (deleteConfirmation === 'I confirm') {
            try {
                setLoadingDelete(true); // Start loading spinner
                await merchantService.deleteMerchant();
                alert("Merchant deleted successfully.");
                logout();
            } catch (error) {
                console.error('Error deleting merchant:', error);
                alert("Failed to delete Merchant. Please try again.");
            } finally {
                setLoadingDelete(false); // Stop loading spinner
                setIsDeleteModalOpen(false);
            }
        } else {
            alert('You must type "I confirm" to delete your account.');
        }
    };

    // Handlers for editing merchant info
    const handleEdit = () => {
        setIsEditing(true);
        setEditedContactEmail(userInfo.merchant.contactEmail);
        setEditedContactPhone(userInfo.merchant.contactPhone || ''); // Load phone if it exists
        setEditedIOSSNumber(userInfo.merchant.iossNumber || '');
        setError(null);
    };

    const handleCancelEdit = () => {
        setIsEditing(false);
        setError(null);
    };

    const handleSaveEdit = async () => {
        // Basic validation
        if (!editedContactEmail || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(editedContactEmail)) {
            setError('Please enter a valid email address.');
            return;
        }
        if (editedIOSSNumber && !/^[A-Z0-9]+$/.test(editedIOSSNumber)) {
            setError('IOSS Number should contain only uppercase letters and numbers.');
            return;
        }
        if (editedContactPhone && !/^[0-9\s()+-]+$/.test(editedContactPhone)) {
            setError('Please enter a valid phone number.');
            return;
        }

        try {
            setError(null);
            setLoadingSubmit(true); // Optional: show loading state

            // Update merchant info in one API call
            const updatedData = {
                contactEmail: editedContactEmail,
                contactPhone: editedContactPhone, // Include phone in the request
                iossNumber: editedIOSSNumber,
            };
            await merchantService.updateMerchantInfo(updatedData);

            alert("Merchant information updated successfully.");
            setIsEditing(false);
            fetchUserInfo();
        } catch (error) {
            console.error("Error updating merchant information:", error);
            setError('Failed to update merchant information');
        } finally {
            setLoadingSubmit(false); // Optional: hide loading state
        }
    };

    if (loading) {
        return <div className={styles.spinner}><LoadingSpinner /></div>; 
    }

    return (
        <div className={styles.container}>
            <div className={styles.section}>
                <div>
                    <h3>Personal Information</h3>
                    <p><strong>Login Email </strong>{userInfo.user.email}</p>
                    <div className={styles.buttonGroup}>
                        <button onClick={() => setIsPasswordModalOpen(true)} className={styles.edit}>Change Password</button>
                        <button onClick={deleteMerchantModal} className={styles.remove} disabled={expiring}>Delete Account</button>
                    </div>
                </div>

                <div>
                    <h3>Merchant Information</h3>
                    {!isEditing ? (
                        <>
                            <div className={styles.infoRow}>
                                <p><strong>Contact Email </strong>{userInfo.merchant.contactEmail}</p>
                                <p><strong>Contact Phone (WhatsApp) </strong>{userInfo.merchant.contactPhone || 'Not set'}</p> {/* Display phone */}
                                <p><strong>IOSS Number </strong>{userInfo.merchant.iossNumber || 'Not set'}</p>
                            </div>
                            <div className={styles.buttonGroup}>
                                <button onClick={handleEdit} className={styles.edit}>Edit</button>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.formGroup}>
                                <label htmlFor="contactEmail"><strong>Contact Email:</strong></label>
                                <input
                                    type="email"
                                    id="contactEmail"
                                    value={editedContactEmail}
                                    onChange={(e) => setEditedContactEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="contactPhone"><strong>Contact Phone:</strong></label> {/* New phone input */}
                                <input
                                    type="text"
                                    id="contactPhone"
                                    value={editedContactPhone}
                                    onChange={(e) => setEditedContactPhone(e.target.value)}
                                    placeholder="Enter Contact Phone"
                                />
                            </div>
                            <div className={styles.formGroup}>
                                <label htmlFor="iossNumber"><strong>IOSS Number:</strong></label>
                                <input
                                    type="text"
                                    id="iossNumber"
                                    value={editedIOSSNumber}
                                    onChange={(e) => setEditedIOSSNumber(e.target.value)}
                                    placeholder="Enter IOSS Number"
                                />
                            </div>

                            {error && <p className={styles.error}>{error}</p>}
                            <div className={styles.buttonGroup}>
                                <button onClick={handleSaveEdit} className={styles.save} disabled={loadingSubmit}>
                                    {loadingSubmit ? <LoadingButtonDots /> : 'Save'}
                                </button>
                                <button onClick={handleCancelEdit} className={styles.cancel}>Cancel</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {/* Password Change Modal */}
            <PasswordChangeModal
                isOpen={isPasswordModalOpen}
                onRequestClose={() => setIsPasswordModalOpen(false)}
                onChangePassword={handleChangePassword}
                loading={loadingSubmit} 
                error={passwordChangeError} 
            />
            {/* Delete Merchant Modal */}
            <Modal
                isOpen={isDeleteModalOpen}
                onRequestClose={() => setIsDeleteModalOpen(false)}
                className={styles.modal}
                overlayClassName={styles.overlay}
            >
                <h2>Deleting Merchant</h2>
                {loadingModal ? ( 
                    <div className={styles.spinnerModal}><LoadingSpinner /></div> 
                ) : merchantToDeleteOrders && merchantToDeleteOrders.length > 0 ? (
                    <>
                        <div className={styles.warningMessage}>
                            <span className="material-symbols-outlined">warning</span>
                            <p><strong>OBS. There are existing orders pending for this Merchant. Your account will be expiring until fulfilled.</strong></p>
                        </div>
                        <p>When you delete this merchant and account, the app will not be automatically uninstalled from your stores, and products sourced from Qiwi will not be removed from your Shopify stores. Please ensure you manually set these products to "Draft" or remove them from your stores.</p>
                        <table>
                            <thead>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {merchantToDeleteOrders.map(order => (
                                    <tr key={order.orderId}>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className={styles.buttons}>
                            <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                            <button className={styles.expiringButton} onClick={expireMerchant} disabled={loadingExpire}>
                                {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                            </button>
                        </div>
                    </>
                ) : (
                    <>
                        <p><strong>Are you sure you want to delete your merchant account? This action cannot be undone.</strong></p>
                        <p>When you delete this merchant and account, the app will not be automatically uninstalled from your stores, and products sourced from Qiwi will not be removed from your Shopify stores. Please ensure you manually set these products to "Draft" or remove them from your stores.</p>
                        <input
                            type="text"
                            placeholder='Type "I confirm" to delete'
                            value={deleteConfirmation}
                            onChange={(e) => setDeleteConfirmation(e.target.value)}
                        />
                        <div className={styles.buttons}>
                            <button onClick={() => setIsDeleteModalOpen(false)} className={styles.closeButton}>Close</button>
                            <button className={styles.deleteButtonModal} onClick={deleteMerchant} disabled={deleteConfirmation !== 'I confirm' || loadingDelete}>
                                {loadingDelete ? <LoadingButtonDots /> : 'Yes, delete my account'}
                            </button>
                        </div>
                    </>
                )}
            </Modal>
        </div>
    );

};

export default Settings;
