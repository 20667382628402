import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import styles from './NeedUpdate.module.scss';

Modal.setAppElement('#root'); // Nödvändigt för att använda modal korrekt

const NeedUpdate = () => {
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [storesToUpdate, setStoresToUpdate] = useState([]);

  useEffect(() => {
    // Kolla om det finns 'needsupdate' i sessionStorage
    const storedNeedsUpdate = sessionStorage.getItem('needsupdate');
    if (storedNeedsUpdate) {
      // Spara butiksnamnen i state och öppna modalen
      setStoresToUpdate(storedNeedsUpdate.split(','));
      setIsUpdateModalOpen(true);
      // Ta bort 'needsupdate' från sessionStorage så att modalen bara öppnas en gång
      sessionStorage.removeItem('needsupdate');
    }
  }, []);

  return (
    <Modal
      isOpen={isUpdateModalOpen}
      onRequestClose={() => setIsUpdateModalOpen(false)}
      contentLabel="Information"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <h2>These stores need updates</h2>
      
      {/* Informativ text för handlarna */}
      <p>
        Due to recent changes in Shopify's access permissions, the following stores 
        require updates to their access scopes. You can do this by opening the app from your shopify store. 
        <br/>
        <br/>

        To continue using the app without interruptions, 
        please go to your Shopify admin, navigate to "Apps," and select and open the qiwi-fulfill app. You will be 
        prompted to accept the new permissions. If no prompts are showed, you can disregard this message.
      </p>
      
      <ul>
        {storesToUpdate.map((store, index) => (
          <li key={index}>{store}</li>
        ))}
      </ul>
      
      <button onClick={() => setIsUpdateModalOpen(false)}>Close</button>
    </Modal>
  );
};

export default NeedUpdate;
