import React from 'react';
import PaginationComponent from '../../../../components/PaginationComponent';
import styles from '../../Orders.module.scss';

const OrderDeclinesTable = ({
  orderDeclines,
  loading,
  noActiveOrders,
  setItemsPerPage,
  itemsPerPage,
  currentPage,
  filtersApplied,
  handlePageChange,
  totalPages
}) => {
  return (
    <table className={styles.table}>
      <thead className={styles.mainThead}>
        <tr>
          <th>Decline ID</th>
          <th>Shopify Order ID</th>
          <th>Store</th>
          <th colSpan={4}>Reason</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
              <td colSpan={7}></td>
            </tr>
          ))
        ) : !noActiveOrders ? (
          orderDeclines.map((decline) => (
            <tr key={decline.shopifyOrderId} className={styles.rows}>
              <td>{decline.id}</td>
              <td>
                <a
                  href={`https://admin.shopify.com/store/${decline.Store.shop.replace('.myshopify.com', '')}/orders/${decline.shopifyOrderId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {decline.shopifyOrderId}
                </a>
              </td>
              <td>
                <div style={{ backgroundColor: decline.Store.color }} className={styles.shopLogo}>
                  {decline.Store.shortName}
                </div>
              </td>
              <td colSpan={4}>
                {decline.reason}
              </td>
            </tr>
          ))
        ) : (
          <tr className={styles.rows}>
            <td colSpan={7}>
              <p className={styles.noFound}>
                No declined orders found{filtersApplied && ', check filtering'}
              </p>
            </td>
          </tr>
        )}
        <tr className={styles.rows}>
          <td colSpan={7}>
            <PaginationComponent
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderDeclinesTable;
