import React, { useRef, useState, useEffect } from "react";
import styles from "./HoverableOverflowText.module.scss";
import Tooltip from "@mui/material/Tooltip";

const HoverableOverflowText = ({ text, fontSize }) => {
  const textRef = useRef(null);
  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      setIsOverflow(element.scrollWidth > element.clientWidth);
    }
  }, [text]);

  return (
    <Tooltip
      title={<span style={{ fontSize: fontSize || "inherit" }}>{text}</span>} // Dynamisk fontSize
      disableHoverListener={!isOverflow}
      arrow>
      <div
        className={`${styles.textContainer} ${isOverflow && styles.hoverable}`}
        ref={textRef}>
        {text}
      </div>
    </Tooltip>
  );
};

export default HoverableOverflowText;
