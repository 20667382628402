import React, { useState, useRef, useEffect } from 'react';
import { NavLink, useNavigate, Outlet, useLocation } from 'react-router-dom';  // Change here
import styles from './SupplierLayout.module.scss';
import authService from '../services/authService';

const MerchantLayout = () => {
    const [showDropDown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const navigate = useNavigate();
    const [expiring, setExpiring] = useState(false);
    const location = useLocation();

    useEffect(() => {
      const storedExpiring = sessionStorage.getItem('expiring');
      if (storedExpiring !== null) {
        setExpiring(storedExpiring === 'true'); // Konvertera strängen till en boolean
      }
    }, []);

  const toggleDropdown = () => {
    setShowDropdown(!showDropDown);
  };

  const logout = async () => {
    await authService.logout();
    navigate('/login');
  };


  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (showDropDown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropDown]);

  const isSupplierOrSourcingActive = location.pathname === "/supplier" || location.pathname === "/supplier/sourcing";


  return (
    <div className={styles.container}>
        <div className={styles.topnav}>

          <div className={styles.topNavComponent}>
            <div className={styles.header}>
            <h1>qiwi</h1>
          <h3>SUPPLIER</h3>
            </div>

              <div className={styles.navLinks}>
              <NavLink
                to="/supplier/sourcing"
                className={isSupplierOrSourcingActive ? styles.active : undefined}
              >
                Sourcing Requests
              </NavLink>
            <NavLink to="/supplier/orders"  className={({ isActive }) => isActive ? styles.active : undefined}>
              Orders
              </NavLink>
              </div>
          </div>
          <ul className={styles.topMenu}>
          {expiring && <li className={`${styles.topMenuItem} ${styles.expiring}`}><span className="material-symbols-outlined">warning</span><p>Supplier expiring</p></li>}
            <li className={`${styles.topMenuItem} ${styles.updates}`}>
            <NavLink to="/supplier/updates" className={({ isActive }) => isActive ? styles.active : undefined}>
            <span class="material-symbols-outlined">
            update
            </span> Updates
            </NavLink>
            </li>
            <li className={`${styles.topMenuItem} ${styles.support}`}>
              <NavLink to="/supplier/support" className={({ isActive }) => isActive ? styles.active : undefined}>
                <span className="material-symbols-outlined">support_agent</span>
              </NavLink>
            </li>
            <li className={styles.topMenuItem}>
              <button className={styles.profile} onClick={toggleDropdown}>
                <span className="material-symbols-outlined">person</span>
              </button>
              <div ref={dropdownRef} className={`${styles.dropdown} ${showDropDown ? styles.dropdownOpen : ''}`}>
                <div className={styles.dropdownItem}>
                  <NavLink to="/supplier/settings">
                    <span className="material-symbols-outlined">settings</span>
                    Settings
                  </NavLink>    
                </div>

                <div className={styles.dropdownItem}>
                  <button onClick={logout}>
                    <span className="material-symbols-outlined">logout</span>
                    Logout
                  </button>
                </div>

              </div>
            </li>
          </ul>
        </div>
        <div className={styles.main}>
          <Outlet />
        </div>
      </div>
  );
};

export default MerchantLayout;
