import React from "react";
import Tooltip from "@mui/material/Tooltip";
import styles from "./GridInfoBox.module.scss";

const GridInfoBox = ({ grid, children }) => {
  const content = Object.entries(grid).map(([label, value], index) => (
    <div key={index} className={styles.gridRow}>
      <span>
        <strong>{label}:</strong>
      </span>
      <span>{value ? value : "NA"}</span>
    </div>
  ));

  return (
    <Tooltip
      title={<div className={styles.gridContainer}>{content}</div>} // Tooltip vid hover
      arrow
      placement="top">
      <span>{children}</span>
    </Tooltip>
  );
};

export default GridInfoBox;
