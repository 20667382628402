import React from 'react';
import styles from './QiwiOnboardingTutorial.module.scss';

const QiwiOnboardingTutorial = () => {
  return (
    <div className={styles.qiwiOnboardingTutorial}>
      <h1>Welcome to Qiwi Fulfillment</h1>
      <p>
        This guide is designed to help you navigate through Qiwi's sourcing and fulfillment processes. Below is an overview of the application's features and a step-by-step guide to get you started.
      </p>

      {/* Overview Section */}
      <div className={styles.overview}>
        <h2>App Overview</h2>
        <ul>
          <li>
            <strong>Dashboard:</strong> Overview of all activities and key metrics.
          </li>
          <li>
            <strong>Sourcing:</strong> Request new products to source and view quotations from suppliers.
          </li>
          <li>
            <strong>Products:</strong> Manage your products and add them to your store.
          </li>
          <li>
            <strong>Orders:</strong> View and manage orders.
          </li>
          <li>
            <strong>Store Management:</strong> Configure and manage your store settings.
          </li>
          <li>
            <strong>Payments:</strong> Handle payments and manage your wallet within the app.
          </li>
          <li>
            <strong>Settings:</strong> Manage your account information and settings.
          </li>
          <li>
            <strong>Partners:</strong> View and connect with Qiwi's partners.
          </li>
          <li>
            <strong>Support:</strong> Create support tickets for assistance.
          </li>
        </ul>
      </div>

      {/* Step-by-Step Guide */}
      <div className={styles.steps}>
        <h2>Getting Started with Qiwi Fulfillment</h2>

        {/* Step 1 */}
        <div className={styles.step}>
          <h3>Step 1: Create a Sourcing Request</h3>
          <p>
            Start by creating a sourcing request. This allows you to specify the products you want to source. For detailed instructions, refer to our 
            <a href="/guide/sourcing/create-request" target="_blank" rel="noopener noreferrer"> guide here</a>.
          </p>
          <p>
            <strong>Sub-Steps:</strong>
          </p>
          <ul>
            <li>Navigate to the <strong>Sourcing</strong> section in the dashboard.</li>
            <li>Click on the <strong>Create Request</strong> button.</li>
            <li>Fill in the required details such as product name, URL, and variants.</li>
            <li>Submit the request to receive quotations from suppliers.</li>
          </ul>
        </div>

        {/* Step 2 */}
        <div className={styles.step}>
          <h3>Step 2: Receive Quotations from Suppliers</h3>
          <p>
            After submitting your sourcing request, you will receive quotations from various suppliers. Review these quotations to determine which supplier best meets your requirements.
          </p>
          <p>
            <strong>Considerations:</strong>
          </p>
          <ul>
            <li><strong>Price:</strong> Compare the pricing offered by different suppliers.</li>
            <li><strong>Delivery Time:</strong> Assess the estimated delivery times.</li>
            <li><strong>Suitability with Other Suppliers:</strong> Ensure that using different suppliers for products in the same orders won't result in delayed shipments.</li>
          </ul>
        </div>

        {/* Step 3 */}
        <div className={styles.step}>
          <h3>Step 3: Select a Supplier and Add the Product to Your Qiwi Library</h3>
          <p>
            Compare the received quotations and select the supplier you wish to work with. Adding the chosen product to your Qiwi product library allows you to manage it further within the platform.
          </p>
          <p>
            <strong>How to Add to Library:</strong>
          </p>
          <ul>
            <li>Click on the preferred supplier's quotation.</li>
            <li>Press the <strong>Confirm</strong> button, then click <strong>Add Product</strong> to add it to your Qiwi library.</li>
          </ul>
        </div>

        {/* Step 4 */}
        <div className={styles.step}>
          <h3>Step 4: Connect Your Store to Qiwi</h3>
          <p>
            For the next steps, you need to link your Shopify store to Qiwi. Download the Qiwi app from the Shopify App Store and log in with your Qiwi account. If you do not have an account, you can create one during this process.
          </p>
          <p>
            <strong>Connecting Your Store:</strong>
          </p>
          <ul>
            <li>Go to the <a href="https://apps.shopify.com/qiwi-fulfill" target="_blank" rel="noopener noreferrer">Shopify App Store</a> and search for the Qiwi app.</li>
            <li>Click <strong>Add app</strong> and follow the installation prompts.</li>
            <li>Once installed, open the Qiwi app and log in with your Qiwi credentials.</li>
            <li>If you don’t have an account, click on <strong>Create Account</strong> and follow the registration steps.</li>
          </ul>
        </div>

        {/* Step 5 */}
        <div className={styles.step}>
          <h3>Step 5: Add the Product to Your Store</h3>
          <p>
            You can choose to have Qiwi automatically create the product in your store or add it to an <a href="/guide/product/publish-guide" target="_blank" rel="noopener noreferrer">existing product</a>. If you add it to an existing product, Qiwi will handle fulfillment for all parts of pending orders that include this product.
          </p>
          <p>
            <strong>Options:</strong>
          </p>
          <ul>
            <li><strong>Automatic Creation:</strong> Let Qiwi create a new product entry in your store based on the details from the Qiwi library.</li>
            <li><strong>Existing Product:</strong> Link the sourced product to an existing product in your store. This is useful if you already have pending orders for the product.</li>
          </ul>
        </div>

        {/* Step 6 */}
        <div className={styles.step}>
          <h3>Step 6: Request Fulfillment for Orders</h3>
          <p>
            Once the product is added to your store, it will be set to the fulfillment location for the product and incoming orders, which you can then request fulfillment for. This action allows Qiwi to manage the delivery directly from the supplier. If the order contains a product that exists within your library, in the correct store, and the destination matches the quotation and store countries, Qiwi will accept the request.
          </p>
          <p>
            <strong>Steps to Request Fulfillment:</strong>
          </p>
          <ul>
            <li>Go to the <strong>Orders</strong> section in your Shopify Store.</li>
            <li>Either go into each order separately or mark the checkboxes of all the orders you want to request fulfillment for.</li>
            <li>Click on <strong>Request Fulfillment</strong>.</li>
            <li>Confirm the request to initiate the fulfillment process.</li>
          </ul>
        </div>

        {/* Step 7 */}
        <div className={styles.step}>
          <h3>Step 7: Complete the Payment</h3>
          <p>
            To enable the supplier to ship the product, you need to complete the payment for the order. This is done on the order page within the Qiwi app using your wallet. The wallet can be topped up on the Payments page.
          </p>
          <p>
            <strong>Payment Process:</strong>
          </p>
          <ul>
            <li>Navigate to the <strong>Orders</strong> section in Qiwi.</li>
            <li>Select the order you need to pay for.</li>
            <li>You can either select multiple orders or leave the checkboxes cleared and then press <strong>Pay</strong>, which will subtract the amount from your wallet.</li>
          </ul>
          <p>
            <strong>Important:</strong> Payments must be completed promptly to avoid delays in order fulfillment.
          </p>
        </div>

        {/* Step 8 */}
        <div className={styles.step}>
          <h3>Step 8: Await Delivery from the Supplier</h3>
          <p>
            After completing the payment, wait for the supplier to deliver the product. Once the product is shipped, the order status will be updated to fulfilled in your Shopify store.
          </p>
          <p>
            <strong>Tracking Delivery:</strong>
          </p>
          <ul>
            <li>Go to the <strong>Orders</strong> section in Qiwi.</li>
            <li>Check the status of your orders to see updates on delivery.</li>
            <li>Once the supplier has shipped the product, the order will be set to fulfilled in your Shopify store with tracking information.</li>
          </ul>
          <p>
            <strong>Completion:</strong> After the supplier delivers the product and updates the order status, the fulfillment process is complete. Ensure that customers are notified of their order status accordingly.
          </p>
        </div>
      </div>
    </div>
  );
};

export default QiwiOnboardingTutorial;
