import React, { useEffect, useState } from 'react';
import merchantService from '../../services/merchantService';
import styles from './MergeOrder.module.scss';
import LoadingButtonDots from '../../components/LoadingButtonDots';
import LoadingSpinner from '../../components/LoadingSpinner';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Nödvändigt för att använda modal korrekt

const MergeOrder = () => {
    useEffect(() => {
        document.title = 'Merge Orders | Qiwi';
    }, []);

    const [mergableGroups, setMergableGroups] = useState([]);
    const [pendingMergeRequestGroups, setPendingMergeRequestGroups] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState({});  // Hanterar valda ordrar per grupp
    const [loadingMergableOrders, setLoadingMergableOrders] = useState(false);
    const [loadingMergeRequests, setLoadingMergeRequests] = useState(false);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [loadingMerge, setLoadingMerge] = useState(false);
    const [error, setError] = useState(null);
    const [visibleItems, setVisibleItems] = useState({});
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const openInfoModal = () => {
        setIsInfoModalOpen(true);
    };

    const closeInfoModal = () => {
        setIsInfoModalOpen(false);
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await fetchMergeOrders(); // Vänta på att merge orders hämtas
                await fetchMergeRequests(); // Hämta merge requests efteråt
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };
    
        fetchData();
    }, []);

    const toggleItemDetails = (orderId) => {
        setVisibleItems(prev => ({
            ...prev,
            [orderId]: !prev[orderId]  // Växla synligheten för Order Items
        }));
    };

    const fetchMergeOrders = async () => {
        try {
            setLoadingMergableOrders(true);
            const data = await merchantService.fetchMergeOrders();
            console.log('Fetched mergable groups:', data); // Lägg till loggning
            setMergableGroups(data);
        } catch (error) {
            console.error('Failed to fetch merge orders:', error);
            setError('Failed to fetch merge orders');
        } finally {
            setLoadingMergableOrders(false);
        }
    };

    const fetchMergeRequests = async () => {
        try {
            setLoadingMergeRequests(true);
            const data = await merchantService.fetchMergeRequests();
            setPendingMergeRequestGroups(data);
        } catch (error) {
            console.error('Failed to fetch merge requests:', error);
            setError('Failed to fetch merge requests');
        } finally {
            setLoadingMergeRequests(false);
        }
    };

    const handleSelectOrder = (groupIndex, orderId) => {
        setSelectedOrders((prevSelectedOrders) => {
            const groupOrders = prevSelectedOrders[groupIndex] || new Set();
            const updatedGroupOrders = new Set(groupOrders);

            if (updatedGroupOrders.has(orderId)) {
                updatedGroupOrders.delete(orderId);
            } else {
                updatedGroupOrders.add(orderId);
            }

            return { ...prevSelectedOrders, [groupIndex]: updatedGroupOrders };
        });
    };

    const handleSelectMergedGroup = (groupIndex, mergedGroup) => {
        const allOrderIds = mergedGroup.mergedOrders.map(order => order.orderId);
        const areAllSelected = allOrderIds.every(orderId => isOrderSelected(groupIndex, orderId));

        setSelectedOrders((prevSelectedOrders) => {
            const groupOrders = prevSelectedOrders[groupIndex] || new Set();
            const updatedGroupOrders = new Set(groupOrders);

            allOrderIds.forEach(orderId => {
                if (areAllSelected) {
                    updatedGroupOrders.delete(orderId);
                } else {
                    updatedGroupOrders.add(orderId);
                }
            });

            return { ...prevSelectedOrders, [groupIndex]: updatedGroupOrders };
        });
    };

    const handleMergeOrders = async (groupIndex) => {
        const selectedGroupOrders = Array.from(selectedOrders[groupIndex] || []);
        
        if (selectedGroupOrders.length < 2) {
            alert('Please select at least two orders to merge.');
            return;
        }

        // Samla alla fulfillmentOrderIds
        const fulfillmentOrderIds = selectedGroupOrders.flatMap(orderId => {
            const order = findOrderById(groupIndex, orderId); // Hjälpfunktion för att hitta order
            return order.fulfillmentOrder.id;
        });

        try {
            setLoadingMerge(true);
            await merchantService.createMergeOrder(fulfillmentOrderIds);  // Skicka fulfillmentOrderIds till backend
            alert('Orders merged successfully!');
            fetchMergeOrders();
        } catch (error) {
            console.error('Failed to merge orders:', error);
        } finally {
            setLoadingMerge(false);
        }
    };
    
    const handleRequestMergeOrders = async (groupIndex) => {
        const selectedGroupOrders = Array.from(selectedOrders[groupIndex] || []);
        
        if (selectedGroupOrders.length < 2) {
            alert('Please select at least two orders to request merge.');
            return;
        }
        
        // Samla alla fulfillmentOrderIds
        const fulfillmentOrderIds = selectedGroupOrders.flatMap(orderId => {
            const order = findOrderById(groupIndex, orderId);
            return order.fulfillmentOrder.id;
        });

        try {
            setLoadingRequest(true);
            await merchantService.createMergeRequest(fulfillmentOrderIds);  // Skicka fulfillmentOrderIds till backend
            alert('Merge request sent successfully!');
            await fetchMergeOrders();
            await fetchMergeRequests();
        } catch (error) {
            console.error('Failed to request merge orders:', error);
        } finally {
            setLoadingRequest(false);
        }
    };
    
    // Hjälpfunktion för att hitta order i en grupp
    const findOrderById = (groupIndex, orderId) => {
        const group = mergableGroups[groupIndex];
        if (!group) return undefined;

        for (const order of group.orders) {
            if (order.mergeFulfillmentGroupId && order.mergedOrders) {
                const foundOrder = order.mergedOrders.find(o => o.orderId === orderId);
                if (foundOrder) return foundOrder;
            } else {
                if (order.orderId === orderId) return order;
            }
        }
        return undefined;
    };    

    const areButtonsDisabled = (groupIndex) => {
        const selectedGroupOrders = Array.from(selectedOrders[groupIndex] || []);
        const group = mergableGroups[groupIndex];
        if (!group) return true;

        let unitCount = 0;
        const countedMergedGroups = new Set();

        selectedGroupOrders.forEach(orderId => {
            const order = findOrderById(groupIndex, orderId);
            if (!order) {
                return;
            }

            if (order.fulfillmentOrder.mergeFulfillmentGroupId) {
                if (!countedMergedGroups.has(order.fulfillmentOrder.mergeFulfillmentGroupId)) {
                    unitCount += 1;
                    countedMergedGroups.add(order.fulfillmentOrder.mergeFulfillmentGroupId);
                }
            } else {
                unitCount += 1;
            }
        });

        return unitCount < 2;
    };

    const hasAwaitingShipment = (groupIndex) => {
        const selectedGroupOrders = Array.from(selectedOrders[groupIndex] || []);
        const fulfillmentOrders = selectedGroupOrders.flatMap(orderId => {
            const order = findOrderById(groupIndex, orderId); // Hjälpfunktion för att hitta order
            return order ? [order] : [];
        });
        return fulfillmentOrders.some(order => {
            if (order.fulfillmentOrder) {
                return order.fulfillmentOrder.isReadyForSupplier === true;
            }
            if (order.mergedOrders && Array.isArray(order.mergedOrders)) {
                return order.mergedOrders.some(mergedOrder => 
                    mergedOrder.fulfillmentOrder && mergedOrder.fulfillmentOrder.isReadyForSupplier === true
                );
            }
            return false;
        });
    };
    
    const isOrderSelected = (groupIndex, orderId) => {
        return selectedOrders[groupIndex]?.has(orderId);
    };

    const renderWithBreaksAndLabels = (labelValuePairs) => {
        return labelValuePairs.filter(([, value]) => value).map(([label, value], index) => (
            <React.Fragment key={index}>
                <strong>{label}</strong> {value}
                {index < labelValuePairs.length - 1 && <br />}
            </React.Fragment>
        ));
    };

    const renderOrderItemsTable = (orderItems, lastSpan) => {
        return (
            <table className={styles.itemsTable}>
                <thead>
                    <tr>
                        <th>Item ID</th>
                        <th>Product Name</th>
                        <th>Variant</th>
                        <th>Quantity</th>
                        <th colSpan={lastSpan}>SKU</th>
                    </tr>
                </thead>
                <tbody>
                    {orderItems.map(item => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.productName}</td>
                            <td>
                            {item.variantTitle ? item.variantTitle
                                     : 
                                        '-'
                                    }
                            </td>
                            <td>{item.quantity}</td>
                            <td>{item.SKU}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const renderSingleOrder = (groupIndex, order) => (
        <React.Fragment key={order.orderId}>
            <tr>
                <td>
                    <input
                        type="checkbox"
                        checked={isOrderSelected(groupIndex, order.orderId)}
                        onChange={() => handleSelectOrder(groupIndex, order.orderId)}
                    />
                </td>
                <td>{order.orderId}</td>
                <td>{order.orderStatus}</td>
                <td>{order.fullName}</td>
                <td colSpan={2}>
                    {renderWithBreaksAndLabels([
                        ['Country:', order.deliveryCountry],
                        ['City:', order.deliveryCity],
                        ['Province:', order.deliveryProvince],
                        ['Address Line 1:', order.deliveryAddress],
                        ['Address Line 2:', order.deliveryAddressLine2],
                        ['ZIP:', order.deliveryZIP],
                        ['Company:', order.company],
                        ['Phone Number:', order.phoneNumber],
                        ['Email:', order.mail],
                    ])}
                </td>
                <td>
                    <button
                        className={styles.toggleRowsButton}
                        onClick={() => toggleItemDetails(order.orderId)}
                    >
                        {order.fulfillmentOrder.OrderItems.length}
                        <span className="material-symbols-outlined">
                            {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                        </span>
                    </button>
                </td>
                <td>{new Date(order.createdAt).toLocaleDateString() || 'N/A'}</td>
            </tr>
            {visibleItems[order.orderId] && (
                <tr>
                    <td colSpan="8" className={styles.itemsRow}>
                        {renderOrderItemsTable(order.fulfillmentOrder.OrderItems, 4)}
                    </td>
                </tr>
            )}
        </React.Fragment>
    );

    const renderMergedGroup = (groupIndex, mergedGroup) => (
        <React.Fragment key={mergedGroup.mergeFulfillmentGroupId}>
            <tr>
                <td>
                    <input
                        type="checkbox"
                        checked={mergedGroup.mergedOrders.every(order => isOrderSelected(groupIndex, order.orderId))}
                        onChange={() => handleSelectMergedGroup(groupIndex, mergedGroup)}
                    />
                </td>
                <td colSpan="7" className={styles.mergedTd}>
                    <table className={styles.mergedGroupTable}>
                        <tbody>
                            {mergedGroup.mergedOrders.map((order) =>
                                <React.Fragment key={order.orderId}>
                                    <tr>
                                        <td>{order.orderId}</td>
                                        <td>{order.orderStatus}</td>
                                        <td>{order.fullName}</td>
                                        <td colSpan={2}>
                                            {renderWithBreaksAndLabels([
                                                ['Country:', order.deliveryCountry],
                                                ['City:', order.deliveryCity],
                                                ['Province:', order.deliveryProvince],
                                                ['Address Line 1:', order.deliveryAddress],
                                                ['Address Line 2:', order.deliveryAddressLine2],
                                                ['ZIP:', order.deliveryZIP],
                                                ['Company:', order.company],
                                                ['Phone Number:', order.phoneNumber],
                                                ['Email:', order.mail],
                                            ])}
                                        </td>
                                        <td>
                                            <button
                                                className={styles.toggleRowsButton}
                                                onClick={() => toggleItemDetails(order.orderId)}
                                            >
                                                {order.fulfillmentOrder.OrderItems.length}
                                                <span className="material-symbols-outlined">
                                                    {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                </span>
                                            </button>
                                        </td>
                                        <td>{new Date(order.createdAt).toLocaleDateString() || 'N/A'}</td>
                                    </tr>
                                    {visibleItems[order.orderId] && (
                                        <tr>
                                            <td colSpan="7" className={styles.itemsRow}>
                                                {renderOrderItemsTable(order.fulfillmentOrder.OrderItems, 3)}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            )}
                        </tbody>
                    </table>
                </td>
            </tr>
        </React.Fragment>
    );

    const renderOrders = (groupIndex, orders) => {
        return orders.map((order) => {
            if (order.mergeFulfillmentGroupId && order.mergedOrders) {
                return renderMergedGroup(groupIndex, order);
            } else {
                return renderSingleOrder(groupIndex, order);
            }
        });
    };

    const renderPendingMergeRequests = () => {
        return pendingMergeRequestGroups.map((group, groupIndex) => (
            <div key={groupIndex} className={styles.group}>
                <p><strong>Supplier: </strong>{group.supplier}</p>
                <p><strong>Status: </strong>{group.status}</p>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Order ID</th>
                            <th>Status</th>
                            <th>Customer</th>
                            <th colSpan={3}>Delivery</th>
                            <th>Order Items</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {group.orders.map(order => {
                            if (order.mergeFulfillmentGroupId && order.mergedOrders) {
                                return renderMergedGroupForRequests(groupIndex, order);
                            } else {
                                return renderSingleOrderForRequests(groupIndex, order);
                            }
                        })}
                    </tbody>
                </table>
            </div>
        ));
    };
    
    const renderMergedGroupForRequests = (groupIndex, mergedGroup) => (
        <React.Fragment key={mergedGroup.mergeFulfillmentGroupId}>
            <tr>
                <td colSpan="8" className={styles.mergedTd}>
                    <table className={styles.mergedGroupTable}>
                        <tbody>
                            {mergedGroup.mergedOrders.map((order) => (
                                <React.Fragment key={order.orderId}>
                                    <tr>
                                        <td>{order.orderId}</td>
                                        <td>{order.status}</td>
                                        <td>{order.customer.fullName}</td>
                                        <td colSpan={3}>
                                            {renderWithBreaksAndLabels([
                                                ['Country:', order.customer.deliveryCountry],
                                                ['City:', order.customer.deliveryCity],
                                                ['Province:', order.customer.deliveryProvince],
                                                ['Address Line 1:', order.customer.deliveryAddressLine],
                                                ['Address Line 2:', order.customer.deliveryAddressLine2],
                                                ['ZIP:', order.customer.deliveryZIP],
                                                ['Company:', order.customer.company],
                                                ['Phone Number:', order.customer.phoneNumber],
                                                ['Email:', order.customer.mail],
                                            ])}
                                        </td>
                                        <td>
                                            <button
                                                className={styles.toggleRowsButton}
                                                onClick={() => toggleItemDetails(order.orderId)}
                                            >
                                                {order.orderItems.length}
                                                <span className="material-symbols-outlined">
                                                    {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                </span>
                                            </button>
                                        </td>
                                        <td>{new Date(order.createdAt).toLocaleDateString() || 'N/A'}</td>
                                    </tr>
                                    {/* Order Items synliga om toggle är aktiv */}
                                    {visibleItems[order.orderId] && (
                                        <tr>
                                            <td colSpan="8" className={styles.itemsRow}>
                                                {renderOrderItemsTable(order.orderItems, 4)}
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </td>
            </tr>
        </React.Fragment>
    );
    
    const renderSingleOrderForRequests = (groupIndex, order) => (
        <React.Fragment key={order.orderId}>
            <tr>
                <td>{order.orderId}</td>
                <td>{order.status}</td>
                <td>{order.customer.fullName}</td>
                <td colSpan={3}>
                    {renderWithBreaksAndLabels([
                        ['Country:', order.customer.deliveryCountry],
                        ['City:', order.customer.deliveryCity],
                        ['Province:', order.customer.deliveryProvince],
                        ['Address Line 1:', order.customer.deliveryAddressLine],
                        ['Address Line 2:', order.customer.deliveryAddressLine2],
                        ['ZIP:', order.customer.deliveryZIP],
                        ['Company:', order.customer.company],
                        ['Phone Number:', order.customer.phoneNumber],
                        ['Email:', order.customer.mail],
                    ])}
                </td>
                <td>
                    <button
                        className={styles.toggleRowsButton}
                        onClick={() => toggleItemDetails(order.orderId)}
                    >
                        {order.orderItems.length}
                        <span className="material-symbols-outlined">
                            {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                        </span>
                    </button>
                </td>
                <td>{new Date(order.createdAt).toLocaleDateString() || 'N/A'}</td>
            </tr>
            {visibleItems[order.orderId] && (
                <tr>
                    <td colSpan="8" className={styles.itemsRow}>
                        {renderOrderItemsTable(order.orderItems, 4)}
                    </td>
                </tr>
            )}
        </React.Fragment>
    );
    

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h3>Merge Orders</h3>

                <button onClick={openInfoModal} className={styles.infoButton}>
                    <span className="material-symbols-outlined">info</span>
                </button>            
                </div>

            {loadingMergableOrders && <div className={styles.spinner}><LoadingSpinner /></div>}
            {!loadingMergableOrders && !error && (
                <div className={styles.ordersContainer}>
                    {mergableGroups.length === 0 && <p>No mergable orders found.</p>}
                    {mergableGroups.map((group, groupIndex) => (
                        <div key={groupIndex} className={styles.group}>
                           <p><strong>Supplier: </strong>{group.supplier}</p>
                           <p><strong>Store: </strong>{group.store.replace('.myshopify.com', '')}</p>
                            <table className={styles.table}>
                                <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>Order ID</th>
                                        <th>Status</th>
                                        <th>Customer</th>
                                        <th colSpan={2}>Delivery</th>
                                        <th>Order Items</th>
                                        <th>Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {renderOrders(groupIndex, group.orders)}
                                </tbody>
                            </table>
                            <div className={styles.buttonGroup}>
                                <button 
                                    onClick={() => handleMergeOrders(groupIndex)} 
                                    className={styles.mergeButton} 
                                    disabled={areButtonsDisabled(groupIndex) || hasAwaitingShipment(groupIndex)}
                                >
                                    {loadingMerge ? <LoadingButtonDots /> : 'Merge Selected Orders'}
                                </button>
                                <button 
                                    onClick={() => handleRequestMergeOrders(groupIndex)} 
                                    className={styles.mergeButton} 
                                    disabled={areButtonsDisabled(groupIndex) || !hasAwaitingShipment(groupIndex)}
                                >
                                    {loadingRequest ? <LoadingButtonDots /> : 'Request Merge'}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {!loadingMergeRequests && pendingMergeRequestGroups.length > 0 && (
                <>
                <div className={styles.header}><h3>Merge Requests</h3></div>
                <div className={styles.ordersContainer}>
                    {renderPendingMergeRequests()}
                </div>
                </>
            )}
            <Modal
                isOpen={isInfoModalOpen}
                onRequestClose={closeInfoModal}
                contentLabel="Information"
                className={styles.modal}  // Använd specifika modal-styles
                overlayClassName={styles.overlay}  // För overlay-styling
            >
                <div className={styles.modalContent}>
                    <h2>Information</h2>
                    <p>
                        Merge orders or specific items together for a consolidated shipment. 
                        You can view the items eligible for merging by toggling the "Order items" section. 
                        Orders awaiting fulfillment need to be requested by the supplier before they can be processed. 
                        Select the orders you'd like to merge. 
                        <br/>
                        <br/>
                        <strong>Note: </strong>If order items are in different processing stages 
                        (e.g., one is awaiting shipment and another is unpaid), 
                        the more advanced orders will be held until the others catch up.
                    </p>
                    <button onClick={closeInfoModal} className={styles.closeModalButton}>Close</button>
                </div>
            </Modal>
        </div>
    );
};

export default MergeOrder;
