// CreateRequest.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import dataService from '../../services/merchantService';
import styles from './CreateRequest.module.scss';
import LoadingButtonDots from '../../components/LoadingButtonDots'; // Import LoadingDots component
import { countries } from 'countries-list';
import Select from 'react-select';
import Tooltip from '@mui/material/Tooltip';
import AddVariantModal from './components/sourcing/AddVariantModal'; // Adjust import path if necessary
import Modal from 'react-modal'; // Import react-modal

// Set the app element for accessibility
Modal.setAppElement('#root'); // Ensure that your root element has the id 'root'

const CreateRequest = () => {
  const navigate = useNavigate();

  const [formDataURL, setFormDataURL] = useState({
    url: '',
    productName: '',
    note: '',
    selectedCountries: [],
    averageMonthlyOrders: '',
    variants: [],
  });

  const [formDataDetails, setFormDataDetails] = useState({
    productName: '',
    description: '',
    note: '',
    url: '',
    image: null,
    selectedCountries: [],
    averageMonthlyOrders: '',
    variants: [],
  });

  const [errorsURL, setErrorsURL] = useState({});
  const [errorsDetails, setErrorsDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [submittedURL, setSubmittedURL] = useState(false); // To track if submitted
  const [submittedDetails, setSubmittedDetails] = useState(false); // To track if submitted

  // State for confirmation modals
  const [showConfirmModalURL, setShowConfirmModalURL] = useState(false);
  const [showConfirmModalDetails, setShowConfirmModalDetails] = useState(false);

  const countryOptions = Object.entries(countries).map(([code, country]) => ({
    value: code,
    label: country.name,
  }));

  useEffect(() => {
    document.title = 'Create Sourcing Request | Qiwi';
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#edeeef',
      border: 'none',
      boxShadow: 'none',
      padding: '3px',
      minHeight: '40px',
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    valueContainer: (provided) => ({
      ...provided,
      minHeight: '40px',
      padding: '0 6px',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      minHeight: '40px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: 'black',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000',
    }),
    multiValue: (provided) => ({
      ...provided,
      padding: '3px',
      borderRadius: '10px',
      backgroundColor: '#000',
      color: '#fff',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: '#fff',
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#000',
        color: '#edeeef',
      },
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#000',
      '&:hover': {
        color: '#000',
      },
    }),
  };

  // Validation functions
  const validateFieldURL = (name, value) => {
    let error = '';

    switch (name) {
      case 'productName':
        if (value.length > 255) {
          error = 'Product Name cannot exceed 255 characters.';
        } else if (!value.trim()) {
          error = 'Product Name is required.';
        }
        break;
      case 'url':
        if (value.length > 2048) {
          error = 'URL cannot exceed 2048 characters.';
        } else if (!value.trim()) {
          error = 'URL is required.';
        }
        break;
      case 'averageMonthlyOrders':
        if (value && value < 0) {
          error = 'Average Monthly Orders cannot be negative.';
        }
        break;
      default:
        break;
    }

    setErrorsURL((prev) => ({ ...prev, [name]: error }));
  };

  const validateFieldDetails = (name, value) => {
    let error = '';

    switch (name) {
      case 'productName':
        if (value.length > 255) {
          error = 'Product Name cannot exceed 255 characters.';
        } else if (!value.trim()) {
          error = 'Product Name is required.';
        }
        break;
      case 'description':
        if (value.length > 1000) {
          error = 'Description cannot exceed 1000 characters.';
        } else if (!value.trim()) {
          error = 'Description is required.';
        }
        break;
      case 'url':
        if (value.length > 2048) {
          error = 'URL cannot exceed 2048 characters.';
        }
        break;
      case 'averageMonthlyOrders':
        if (value && value < 0) {
          error = 'Average Monthly Orders cannot be negative.';
        }
        break;
      default:
        break;
    }

    setErrorsDetails((prev) => ({ ...prev, [name]: error }));
  };

  // Helper function to generate all variant combinations
  const generateVariantCombinations = (variants) => {
    if (variants.length === 0) return [];

    const combinations = variants.reduce((acc, variant) => {
      const temp = [];
      acc.forEach((combination) => {
        variant.values.forEach((value) => {
          temp.push([...combination, value]);
        });
      });
      return temp;
    }, [[]]);

    return combinations.map((combo) => combo.join(' / '));
  };

  // Handlers for URL form
  const handleInputChangeURL = (e) => {
    const { name, value } = e.target;
    setFormDataURL((prev) => ({ ...prev, [name]: value }));
    if (submittedURL) validateFieldURL(name, value);
  };

  const handleCountryChangeURL = (selectedOptions) => {
    setFormDataURL((prev) => ({ ...prev, selectedCountries: selectedOptions }));
    if (submittedURL) {
      if (selectedOptions.length === 0) {
        setErrorsURL((prev) => ({ ...prev, selectedCountries: 'Please select at least one country.' }));
      } else {
        setErrorsURL((prev) => ({ ...prev, selectedCountries: '' }));
      }
    }
  };

  // Handlers for Details form
  const handleInputChangeDetails = (e) => {
    const { name, value } = e.target;
    setFormDataDetails((prev) => ({ ...prev, [name]: value }));
    if (submittedDetails) validateFieldDetails(name, value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormDataDetails((prev) => ({ ...prev, image: file }));
    if (submittedDetails) {
      if (!file) {
        setErrorsDetails((prev) => ({ ...prev, image: 'Image is required.' }));
      } else {
        setErrorsDetails((prev) => ({ ...prev, image: '' }));
      }
    }
  };

  const handleCountryChangeDetails = (selectedOptions) => {
    setFormDataDetails((prev) => ({ ...prev, selectedCountries: selectedOptions }));
    if (submittedDetails) {
      if (selectedOptions.length === 0) {
        setErrorsDetails((prev) => ({ ...prev, selectedCountries: 'Please select at least one country.' }));
      } else {
        setErrorsDetails((prev) => ({ ...prev, selectedCountries: '' }));
      }
    }
  };

  // Handlers for variant modals
  const [showCategoryModalURL, setShowCategoryModalURL] = useState(false); // Modal for URL form
  const [showCategoryModalDetails, setShowCategoryModalDetails] = useState(false); // Modal for Details form

  const openVariantModalURL = () => {
    setShowCategoryModalURL(true);
  };

  const openVariantModalDetails = () => {
    setShowCategoryModalDetails(true);
  };

  const closeVariantModalURL = () => {
    setShowCategoryModalURL(false);
  };

  const closeVariantModalDetails = () => {
    setShowCategoryModalDetails(false);
  };

  const handleSaveVariantsURL = (updatedVariants) => {
    setFormDataURL((prev) => ({
      ...prev,
      variants: updatedVariants,
    }));
  };

  const handleSaveVariantsDetails = (updatedVariants) => {
    setFormDataDetails((prev) => ({
      ...prev,
      variants: updatedVariants,
    }));
  };

  // Submit handlers with confirmation modal
  const handleSubmitURL = (e) => {
    e.preventDefault();
    setSubmittedURL(true);

    // Final validation
    let hasError = false;
    const newErrors = {};

    if (!formDataURL.productName.trim()) {
      newErrors.productName = 'Product Name is required.';
      hasError = true;
    } else if (formDataURL.productName.length > 255) {
      newErrors.productName = 'Product Name cannot exceed 255 characters.';
      hasError = true;
    }

    if (!formDataURL.url.trim()) {
      newErrors.url = 'URL is required.';
      hasError = true;
    } else if (formDataURL.url.length > 2048) {
      newErrors.url = 'URL cannot exceed 2048 characters.';
      hasError = true;
    }

    if (formDataURL.averageMonthlyOrders && formDataURL.averageMonthlyOrders < 0) {
      newErrors.averageMonthlyOrders = 'Average Monthly Orders cannot be negative.';
      hasError = true;
    }

    if (formDataURL.selectedCountries.length === 0) {
      newErrors.selectedCountries = 'Please select at least one country.';
      hasError = true;
    }

    setErrorsURL(newErrors);

    if (hasError) {
      return;
    }

    // Always show confirmation modal
    setShowConfirmModalURL(true);
  };

  const submitRequestURL = async () => {
    setIsLoading(true);
    setShowConfirmModalURL(false);
    try {
      const requestData = {
        url: formDataURL.url,
        productName: formDataURL.productName,
        note: formDataURL.note,
        averageMonthlyOrders: formDataURL.averageMonthlyOrders,
        countries: formDataURL.selectedCountries.map((c) => c.label),
        variants: Array.isArray(formDataURL.variants) ? formDataURL.variants : [],
      };
      await dataService.createSourcingRequest(requestData);
      alert('Request created successfully!');
      navigate('/sourcing');
    } catch (error) {
      console.error('Failed to create request: ', error);
      alert('Failed to create request.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmSubmitURL = () => {
    if (formDataURL.variants.length > 0) {
      submitRequestURL();
    } else {
      // Warn about missing variants and proceed if user agrees
      const proceed = window.confirm('No variants have been added. Do you want to proceed without variants?');
      if (proceed) {
        submitRequestURL();
      }
    }
  };

  const handleCancelSubmitURL = () => {
    setShowConfirmModalURL(false);
  };

  const handleSubmitDetails = (e) => {
    e.preventDefault();
    setSubmittedDetails(true);

    // Final validation
    let hasError = false;
    const newErrors = {};

    if (!formDataDetails.productName.trim()) {
      newErrors.productName = 'Product Name is required.';
      hasError = true;
    } else if (formDataDetails.productName.length > 255) {
      newErrors.productName = 'Product Name cannot exceed 255 characters.';
      hasError = true;
    }

    if (!formDataDetails.description.trim()) {
      newErrors.description = 'Description is required.';
      hasError = true;
    } else if (formDataDetails.description.length > 1000) {
      newErrors.description = 'Description cannot exceed 1000 characters.';
      hasError = true;
    }

    if (formDataDetails.url && formDataDetails.url.length > 2048) {
      newErrors.url = 'URL cannot exceed 2048 characters.';
      hasError = true;
    }

    if (formDataDetails.averageMonthlyOrders && formDataDetails.averageMonthlyOrders < 0) {
      newErrors.averageMonthlyOrders = 'Average Monthly Orders cannot be negative.';
      hasError = true;
    }

    if (formDataDetails.selectedCountries.length === 0) {
      newErrors.selectedCountries = 'Please select at least one country.';
      hasError = true;
    }

    if (!formDataDetails.image) {
      newErrors.image = 'Image is required.';
      hasError = true;
    }

    setErrorsDetails(newErrors);

    if (hasError) {
      return;
    }

    // Always show confirmation modal
    setShowConfirmModalDetails(true);
  };

  const submitRequestDetails = async () => {
    setIsLoadingTwo(true);
    setShowConfirmModalDetails(false);
    try {
      const formDataToSubmit = new FormData();
      formDataToSubmit.append('productName', formDataDetails.productName);
      formDataToSubmit.append('description', formDataDetails.description);
      formDataToSubmit.append('note', formDataDetails.note);
      formDataToSubmit.append('url', formDataDetails.url);
      formDataToSubmit.append('averageMonthlyOrders', formDataDetails.averageMonthlyOrders);
      if (formDataDetails.image) {
        formDataToSubmit.append('image', formDataDetails.image);
      }
      formDataDetails.selectedCountries.forEach((country) => {
        formDataToSubmit.append('countries[]', country.label);
      });
      if (Array.isArray(formDataDetails.variants)) {
        formDataToSubmit.append('variants', JSON.stringify(formDataDetails.variants));
      }
      await dataService.createSourcingRequestWithImage(formDataToSubmit);
      alert('Detailed request created successfully!');
      navigate('/sourcing');
    } catch (error) {
      console.error('Failed to create request with details: ', error);
      alert('Failed to create detailed request.');
    } finally {
      setIsLoadingTwo(false);
    }
  };

  const handleConfirmSubmitDetails = () => {
    if (formDataDetails.variants.length > 0) {
      submitRequestDetails();
    } else {
      // Warn about missing variants and proceed if user agrees
      const proceed = window.confirm('No variants have been added. Do you want to proceed without variants?');
      if (proceed) {
        submitRequestDetails();
      }
    }
  };

  const handleCancelSubmitDetails = () => {
    setShowConfirmModalDetails(false);
  };

  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <p>
          Need assistance? <Link to="../../guide/sourcing/create-request" target="_blank">View our guide</Link> for a step-by-step guide on creating a sourcing request.
        </p>
      </div>
      <div className={styles.forms}>
        {/* URL Form */}
        <form onSubmit={handleSubmitURL} className={styles.formContainer}>
          <h2>Submit with URL</h2>
          {/* Countries */}
          <label htmlFor="countriesURL" className={styles.label}>
            <span className={styles.required}>*</span> Countries
            <Select
              isMulti
              name="countries"
              options={countryOptions}
              value={formDataURL.selectedCountries}
              onChange={handleCountryChangeURL}
              className={styles.countrySelect}
              classNamePrefix="select"
              styles={customStyles}
            />
            {submittedURL && errorsURL.selectedCountries && (
              <span className={styles.errorMsg}>{errorsURL.selectedCountries}</span>
            )}
          </label>

          {/* URL */}
          <label htmlFor="urlURL" className={styles.label}>
            <span className={styles.required}>*</span> URL
            <input
              type="text"
              id="urlURL"
              name="url"
              value={formDataURL.url}
              onChange={handleInputChangeURL}
              className={`${styles.input} ${errorsURL.url ? styles.errorInput : ''}`}
            />
            {submittedURL && errorsURL.url && (
              <span className={styles.errorMsg}>{errorsURL.url}</span>
            )}
            {!errorsURL.url && formDataURL.url && (
              <span className={styles.charCount}>{formDataURL.url.length}/2048</span>
            )}
          </label>

          {/* Product Name */}
          <label htmlFor="productNameURL" className={styles.label}>
            <span className={styles.required}>*</span> Product Name
            <input
              type="text"
              id="productNameURL"
              name="productName"
              value={formDataURL.productName}
              onChange={handleInputChangeURL}
              className={`${styles.input} ${errorsURL.productName ? styles.errorInput : ''}`}
            />
            {submittedURL && errorsURL.productName && (
              <span className={styles.errorMsg}>{errorsURL.productName}</span>
            )}
            {!errorsURL.productName && formDataURL.productName && (
              <span className={styles.charCount}>{formDataURL.productName.length}/255</span>
            )}
          </label>

          {/* Average Monthly Orders */}
          <label htmlFor="averageMonthlyOrdersURL" className={styles.label}>
            <div className={styles.avgMonthlyLabel}>
              Average Monthly Orders
              <Tooltip
                title="If you already have this product in your store, please specify your average orders per month"
                arrow
              >
                <div>
                  <span className="material-symbols-outlined">
                    help
                  </span>
                </div>
              </Tooltip>
            </div>
            <input
              type="number"
              id="averageMonthlyOrdersURL"
              name="averageMonthlyOrders"
              value={formDataURL.averageMonthlyOrders || ''}
              onChange={handleInputChangeURL}
              className={`${styles.input} ${errorsURL.averageMonthlyOrders ? styles.errorInput : ''}`}
              min="0"
            />
            {submittedURL && errorsURL.averageMonthlyOrders && (
              <span className={styles.errorMsg}>{errorsURL.averageMonthlyOrders}</span>
            )}
          </label>

          {/* Note */}
          <label htmlFor="noteURL" className={styles.label}>
            Note
            <textarea
              id="noteURL"
              name="note"
              value={formDataURL.note}
              onChange={handleInputChangeURL}
              className={styles.input}
            />
            {!errorsURL.note && formDataURL.note && (
              <span className={styles.charCount}>{formDataURL.note.length}/1000</span>
            )}
          </label>

          {/* Add Variants */}
          <div className={styles.addVariantContainer}>
            <button
              type="button"
              onClick={openVariantModalURL}
              className={styles.addVariantButton}
            >
              {formDataURL.variants.length > 0 ? 'Edit Variants' : 'Add Variants'}
            </button>
            <p>
              If multiple variants are desired, it's important to include them in your request, such as sizes and
              colors.
            </p>
          </div>
          {/* Display Selected Variants */}
          {Array.isArray(formDataURL.variants) && formDataURL.variants.length > 0 && (
            <div className={styles.selectedVariants}>
              {formDataURL.variants.map((variant, index) => (
                <div key={index} className={styles.variantCategory}>
                  <strong>{variant.name}:</strong> {variant.values.join(', ')}
                </div>
              ))}
            </div>
          )}
          {/* AddVariantModal */}
          <AddVariantModal
            isOpen={showCategoryModalURL}
            onRequestClose={closeVariantModalURL}
            maxVariants={3}
            onVariantsUpdated={handleSaveVariantsURL}
            existingVariantList={formDataURL.variants}
          />

          {/* Submit Button */}
          <button type="submit" className={styles.submitButton} disabled={isLoading}>
            {isLoading ? <LoadingButtonDots /> : 'Submit Request'}
          </button>
        </form>

        {/* Details Form */}
        <form onSubmit={handleSubmitDetails} className={styles.detailsContainer}>
          <h2>Submit with Details</h2>
          {/* Countries */}
          <label htmlFor="countriesDetails" className={styles.label}>
            <span className={styles.required}>*</span> Countries
            <Select
              isMulti
              name="countries"
              options={countryOptions}
              value={formDataDetails.selectedCountries}
              onChange={handleCountryChangeDetails}
              className={styles.countrySelect}
              classNamePrefix="select"
              styles={customStyles}
            />
            {submittedDetails && errorsDetails.selectedCountries && (
              <span className={styles.errorMsg}>{errorsDetails.selectedCountries}</span>
            )}
          </label>

          {/* Image */}
          <label htmlFor="imageDetails" className={styles.label}>
            <span className={styles.required}>*</span> Image
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              id="imageDetails"
              onChange={handleFileChange}
              className={`${styles.input} ${errorsDetails.image ? styles.errorInput : ''}`}
            />
            {submittedDetails && errorsDetails.image && (
              <span className={styles.errorMsg}>{errorsDetails.image}</span>
            )}
          </label>

          {/* Product Name */}
          <label htmlFor="productNameDetails" className={styles.label}>
            <span className={styles.required}>*</span> Product Name
            <input
              type="text"
              id="productNameDetails"
              name="productName"
              value={formDataDetails.productName}
              onChange={handleInputChangeDetails}
              className={`${styles.input} ${errorsDetails.productName ? styles.errorInput : ''}`}
            />
            {submittedDetails && errorsDetails.productName && (
              <span className={styles.errorMsg}>{errorsDetails.productName}</span>
            )}
            {!errorsDetails.productName && formDataDetails.productName && (
              <span className={styles.charCount}>{formDataDetails.productName.length}/255</span>
            )}
          </label>

          {/* Description */}
          <label htmlFor="descriptionDetails" className={styles.label}>
            <span className={styles.required}>*</span> Description
            <textarea
              id="descriptionDetails"
              name="description"
              value={formDataDetails.description}
              onChange={handleInputChangeDetails}
              className={`${styles.input} ${errorsDetails.description ? styles.errorInput : ''}`}
            />
            {submittedDetails && errorsDetails.description && (
              <span className={styles.errorMsg}>{errorsDetails.description}</span>
            )}
            {!errorsDetails.description && formDataDetails.description && (
              <span className={styles.charCount}>{formDataDetails.description.length}/1000</span>
            )}
          </label>

          {/* URL */}
          <label htmlFor="urlDetails" className={styles.label}>
            URL
            <input
              type="text"
              id="urlDetails"
              name="url"
              value={formDataDetails.url}
              onChange={handleInputChangeDetails}
              className={`${styles.input} ${errorsDetails.url ? styles.errorInput : ''}`}
            />
            {submittedDetails && errorsDetails.url && (
              <span className={styles.errorMsg}>{errorsDetails.url}</span>
            )}
            {!errorsDetails.url && formDataDetails.url && (
              <span className={styles.charCount}>{formDataDetails.url.length}/2048</span>
            )}
          </label>

          {/* Average Monthly Orders */}
          <label htmlFor="averageMonthlyOrdersDetails" className={styles.label}>
            <div className={styles.avgMonthlyLabel}>
              Average Monthly Orders
              <Tooltip
                title="If you already have this product in your store, please specify your average orders per month"
                arrow
              >
                <div>
                  <span className="material-symbols-outlined">
                    help
                  </span>
                </div>
              </Tooltip>
            </div>
            <input
              type="number"
              id="averageMonthlyOrdersDetails"
              name="averageMonthlyOrders"
              value={formDataDetails.averageMonthlyOrders || ''}
              onChange={handleInputChangeDetails}
              className={`${styles.input} ${errorsDetails.averageMonthlyOrders ? styles.errorInput : ''}`}
              min="0"
            />
            {submittedDetails && errorsDetails.averageMonthlyOrders && (
              <span className={styles.errorMsg}>{errorsDetails.averageMonthlyOrders}</span>
            )}
          </label>

          {/* Note */}
          <label htmlFor="noteDetails" className={styles.label}>
            Note
            <textarea
              id="noteDetails"
              name="note"
              value={formDataDetails.note}
              onChange={handleInputChangeDetails}
              className={styles.input}
            />
            {!errorsDetails.note && formDataDetails.note && (
              <span className={styles.charCount}>{formDataDetails.note.length}/1000</span>
            )}
          </label>

          {/* Add Variants */}
          <div className={styles.addVariantContainer}>
            <button
              type="button"
              onClick={openVariantModalDetails}
              className={styles.addVariantButton}
            >
              {formDataDetails.variants.length > 0 ? 'Edit Variants' : 'Add Variants'}
            </button>
            <p>
              If multiple variants are desired, it's important to include them in your request, such as sizes and
              colors.
            </p>
          </div>
          {/* Display Selected Variants */}
          {Array.isArray(formDataDetails.variants) && formDataDetails.variants.length > 0 && (
            <div className={styles.selectedVariants}>
              {formDataDetails.variants.map((variant, index) => (
                <div key={index} className={styles.variantCategory}>
                  <strong>{variant.name}:</strong> {variant.values.join(', ')}
                </div>
              ))}
            </div>
          )}
          {/* AddVariantModal */}
          <AddVariantModal
            isOpen={showCategoryModalDetails}
            onRequestClose={closeVariantModalDetails}
            maxVariants={3}
            onVariantsUpdated={handleSaveVariantsDetails}
            existingVariantList={formDataDetails.variants}
          />

          {/* Submit Button */}
          <button type="submit" className={styles.submitButton} disabled={isLoadingTwo}>
            {isLoadingTwo ? <LoadingButtonDots /> : 'Submit Request with Details'}
          </button>
        </form>
      </div>

      {/* Confirmation Modal for URL Form */}
      <Modal
        isOpen={showConfirmModalURL}
        onRequestClose={handleCancelSubmitURL}
        contentLabel="Confirm Sourcing Request"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Confirm Your Sourcing Request</h2>
        <div className={styles.modalContent}>
          <p><strong>Product Name:</strong> {formDataURL.productName}</p>
          <p className={styles.URL}><strong>URL:</strong> <a href={formDataURL.url} target="_blank">{formDataURL.url}</a></p>
          <p><strong>Countries:</strong> {formDataURL.selectedCountries.map(c => c.label).join(', ')}</p>
          {formDataURL.averageMonthlyOrders && (
            <p><strong>Average Monthly Orders:</strong> {formDataURL.averageMonthlyOrders}</p>
          )}
          {formDataURL.note && (
            <p><strong>Note:</strong> {formDataURL.note}</p>
          )}
          {formDataURL.variants.length > 0 ? (
            <div className={styles.variants}>
              <p><strong>Variants:</strong></p>
              <ul>
                {generateVariantCombinations(formDataURL.variants).map((combo, index) => (
                  <li key={index}>{combo}</li>
                ))}
              </ul>
            </div>
          ) : (
            <p className={styles.warning}>No variants have been added.</p>
          )}
        </div>
        <div className={styles.modalActions}>
          <button onClick={handleConfirmSubmitURL} className={styles.confirmButton}>
            Confirm
          </button>
          <button onClick={handleCancelSubmitURL} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </Modal>

      {/* Confirmation Modal for Details Form */}
      <Modal
        isOpen={showConfirmModalDetails}
        onRequestClose={handleCancelSubmitDetails}
        contentLabel="Confirm Detailed Sourcing Request"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Confirm Your Detailed Sourcing Request</h2>
        <div className={styles.modalContent}>
          <p><strong>Product Name:</strong> {formDataDetails.productName}</p>
          <p><strong>Description:</strong> {formDataDetails.description}</p>
          {formDataDetails.url && (
                    <p className={styles.URL}><strong>URL:</strong> <a href={formDataDetails.url} target="_blank">{formDataDetails.url}</a></p>
                    )}
          <p><strong>Countries:</strong> {formDataDetails.selectedCountries.map(c => c.label).join(', ')}</p>
          {formDataDetails.averageMonthlyOrders && (
            <p><strong>Average Monthly Orders:</strong> {formDataDetails.averageMonthlyOrders}</p>
          )}
          {formDataDetails.note && (
            <p><strong>Note:</strong> {formDataDetails.note}</p>
          )}
          {formDataDetails.variants.length > 0 ? (
            <div className={styles.variants}>
              <p><strong>Variants:</strong></p>
              <ul>
                {generateVariantCombinations(formDataDetails.variants).map((combo, index) => (
                  <li key={index}>{combo}</li>
                ))}
              </ul>
            </div>
          ) : (
            <p className={styles.warning}>No variants have been added.</p>
          )}
        </div>
        <div className={styles.modalActions}>
        <button onClick={handleConfirmSubmitDetails} className={styles.confirmButton}>
            Confirm
          </button>
          <button onClick={handleCancelSubmitDetails} className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default CreateRequest;
