import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Suppliers.module.scss';
import Modal from 'react-modal';

const Suppliers = () => {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [visibleUsers, setVisibleUsers] = useState({});
    const [selectedSupplierToDelete, setSelectedSupplierToDelete] = useState({});
    const [selectedSupplierToDeleteOrders, setSelectedSupplierToDeleteOrders] = useState({});
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState({
        dateRange: [],
      });
    const deleteSupplierModal = async (supplier) => {
        const orders = await adminService.supplierDeleteStatus(supplier.id);
        setSelectedSupplierToDelete(supplier);
        setSelectedSupplierToDeleteOrders(orders.orders);
        setIsDeleteModalOpen(true);
    }

    const expireSupplier = async (supplierId) => {
        const confirmed = window.confirm("Are you sure you want to expire this Supplier? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.expireSupplier(supplierId);
                alert("Supplier expired successfully.");
                fetchSuppliers();
            } catch (error) {
                console.error("Error expiring Supplier:", error);
                alert("Failed to expire Supplier.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };

    
    const deleteSupplier = async (supplierId) => {
        const confirmed = window.confirm("Are you sure you want to delete this Supplier? This action cannot be undone.");
        if (confirmed) {
            try {
                await adminService.supplierDelete(supplierId);
                alert("Supplier deleted successfully.");
                fetchSuppliers();
            } catch (error) {
                console.error("Error deleting Supplier:", error);
                alert("Failed to delete Supplier.");
            } finally {
                setIsDeleteModalOpen(false);
            }
        }
    };

    const toggleItemDetails = async (supplierId) => {
        if (visibleUsers[supplierId]) {
            setVisibleUsers(prev => ({
                ...prev,
                [supplierId]: false
            }));
        } else {
            setVisibleUsers(prev => ({
                ...prev,
                [supplierId]: 'loading'
            }));
            try {
                const users = await adminService.fetchSupplierUsers(supplierId, false);
                setData(prevSuppliers => prevSuppliers.map(supplier =>
                    supplier.id === supplierId ? { ...supplier, users } : supplier
                ));
            } catch (error) {
                console.error('Failed to fetch users:', error);
            } finally {
                setVisibleUsers(prev => ({
                    ...prev,
                    [supplierId]: true
                }));
            }
        }
    };

    const fetchSuppliers = async () => {
        try {
            const { dateRange } = appliedFilters;
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchSuppliersByPageAndFilters(currentPage, itemsPerPage, startDate, endDate);
            setData(response.suppliers || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    };

    useEffect(() => {
        fetchSuppliers();
    }, [currentPage, itemsPerPage, appliedFilters]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const createDetailRow = (supplierUsers) => {
        return (
            <td colSpan="6">
                <table>
                    <thead>
                        <tr>
                            <th>User Id</th>
                            <th>User Name</th>
                            <th colSpan={4}>Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        {supplierUsers.map((user, index) => (
                            <tr key={`user-${user.id}-${index}`}>
                                <td>{user.id}</td>
                                <td>{user.email}</td>
                                <td>{new Date(user.createdAt).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </td>
        );
    };


    const handleApplyFilters = (filters) => {
        setAppliedFilters(filters);
        setCurrentPage(1);
      };


    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Suppliers</h1>
            </div>
                    <div className={styles.tableContainer}>
                    <div className={styles.filterHeader}>
                <FilterComponent
                                initialFilters={appliedFilters}
                                onApplyFilters={handleApplyFilters}
                />
            </div>
                        <table className={styles.table}>
                            <thead className={styles.mainThead}>
                                <tr>
                                    <th onClick={() => handleSort('id')}>
                                        <div className={styles.thHeader}>
                                            Supplier Id
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'id' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'id' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('name')}>
                                        <div className={styles.thHeader}>
                                            Name
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Users</th>
                                    <th onClick={() => handleSort('createdAt')}>
                                        <div className={styles.thHeader}>
                                            Created At
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th>Active</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.map(supplier => (
                                    <React.Fragment key={supplier.id}>
                                            <tr
                                                className={visibleUsers[supplier.id] ? styles.droppeddown : styles.rows}
                                                >
                                            <td>{supplier.id}</td>
                                            <td> <div className={styles.supplierColor} style={{backgroundColor: supplier.colorHex}}>{supplier.name}({supplier.alias})</div></td>
                                            <td>
                                                <button
                                                    className={styles.toggleRowsButton}
                                                    onClick={() => toggleItemDetails(supplier.id)}
                                                >
                                                    {supplier.userCount}
                                                    <span className="material-symbols-outlined">
                                                        {visibleUsers[supplier.id] ? 'arrow_drop_up' : 'arrow_drop_down'}
                                                    </span>
                                                </button>
                                            </td>
                                            <td>{new Date(supplier.createdAt).toLocaleDateString()}</td>
                                            <td>{supplier.expiring ? 
                                    <div className={styles.hourglass}>
                                    <span className="material-symbols-outlined">
                                    hourglass_bottom
                                    </span>
                                    </div>
                                     :
                                     <div className={styles.checked}>
                                     <span className="material-symbols-outlined">
                                     check_circle
                                     </span>
                                     </div>
                                     }</td>
                                            <td>
                                            {true && !supplier.expiring && <button className={styles.deleteButton} onClick={() => deleteSupplierModal(supplier)}>Delete</button>}
                                            </td>                                        </tr>
                                        {visibleUsers[supplier.id] === 'loading' ? (
                                            <tr className={styles.detailRow} key={`details-${supplier.id}`}>
                                                <td colSpan={6}>
                                                    <table>
                                                        <tbody>
                                                            {Array.from({ length: 3 }).map((_, index) => (
                                                                <tr key={index} className={`${styles.rows} ${styles.placeHolderDetailRow}`}>
                                                                    <td colSpan={6}></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        ) : visibleUsers[supplier.id] && supplier.users && (
                                            <tr className={styles.detailRow} key={`details-${supplier.id}`}>
                                                {createDetailRow(supplier.users)}
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                                <tr className={styles.rows}>
                                    <td colSpan={6}>
                                    <PaginationComponent
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        totalPages={totalPages}
                        currentPage={currentPage}
                        handlePageChange={handlePageChange}
                    />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                         <Modal
    isOpen={isDeleteModalOpen}
    onRequestClose={() => setIsDeleteModalOpen(false)}
    className={styles.modal}
    overlayClassName={styles.overlay}
>
    <h2>Deleting Supplier</h2>
    <p>{selectedSupplierToDelete.name}</p>
    
    {selectedSupplierToDeleteOrders && selectedSupplierToDeleteOrders.length > 0 && (
        <>
        <p><strong>OBS. There are exisiting orders pending for this Supplier</strong></p>
        <table>
            <thead>
                <tr>
                    <th>Order ID</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {selectedSupplierToDeleteOrders.map(order => (
                    <tr key={order.orderId}>
                        <td>{order.orderId}</td>
                        <td>{order.status}</td>
                    </tr>
                ))}
            </tbody>
        </table>
        </>
    )}
    <div className={styles.buttonGroup}>
    {selectedSupplierToDeleteOrders && selectedSupplierToDeleteOrders.length > 0 ? 
    <button className={styles.expiringButton} onClick={() => expireSupplier(selectedSupplierToDelete.id)}>Set as Expiring</button> :
    <button className={styles.deleteButtonModal} onClick={() => deleteSupplier(selectedSupplierToDelete.id)}> Delete</button>
    }
    </div>
</Modal>
        </div>
    );
};

export default Suppliers;
