import React, { useState, useEffect } from 'react';
import styles from './Updates.module.scss';
import supplierService from '../../services/supplierService';
import DOMPurify from 'dompurify';

const Updates = () => {
    const [updates, setUpdates] = useState([]);
    const [expanded, setExpanded] = useState({}); // Håller koll på expanderade rader

    // Hämtar uppdateringar från backend
    useEffect(() => {
        const fetchUpdates = async () => {
            try {
                const updates = await supplierService.fetchUpdates();
                setUpdates(updates);
            } catch (error) {
                console.error('Error fetching updates:', error);
            }
        };
        fetchUpdates();
    }, []);

    // Toggle för att expandera eller dölja innehållet
    const toggleExpand = (id) => {
        setExpanded(prev => ({
            ...prev,
            [id]: !prev[id]
        }));
    };

    return (
        <div className={styles.updatesContainer}>
            <h2>Updates</h2>
            <div className={styles.table}>
                {updates.map((update) => (
                    <div key={update.id}>
                        <div className={`${styles.row} ${expanded[update.id] ? styles.expanded : ''}`} onClick={() => toggleExpand(update.id)}>
                            <div className={`${styles.arrow} ${expanded[update.id] ? styles.expanded : ''}`}>
                                ▶
                            </div>
                            <div className={styles.cell}>
                                <strong>ID:</strong> {update.id}
                            </div>
                            <div className={styles.cell}>
                                <strong>Date:</strong> {new Date(update.createdAt).toLocaleDateString()}
                            </div>
                            <div className={styles.cell}>
                                <strong>Subject:</strong> {update.subject}
                            </div>
                        </div>
                        {/* Expanderad info visas under raden */}
                        {expanded[update.id] && (
                            <div className={styles.expandedRow}>
                                <div
  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(update.info) }}
/>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Updates;
