// Orders.jsx
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Orders.module.scss';
import GridInfoBox from '../../components/GridInfoBox';
import Tooltip from '@mui/material/Tooltip';
import LoadingSpinner from '../../components/LoadingSpinner';
import HoverableOverflowText from '../../components/HoverableOverflowText';

Modal.setAppElement('#root'); // Set the app element for accessibility

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [visibleItems, setVisibleItems] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // State for the Sync Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMerchantForSync, setSelectedMerchantForSync] = useState('all');
  const [selectedStoreForSync, setSelectedStoreForSync] = useState('all');
  const [storesSync, setStoresSync] = useState([]);
  // Merchants and Stores data
  const [merchants, setMerchants] = useState([]);
  const [stores, setStores] = useState([]);
  // State for filters
  const [appliedFilters, setAppliedFilters] = useState({
    statusFilter: 'all',
    dateRange: [],
    searchQuery: '',
    merchantFilter: 'all',
    storeFilter: 'all',
  });
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [loading, setLoading] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [mergedData, setMergedData] = useState([]);
  const [mergedModal, setMergedModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isPricesModalOpen, setIsPricesModalOpen] = useState(false);
  const [selectedOrderForPrices, setSelectedOrderForPrices] = useState(null);
  const [orderItemsForPrices, setOrderItemsForPrices] = useState([]);
  const [loadingPrices, setLoadingPrices] = useState(false);
  const [isCustomerModalOpen, setIsCustomerModalOpen] = useState(false);
  const [selectedOrderForCustomer, setSelectedOrderForCustomer] = useState(null);
  const [customerData, setCustomerData] = useState({});
  const [savingCustomerData, setSavingCustomerData] = useState(false);
  // New State for Group Discount Modal
  const [isGroupDiscountModalOpen, setIsGroupDiscountModalOpen] = useState(false);
  const [groupOrderItems, setGroupOrderItems] = useState([]);
  const [currentProductIndex, setCurrentProductIndex] = useState(0);
  const [options] = useState(['Active', 'Archived']); // Lägg till 'Declined'
  const [activeOption, setActiveOption] = useState(options[0]);


  const statusOptions = [
    { value: 'all', label: 'All Statuses' },
    { value: 'Awaiting Customer Payment', label: 'Awaiting Customer Payment' },
    { value: 'Awaiting Payment', label: 'Awaiting Payment' },
    { value: 'Awaiting Payment Confirmation', label: 'Awaiting Payment Confirmation' },
    { value: 'Awaiting Fulfillment', label: 'Awaiting Fulfillment' },
    { value: 'Partially Fulfilled', label: 'Partially Fulfilled' },
    { value: 'Fulfilled', label: 'Fulfilled' },
    { value: 'Cancel Requested', label: 'Cancel Requested' },
    { value: 'Cancelled', label: 'Cancelled' },
  ];

  const statusColors = {
    'Awaiting Payment': 'rgba(255, 235, 120, 1)',
    'Awaiting Fulfillment': 'rgba(213, 235, 255, 1)',
    'Partially Fulfilled': 'rgba(255, 214, 164, 1)',
    'Fulfilled': 'rgba(0, 0, 0, .06)',
    'Cancelled': 'rgba(255, 214, 164, 1)',
  };

  const getStatusColor = (status) => {
    return statusColors[status] || '#808080';
  };

  const mergeColors = [
    '#1F77B4', // Dark Blue
    '#FF7F0E', // Dark Orange
    '#2CA02C', // Dark Green
    '#D62728', // Dark Red
    '#9467BD', // Dark Purple
    '#8C564B', // Brown
    '#E377C2', // Pink
    '#7F7F7F', // Gray
    '#BCBD22', // Olive
    '#17BECF', // Teal
    // Add more colors if needed
  ];

  const getColorForMergeId = (mergeId) => {
    const index = Math.abs(mergeId % mergeColors.length);
    return mergeColors[index];
  };

  useEffect(() => {
    fetchMerchants();
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [currentPage, itemsPerPage, appliedFilters]);

  const fetchMerchants = async () => {
    try {
      const merchantsData = await adminService.fetchMerchants();
      setMerchants(merchantsData);
    } catch (error) {
      console.error('Failed to fetch merchants:', error);
    }
  };

  const fetchStores = async (merchantId) => {
    try {
      const storesData = await adminService.fetchMerchantStores(merchantId);
      setStores(storesData);
      console.log(storesData)
    } catch (error) {
      console.error('Failed to fetch stores:', error);
      setStores([]);
    }
  };

  const handleMerchantChange = async (merchantId) => {
    if (merchantId === 'all') {
      setStores([]);
    } else {
      await fetchStores(merchantId);
    }
  };

  const handleApplyFilters = (filters) => {
    // Replace 'all' with null to send to backend
    const processedFilters = {
      ...filters,
      merchantFilter: filters.merchantFilter === 'all' ? null : filters.merchantFilter,
      storeFilter: filters.storeFilter === 'all' ? null : filters.storeFilter,
    };
    setAppliedFilters(processedFilters);
    setCurrentPage(1);
  };

  const handleOptionClick = (option) => {
    setInitialLoad(true);
    setActiveOption(option);
      setAppliedFilters({
        statusFilter: 'all',
        dateRange: [],
        searchQuery: '',
        merchantFilter: 'all',
        storeFilter: 'all',
      });
    setCurrentPage(1);
  };
  

  const fetchOrders = async () => {
    setLoading(true);
    try {
      // Destructure the applied filters
      const {
        statusFilter,
        dateRange,
        searchQuery,
        merchantFilter: rawMerchantFilter,
        storeFilter: rawStoreFilter,
      } = appliedFilters;
  
      let startDate, endDate;
      if (dateRange.length > 0) {
        startDate = dateRange[0].startDate;
        endDate = dateRange[0].endDate;
      }
  
      // Handle 'all' selection by setting them to null
      const merchantFilter = rawMerchantFilter === "all" ? null : rawMerchantFilter;
      const storeFilter = rawStoreFilter === "all" ? null : rawStoreFilter;
      const archived = activeOption === "Archived";
      // Fetch the orders with the modified filters
      const data = await adminService.fetchAllOrdersByPageAndFilters(
        statusFilter,
        currentPage,
        itemsPerPage,
        searchQuery,
        startDate,
        endDate,
        merchantFilter,
        storeFilter,
        archived
      );
  
      setOrders(data.orders || []);
      setTotalPages(data.totalPages);
  
      if (initialLoad) {
        if (!data.orders.length) {
          setFiltersApplied(false);
        } else {
          setFiltersApplied(true);
        }
        setInitialLoad(false);
      }
  
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      setOrders([]);
      setLoading(false);
    }
  };

  const fetchMergeData = async (mergeId) => {
    try {
      const { success, mergeGroup } = await adminService.fetchMergeByMergeId(mergeId);
      if (success && mergeGroup) {
        setMergedData([mergeGroup]);
        setMergedModal(true);
      } else {
        console.error('No merge group found.');
      }
    } catch (error) {
      console.error('Failed to fetch merge info:', error);
    }
  };

  const handleSyncClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMerchantForSync('all');
    setSelectedStoreForSync('all');
    setStoresSync([]);
  };

  const fetchStoresForSync = async (merchantId) => {
    try {
      const storesData = await adminService.fetchMerchantStores(merchantId);
      setStoresSync(storesData);
    } catch (error) {
      console.error('Failed to fetch stores:', error);
      setStores([]);
    }
  };

  const handleSyncMerchantChange = async (event) => {
    const merchantId = event.target.value;
    setSelectedMerchantForSync(merchantId);
    setSelectedStoreForSync('all');

    if (merchantId === 'all') {
      setStores([]);
    } else {
      await fetchStoresForSync(merchantId);
    }
  };

  const handleSyncSubmit = async () => {
    if (!selectedMerchantForSync || selectedMerchantForSync === 'all') {
      alert('Please select a merchant.');
      return;
    }

    if (selectedStoreForSync === 'all' && stores.length > 0) {
      alert('Please select a store or choose "All Stores".');
      return;
    }

    try {
      const response = await adminService.syncShopifyOrders(
        selectedStoreForSync === 'all' ? null : selectedStoreForSync,
        selectedMerchantForSync
      );
      alert(response.message);
      fetchOrders();
      closeModal();
    } catch (error) {
      console.error('Failed to sync Shopify orders:', error);
    }
  };

  const toggleItemDetails = async (orderId) => {
    if (visibleItems[orderId]) {
      setVisibleItems((prev) => ({
        ...prev,
        [orderId]: false,
      }));
    } else {
      setVisibleItems((prev) => ({
        ...prev,
        [orderId]: 'loading',
      }));
      try {
        const archived = activeOption === "Archived";
        const items = await adminService.fetchOrderItems(orderId, archived);
        setOrders((prevOrders) =>
          prevOrders.map((order) => (order.orderId === orderId ? { ...order, items } : order))
        );
      } catch (error) {
        console.error('Failed to fetch order items:', error);
      } finally {
        setVisibleItems((prev) => ({
          ...prev,
          [orderId]: true,
        }));
      }
    }
  };

  const fetchGroupOrderItems = async () => {
    try {
      if (selectedRows.length === 0) {
        alert('No orders selected.');
        return;
      }
  
      const response = await adminService.fetchGroupOrderItems(selectedRows);
      setGroupOrderItems(response);
      setIsGroupDiscountModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch group order items:', error);
    }
  };  
  
  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...orders].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
    setOrders(sortedData);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleRowSelect = (orderId) => {
    setSelectedRows((prevSelectedRows) => {
      if (prevSelectedRows.includes(orderId)) {
        return prevSelectedRows.filter((id) => id !== orderId);
      } else {
        return [...prevSelectedRows, orderId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedRows.length === orders.length && orders.length > 0) {
      setSelectedRows([]);
    } else {
      setSelectedRows(
        orders
          .filter((order) => order.status !== "Cancelled") // Filtrerar bort cancelled orders
          .map((order) => order.orderId) // Returnerar endast orderId
      );
    }
  };

  const openPricesModal = async (order) => {
    setSelectedOrderForPrices(order);
    setIsPricesModalOpen(true);
    setLoadingPrices(true);
    try {
      const items = await adminService.fetchOrderItems(order.orderId, false);
      setOrderItemsForPrices(items);
    } catch (error) {
      console.error('Failed to fetch order items for prices:', error);
      setOrderItemsForPrices([]);
    } finally {
      setLoadingPrices(false);
    }
  };

  const closePricesModal = () => {
    setIsPricesModalOpen(false);
    setSelectedOrderForPrices(null);
    setOrderItemsForPrices([]);
  };

  const openCustomerModal = (order) => {
    setSelectedOrderForCustomer(order);
    setCustomerData(order.customerData);
    setIsCustomerModalOpen(true);
  };

  const closeCustomerModal = () => {
    setIsCustomerModalOpen(false);
    setSelectedOrderForCustomer(null);
    setCustomerData({});
    setSavingCustomerData(false);
  };

  const handleCustomerDataChange = (e) => {
    const { name, value } = e.target;
    setCustomerData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const saveCustomerData = async () => {
    setSavingCustomerData(true);
    try {
      await adminService.editOrderCustomer(selectedOrderForCustomer.orderId, customerData);
      alert('Customer data updated successfully.');
      closeCustomerModal();
      fetchOrders(); // Refresh the orders to get updated customer data
    } catch (error) {
      console.error('Failed to update customer data:', error);
      alert('Failed to update customer data.');
    } finally {
      setSavingCustomerData(false);
    }
  };

  const createDetailRow = (order) => {
    return (
      <td colSpan="11">
        <table>
          <thead>
            <tr>
              <th className={styles.smallestCell}></th>
              <th>Product Name</th>
              <th>Variant</th>
              <th>Quantity</th>
              <th>Product Price</th>
              <th>Shipping Price</th>
              <th>Markup</th>
              <th>Total Price</th>
              <th colSpan={3}>Fulfilled</th>
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => (
              <tr key={`item-${order.orderId}-${index}`}>
                <td className={styles.smallestCell}></td>
                <td>
                  <a href={`./products/${item.productId}`}>
                    <HoverableOverflowText text={item.productName} />
                  </a>
                </td>
                <td>{item.variantTitle || '-'}</td>
                <td>{item.quantity}</td>
                <td>
                  {item.productPrice ? parseFloat(item.productPrice).toFixed(2) : '0.00'}€
                </td>
                <td>
                  {item.shippingPrice ? parseFloat(item.shippingPrice).toFixed(2) : '0.00'}€
                </td>
                <td>
                  {item.markup !== undefined && item.markup !== null
                    ? parseFloat(item.markup).toFixed(2)
                    : '0.00'}
                  €
                </td>
                <td>
                  {(
                    (item.productPrice ? parseFloat(item.productPrice) : 0) +
                    (item.shippingPrice ? parseFloat(item.shippingPrice) : 0) +
                    (item.markup ? parseFloat(item.markup) : 0)
                  ).toFixed(2)}
                  €
                </td>
                <td>
                  {item.isFulfilled ? (
                    <div className={styles.checkedCircle}>
                      <span className="material-symbols-outlined">check_circle</span>
                    </div>
                  ) : (
                    <div className={styles.unCheckedCircle}>
                      <span className="material-symbols-outlined">cancel</span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    );
  };

  const handleProductLevelChange = (productIndex, field, value) => {
    setGroupOrderItems((prevItems) => {
      const updatedItems = [...prevItems];
      const product = updatedItems[productIndex];
      product[field] = value !== '' ? parseFloat(value) : undefined;
      return updatedItems;
    });
  };

  // Handle changes at the variant level
  const handleVariantLevelChange = (productIndex, variantIndex, field, value) => {
    setGroupOrderItems((prevItems) => {
      const updatedItems = [...prevItems];
      const variant = updatedItems[productIndex].variants[variantIndex];
      variant[field] = value !== '' ? parseFloat(value) : undefined;
      return updatedItems;
    });
  };

// Handle changes at the price variant level
const handlePriceVariantChange = (
  productIndex,
  variantIndex,
  priceVariantIndex,
  field,
  value
) => {
  setGroupOrderItems((prevItems) => {
    const updatedItems = [...prevItems];
    const priceVariant =
      updatedItems[productIndex].variants[variantIndex].priceVariants[priceVariantIndex];
    priceVariant[field] = value !== '' ? parseFloat(value) : undefined;
    return updatedItems;
  });
};

const safeParseFloat = (value, defaultValue = 0) => {
  const parsed = parseFloat(value);
  return isNaN(parsed) ? defaultValue : parsed;
};

const handleSaveGroupDiscount = async () => {
  try {
    const updatedOrderItems = [];

    groupOrderItems.forEach((product) => {
      product.variants.forEach((variant) => {
        variant.priceVariants.forEach((priceVariant) => {
          // Hämta priser baserat på prioritet
          const newProductPrice =
            priceVariant.newProductPrice !== undefined
              ? priceVariant.newProductPrice
              : variant.newProductPriceAllPriceClasses !== undefined
              ? variant.newProductPriceAllPriceClasses
              : product.newProductPriceAllVariants !== undefined
              ? product.newProductPriceAllVariants
              : null;

          const newMarkup =
            priceVariant.newMarkup !== undefined
              ? priceVariant.newMarkup
              : variant.newMarkupAllPriceClasses !== undefined
              ? variant.newMarkupAllPriceClasses
              : product.newMarkupAllVariants !== undefined
              ? product.newMarkupAllVariants
              : null;

          // Kontrollera om några ändringar har gjorts
          if (newProductPrice !== null || newMarkup !== null) {
            updatedOrderItems.push({
              orderItemIds: priceVariant.orderItemIds,
              newProductPrice:
                newProductPrice !== null ? parseFloat(newProductPrice) : undefined,
              newMarkup: newMarkup !== null ? parseFloat(newMarkup) : undefined,
            });
          }
        });
      });
    });

    if (updatedOrderItems.length === 0) {
      alert('No changes made.');
      return;
    }

    // Skicka den uppdaterade datan till backend
    await adminService.updateGroupOrderItems(updatedOrderItems);

    alert('Order items updated successfully.');
    setIsGroupDiscountModalOpen(false);
    fetchOrders();
  } catch (error) {
    console.error('Failed to update order items:', error);
    alert('Failed to update order items.');
  }
};

const deleteOrder = async (orderId) => {
  try {
    if (window.confirm("Do you really want to delete this order?")) {
      await adminService.deleteOrders([orderId]);
      fetchOrders();
    }
  } catch (error) {
    console.error('Failed to delete order:', error);
  }
};



  if (loading) {
    return (
      <div className={styles.spinnerContainer}>
        <LoadingSpinner />
      </div>
    );
  }

  // Generate color map for each orderGroupId
  const mergeOrderColorMap = {};
  let colorIndex = 0;

  // First, create a mapping to count the number of orders per orderGroupId
  const orderGroupCountMap = {};

  // Count orders per orderGroupId
  orders.forEach((order) => {
    if (order.orderGroupId) {
      if (!orderGroupCountMap[order.orderGroupId]) {
        orderGroupCountMap[order.orderGroupId] = 0;
      }
      orderGroupCountMap[order.orderGroupId]++;
    }
  });

  // Add to color map only if there are more than one order with the same orderGroupId
  orders.forEach((order) => {
    if (
      order.orderGroupId &&
      orderGroupCountMap[order.orderGroupId] > 1 &&
      !mergeOrderColorMap[order.orderGroupId]
    ) {
      mergeOrderColorMap[order.orderGroupId] = mergeColors[colorIndex % mergeColors.length];
      colorIndex++;
    }
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Orders</h1>
      </div>
      <button className={styles.loadShopifyButton} onClick={handleSyncClick}>
        <span className="material-symbols-outlined">sync</span>Sync Shopify Orders
      </button>
      <div className={styles.tableContainer}>
        <div className={styles.filterHeader}>
        <button
  className={styles.groupDiscount}
  disabled={selectedRows.length === 0}
  onClick={fetchGroupOrderItems}
>
  <span className="material-symbols-outlined">edit</span>
  Set group discount
</button>
<div className={styles.optionContainer}>
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleOptionClick(option)}
            className={`${styles.optionButton} ${
              activeOption === option ? styles.active : ""
            }`}
            >
            {option}
          </button>
        ))}
      </div>

          <FilterComponent
            initialFilters={appliedFilters}
            statusOptions={activeOption === "Active" ? statusOptions : [{ value: 'all', label: 'Archived' }]}
            searchPlaceholder={activeOption === "Active" ? "Search by Shopify Name..." : "Order ID"}
            onApplyFilters={handleApplyFilters}
            merchantOptions={merchants}
            storeOptions={stores}
            onMerchantChange={handleMerchantChange}
          />
        </div>
        <table className={styles.table}>
          <thead className={styles.mainThead}>
            <tr>
              <th className={styles.smallestCell}>
                {activeOption !== "Archived" &&
                <input
                  type="checkbox"
                  checked={selectedRows.length === orders.length && orders.length > 0}
                  onChange={handleSelectAll}
                />
                }
              </th>
              <th onClick={() => handleSort('orderId')}>
                <div className={styles.thHeader}>
                  Order ID
                  <div className={styles.sortingArrows}>
                    <span
                      className={`${styles.sort_arrow} ${styles.asc} ${
                        sortConfig.key === 'orderId' && sortConfig.direction === 'ascending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                    <span
                      className={`${styles.sort_arrow} ${styles.desc} ${
                        sortConfig.key === 'orderId' && sortConfig.direction === 'descending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                  </div>
                </div>
              </th>
              <th>Merchant</th>
              <th>Store</th>
              <th>Items</th>
              <th>Total Price</th>
              <th onClick={() => handleSort('shopifyOrderId')}>
                <div className={styles.thHeader}>
                  Shopify ID
                  <div className={styles.sortingArrows}>
                    <span
                      className={`${styles.sort_arrow} ${styles.asc} ${
                        sortConfig.key === 'shopifyOrderId' && sortConfig.direction === 'ascending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                    <span
                      className={`${styles.sort_arrow} ${styles.desc} ${
                        sortConfig.key === 'shopifyOrderId' && sortConfig.direction === 'descending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                  </div>
                </div>
              </th>
              <th onClick={() => handleSort('status')}>
                <div className={styles.thHeader}>
                  Status
                  <div className={styles.sortingArrows}>
                    <span
                      className={`${styles.sort_arrow} ${styles.asc} ${
                        sortConfig.key === 'status' && sortConfig.direction === 'ascending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                    <span
                      className={`${styles.sort_arrow} ${styles.desc} ${
                        sortConfig.key === 'status' && sortConfig.direction === 'descending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                  </div>
                </div>
              </th>
              <th>Customer Data</th>
              <th onClick={() => handleSort('createdAt')}>
                <div className={styles.thHeader}>
                  Date
                  <div className={styles.sortingArrows}>
                    <span
                      className={`${styles.sort_arrow} ${styles.asc} ${
                        sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                    <span
                      className={`${styles.sort_arrow} ${styles.desc} ${
                        sortConfig.key === 'createdAt' && sortConfig.direction === 'descending'
                          ? styles.active
                          : ''
                      }`}
                    ></span>
                  </div>
                </div>
              </th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order) => (
              <React.Fragment key={order.orderId}>
                <tr
                  className={visibleItems[order.orderId] ? styles.droppeddown : styles.rows}
                  style={{
                    boxShadow: mergeOrderColorMap[order.orderGroupId]
                      ? `inset 10px 0 0 0 ${mergeOrderColorMap[order.orderGroupId]}`
                      : 'none',
                  }}
                >
                  <td className={styles.smallestCell}>
                    {order.status !== "Cancelled" && activeOption !== "Archived" &&
                    <input
                      type="checkbox"
                      checked={selectedRows.includes(order.orderId)}
                      onChange={() => handleRowSelect(order.orderId)}
                    />
                    }

                  </td>
                  <td className={styles.orderIdMerge}>
                    {order.orderId}
                    {order.hasMerges &&
                      order.merges.map((mergeId) => (
                        <button
                          key={mergeId}
                          className={styles.isMergedButton}
                          onClick={() => fetchMergeData(mergeId)}
                          style={{ backgroundColor: getColorForMergeId(mergeId) }}
                        >
                          <span className="material-symbols-outlined">call_merge</span>
                        </button>
                      ))}
                  </td>
                  <td>{order.merchantName}</td>
                  <td>
                    {order.store.storeURL ||
                      order.store.shop.replace('.myshopify.com', '')}
                  </td>
                  <td>
                    <button
                      className={styles.toggleRowsButton}
                      onClick={() => toggleItemDetails(order.orderId)}
                    >
                      {order.itemsCount}
                      <span className="material-symbols-outlined">
                        {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                      </span>
                    </button>
                  </td>
                  <td onClick={() => openPricesModal(order)} className={styles.hoverable}>
                    {order.shippingDiscount > 0 ? (
                      <Tooltip
                        arrow
                        title={`This order had a shipping discount of ${order.shippingDiscount}€ due to multiple products`}
                      >
                        {`${order.totalCost.toFixed(2)}€ (-${order.shippingDiscount.toFixed(2)}€)`}
                      </Tooltip>
                    ) : (
                      `${order.totalCost.toFixed(2)}€`
                    )}
                  </td>
                  <td>{order.shopifyOrderId}</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: getStatusColor(order.status),
                      }}
                      className={styles.orderStatus}
                    >
                      {order.status}
                    </span>
                  </td>
                  <td
                    className={styles.hoverable}
                    onClick={() => openCustomerModal(order)}
                    style={{ cursor: 'pointer' }}
                  >
                    {order.customerData && 
                    <GridInfoBox grid={order.customerData}>
                      {order.customerData.fullName}
                    </GridInfoBox>
                  }
                  </td>
                  <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                  <td>
                    <div className={styles.buttonGroup}>
                      {(order.status === 'Fulfilled' || order.status === 'Cancelled') && !order.isArchived && (
                        <button
                          onClick={() => deleteOrder(order.orderId)}
                          className={styles.archiveButton}
                        >
                          Archive
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
                {visibleItems[order.orderId] === 'loading' ? (
                  <tr className={styles.detailRow} key={`details-${order.orderId}`}>
                    <td colSpan={11}>
                      <table>
                        <tbody>
                          {Array.from({ length: order.itemsCount + 1 }).map((_, index) => (
                            <tr
                              key={index}
                              className={`${styles.rows} ${styles.placeHolderDetailRow}`}
                            >
                              <td colSpan={11}></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                ) : (
                  visibleItems[order.orderId] &&
                  order.items && (
                    <tr className={styles.detailRow} key={`details-${order.orderId}`}>
                      {createDetailRow(order)}
                    </tr>
                  )
                )}
              </React.Fragment>
            ))}
            <tr className={styles.rows}>
              <td colSpan={11}>
                <PaginationComponent
                  itemsPerPage={itemsPerPage}
                  setItemsPerPage={setItemsPerPage}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  otherPagValues={[25, 50, 100, 250]}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Sync Modal */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Sync Shopify Orders"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Sync Shopify Orders</h2>
        <div className={styles.storeAndMerchantSelect}>
          <FormControl fullWidth className={styles.formControl}>
            <InputLabel>Merchant</InputLabel>
            <Select
              value={selectedMerchantForSync}
              onChange={handleSyncMerchantChange}
            >
              <MenuItem value="all">
                <em>All Merchants</em>
              </MenuItem>
              {merchants.map((merchant) => (
                <MenuItem key={merchant.id} value={merchant.id}>
                  {merchant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth className={styles.formControl}>
            <InputLabel>Store</InputLabel>
            <Select
              value={selectedStoreForSync}
              onChange={(e) => setSelectedStoreForSync(e.target.value)}
              disabled={selectedMerchantForSync === 'all'}
            >
              <MenuItem value="all">
                <em>All Stores</em>
              </MenuItem>
              {storesSync.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.name || store.shop}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={styles.modalActions}>
          <button onClick={handleSyncSubmit} className={styles.syncButton}>
            Sync
          </button>
          <button onClick={closeModal} className={styles.closeButton}>
            Close
          </button>
        </div>
      </Modal>

      {/* Prices Modal */}
      <Modal
        isOpen={isPricesModalOpen}
        onRequestClose={closePricesModal}
        contentLabel="Order Prices"
        className={styles.modal}
        overlayClassName={styles.overlay}
      >
        <h2>Order Prices - {selectedOrderForPrices?.orderId}</h2>
        {loadingPrices ? (
          <div>Loading...</div>
        ) : (
          <div className={styles.pricesTableContainer}>
            <table className={styles.pricesTable}>
              <thead>
                <tr>
                  <th>Product Name</th>
                  <th>Variant</th>
                  <th>Quantity</th>
                  <th>Product Price</th>
                  <th>Shipping Price</th>
                  <th>Markup</th>
                  <th>Total Price</th>
                </tr>
              </thead>
              <tbody>
                {orderItemsForPrices.map((item, index) => (
                  <tr key={`price-item-${index}`}>
                    <td>{item.productName}</td>
                    <td>
                      {item.Variant && item.Variant.categories.length > 0 ? (
                        item.Variant.categories.map((cv, catIndex) => (
                          <span key={catIndex}>
                            {`${cv.name}: ${cv.value}`}
                            {catIndex !== item.Variant.categories.length - 1 && ', '}
                          </span>
                        ))
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{item.quantity}</td>

                    <td>
                      {item.productPrice ? parseFloat(item.productPrice).toFixed(2) : '0.00'}€
                    </td>
                    <td>
                      {item.shippingPrice
                        ? parseFloat(item.shippingPrice).toFixed(2)
                        : '0.00'}
                      €
                    </td>
                    <td>
                      {item.markup !== undefined && item.markup !== null
                        ? parseFloat(item.markup).toFixed(2)
                        : '0.00'}
                      €
                    </td>
                    <td>
                      {(
                        (item.productPrice ? parseFloat(item.productPrice) : 0) +
                        (item.shippingPrice ? parseFloat(item.shippingPrice) : 0) +
                        (item.markup ? parseFloat(item.markup) : 0)
                      ).toFixed(2)}
                      €
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* Grand Total */}
            <div className={styles.grandTotal}>
              <strong>Grand Total: </strong>
              {orderItemsForPrices
                .reduce((acc, item) => {
                  const price = item.productPrice ? parseFloat(item.productPrice) : 0;
                  const shipping = item.shippingPrice ? parseFloat(item.shippingPrice) : 0;
                  const markup = item.markup ? parseFloat(item.markup) : 0;
                  return acc + price + shipping + markup;
                }, 0)
                .toFixed(2)}
              €
            </div>
            <button onClick={closePricesModal} className={styles.closeModalButton}>
              Close
            </button>
          </div>
        )}
      </Modal>


{isCustomerModalOpen && (
  <Modal
    isOpen={isCustomerModalOpen}
    onRequestClose={closeCustomerModal}
    contentLabel="Edit Customer Data"
    className={styles.modal}
    overlayClassName={styles.overlay}
  >
    <h2>Edit Customer Data</h2>
    <form className={styles.customerForm}>
      <div className={styles.formGroup}>
        <label>Full Name</label>
        <input
          type="text"
          name="fullName"
          value={customerData.fullName || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Email</label>
        <input
          type="email"
          name="mail"
          value={customerData.mail || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Phone</label>
        <input
          type="text"
          name="phoneNumber"
          value={customerData.phoneNumber || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Address Line 1</label>
        <input
          type="text"
          name="deliveryAddressLine"
          value={customerData.deliveryAddressLine || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Address Line 2</label>
        <input
          type="text"
          name="deliveryAddressLine2"
          value={customerData.deliveryAddressLine2 || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>City</label>
        <input
          type="text"
          name="deliveryCity"
          value={customerData.deliveryCity || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Province</label>
        <input
          type="text"
          name="deliveryProvince"
          value={customerData.deliveryProvince || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Country</label>
        <input
          type="text"
          name="deliveryCountry"
          value={customerData.deliveryCountry || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.formGroup}>
        <label>Zip Code</label>
        <input
          type="text"
          name="deliveryZIP"
          value={customerData.deliveryZIP || ''}
          onChange={handleCustomerDataChange}
        />
      </div>
      <div className={styles.modalActions}>
        <button
          type="button"
          onClick={saveCustomerData}
          className={styles.saveButton}
          disabled={savingCustomerData}
        >
          {savingCustomerData ? 'Saving...' : 'Save'}
        </button>
        <button onClick={closeCustomerModal} className={styles.closeButton}>
          Close
        </button>
      </div>
    </form>
  </Modal>
)}
      {/* Merged Orders Modal */}
      {mergedModal && (
        <Modal
          isOpen={mergedModal}
          onRequestClose={() => setMergedModal(false)}
          contentLabel="Merged Orders"
          className={styles.modal}
          overlayClassName={styles.overlay}
        >
          <h2>Merged Orders</h2>
          <div className={styles.mergeGroupsContainer}>
            {mergedData.map((group) => (
              <div key={group.mergeFulfillmentGroupId} className={styles.mergeGroup}>
                <p>
                  <strong>Supplier:</strong> {group.supplier}
                </p>
                <table className={styles.mergeTable}>
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Product Name</th>
                      <th>Variant</th>
                      <th>Quantity</th>
                      <th>SKU</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.fulfillmentOrders.map((fulfillmentOrder) => (
                      <React.Fragment key={fulfillmentOrder.fulfillmentOrderId}>
                        {fulfillmentOrder.orderItems.map((item) => (
                          <tr key={item.id}>
                            <td>{fulfillmentOrder.orderId}</td>
                            <td>{item.productName}</td>
                            <td>{item.variantTitle ? item.variantTitle : '-'}</td>
                            <td>{item.quantity}</td>
                            <td>{item.SKU}</td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            ))}
            <button onClick={() => setMergedModal(false)} className={styles.closeButton}>
              Close
            </button>
          </div>
        </Modal>
      )}
{isGroupDiscountModalOpen && (
    <Modal
      isOpen={isGroupDiscountModalOpen}
      onRequestClose={() => setIsGroupDiscountModalOpen(false)}
      contentLabel="Set Group Discount"
      className={styles.modal}
      overlayClassName={styles.overlay}
    >
      <div className={styles.modalHeader}>
        <button
          className={`${styles.modalArrow} ${styles.arrowLeft}`}
          onClick={() =>
            setCurrentProductIndex((prev) => (prev > 0 ? prev - 1 : groupOrderItems.length - 1))
          }
        >
          &lt;
        </button>
        <h2>
          Set Group Discount ({currentProductIndex + 1}/{groupOrderItems.length})
        </h2>
        <button
          className={`${styles.modalArrow} ${styles.arrowRight}`}
          onClick={() =>
            setCurrentProductIndex((prev) =>
              prev < groupOrderItems.length - 1 ? prev + 1 : 0
            )
          }
        >
          &gt;
        </button>
      </div>

      <div className={styles.groupDiscountContainer}>
        {groupOrderItems.length > 0 && (
          <div className={styles.groupItem}>
            {(() => {
              const item = groupOrderItems[currentProductIndex];
              return (
                <>
                  <h3>{item.productName}</h3>
                  <p>Supplier: {item.supplierName}</p>
                  <p>Total Quantity: {item.totalQuantity}</p>

                  {/* Visa gruppinputs om det finns flera varianter */}
                  {item.variants.length > 1 && (
                    <div className={styles.inputGroup}>
                      <label>
                        Set New Product Price for All Variants:
                        <input
                          type="number"
                          step="0.01"
                          value={
                            item.newProductPriceAllVariants !== undefined
                              ? item.newProductPriceAllVariants
                              : ''
                          }
                          onChange={(e) =>
                            handleProductLevelChange(
                              currentProductIndex,
                              'newProductPriceAllVariants',
                              e.target.value
                            )
                          }
                        />
                      </label>
                      <label>
                        Set New Markup for All Variants:
                        <input
                          type="number"
                          step="0.01"
                          value={
                            item.newMarkupAllVariants !== undefined
                              ? item.newMarkupAllVariants
                              : ''
                          }
                          onChange={(e) =>
                            handleProductLevelChange(
                              currentProductIndex,
                              'newMarkupAllVariants',
                              e.target.value
                            )
                          }
                        />
                      </label>
                    </div>
                  )}

                  {/* Variants */}
                  {item.variants.map((variant, variantIndex) => (
                    <div key={variantIndex} className={styles.variantGroup}>
                      <h4>Variant: {variant.variantTitle || 'Default'}</h4>
                      <p>Total Quantity: {variant.totalQuantity}</p>

                      {/* Visa gruppinputs om det finns flera prisklasser */}
                      {variant.priceVariants.length > 1 && (
                        <div className={styles.inputGroup}>
                          <label>
                            Set New Product Price for All Price Classes:
                            <input
                              type="number"
                              step="0.01"
                              value={
                                variant.newProductPriceAllPriceClasses !== undefined
                                  ? variant.newProductPriceAllPriceClasses
                                  : ''
                              }
                              onChange={(e) =>
                                handleVariantLevelChange(
                                  currentProductIndex,
                                  variantIndex,
                                  'newProductPriceAllPriceClasses',
                                  e.target.value
                                )
                              }
                            />
                          </label>
                          <label>
                            Set New Markup for All Price Classes:
                            <input
                              type="number"
                              step="0.01"
                              value={
                                variant.newMarkupAllPriceClasses !== undefined
                                  ? variant.newMarkupAllPriceClasses
                                  : ''
                              }
                              onChange={(e) =>
                                handleVariantLevelChange(
                                  currentProductIndex,
                                  variantIndex,
                                  'newMarkupAllPriceClasses',
                                  e.target.value
                                )
                              }
                            />
                          </label>
                        </div>
                      )}

                      {/* Price Variants */}
                      {variant.priceVariants.map((priceVariant, priceVariantIndex) => {
                        // Beräkna det uppdaterade priset baserat på prioritet
                        const updatedProductPrice =
                          priceVariant.newProductPrice !== undefined
                            ? priceVariant.newProductPrice
                            : variant.newProductPriceAllPriceClasses !== undefined
                            ? variant.newProductPriceAllPriceClasses
                            : item.newProductPriceAllVariants !== undefined
                            ? item.newProductPriceAllVariants
                            : priceVariant.productPrice;

                        const updatedMarkup =
                          priceVariant.newMarkup !== undefined
                            ? priceVariant.newMarkup
                            : variant.newMarkupAllPriceClasses !== undefined
                            ? variant.newMarkupAllPriceClasses
                            : item.newMarkupAllVariants !== undefined
                            ? item.newMarkupAllVariants
                            : priceVariant.markup;

                        const totalPrice =
                          safeParseFloat(updatedProductPrice) +
                          safeParseFloat(priceVariant.shippingPrice) +
                          safeParseFloat(updatedMarkup);

                        return (
                          <div key={priceVariantIndex} className={styles.priceVariant}>
                            <h5>Price Variant {priceVariantIndex + 1}</h5>
                            <p>Current Product Price: {priceVariant.productPrice}€</p>
                            <p>Current Shipping Price: {priceVariant.shippingPrice}€</p>
                            <p>Current Markup: {priceVariant.markup}€</p>
                            <p>Current Total: {priceVariant.markup + priceVariant.shippingPrice + priceVariant.productPrice}€</p>
                            <p>Quantity: {priceVariant.totalQuantity}</p>

                            <div className={styles.inputGroup}>
                              <label>
                                New Product Price:
                                <input
                                  type="number"
                                  step="0.01"
                                  value={
                                    priceVariant.newProductPrice !== undefined
                                      ? priceVariant.newProductPrice
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handlePriceVariantChange(
                                      currentProductIndex,
                                      variantIndex,
                                      priceVariantIndex,
                                      'newProductPrice',
                                      e.target.value
                                    )
                                  }
                                />
                              </label>
                              <label>
                                New Markup:
                                <input
                                  type="number"
                                  step="0.01"
                                  value={
                                    priceVariant.newMarkup !== undefined
                                      ? priceVariant.newMarkup
                                      : ''
                                  }
                                  onChange={(e) =>
                                    handlePriceVariantChange(
                                      currentProductIndex,
                                      variantIndex,
                                      priceVariantIndex,
                                      'newMarkup',
                                      e.target.value
                                    )
                                  }
                                />
                              </label>
                            </div>

                            {/* Visa uppdaterade priser */}
                            <div className={styles.updatedPriceDisplay}>
                              <p>
                                <strong>Updated Product Price:</strong>{' '}
                                {safeParseFloat(updatedProductPrice).toFixed(2)}€
                              </p>
                              <p>
                                <strong>Updated Markup:</strong>{' '}
                                {safeParseFloat(updatedMarkup).toFixed(2)}€
                              </p>
                              <p>
                                <strong>Shipping Price:</strong>{' '}
                                {safeParseFloat(priceVariant.shippingPrice).toFixed(2)}€
                              </p>
                              <p>
                                <strong>Total Price:</strong> {totalPrice.toFixed(2)}€
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </>
              );
            })()}
          </div>
        )}
        <div className={styles.modalActions}>
          <button onClick={handleSaveGroupDiscount} className={styles.saveButton}>
            Save
          </button>
          <button
            onClick={() => setIsGroupDiscountModalOpen(false)}
            className={styles.closeButton}
          >
            Close
          </button>
        </div>
      </div>
    </Modal>
  )}






    </div>
  );
};

export default Orders;
