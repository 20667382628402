// components/ProductManagement/ProductManagement.js
import React, { useState, useEffect } from 'react';
import React_Select from 'react-select'; // Use react-select
import merchantService from '../../services/merchantService';
import TablePagination from '@mui/material/TablePagination';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './ProductManagement.module.scss';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import Modal from 'react-modal';
import HoverableList from '../../components/HoverableList';
import LoadingButtonDots from '../../components/LoadingButtonDots';
import LoadingSpinner from '../../components/LoadingSpinner';
import productPlaceHolder from '../../images/productPlaceHolder.jpeg';
import PublishModal from './components/products/PublishModal'; // Corrected import path
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { Link } from 'react-router-dom';

Modal.setAppElement('#root');

function ProductManagement() {
    useEffect(() => {
        document.title = 'Products | Qiwi';
    }, []);

    // State Variables
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [stores, setStores] = useState([]);
    const [selectedStore, setSelectedStore] = useState(0);
    const [showStorePublish, setShowStorePublish] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [pageInfo, setPageInfo] = useState({ next: {}, previous: {}, explored: [] });
    const [totalPages, setTotalPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalProductCount, setTotalProductCount] = useState(0);
    const [productTypes, setProductTypes] = useState('qiwi');
    const [direction, setDirection] = useState('');
    const [activeCardId, setActiveCardId] = useState(null);
    const [showArchived, setShowArchived] = useState(false);
    const [noActiveProducts, setNoActiveProducts] = useState(false);
    const [initialLoad, setInitialLoad] = useState(true);
    const [productImageStates, setProductImageStates] = useState({});
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [selectedProductForPublishing, setSelectedProductForPublishing] = useState(null);
    const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
    const [loadingRemove, setLoadingRemove] = useState(false);
    const [productToDeleteOrders, setProductToDeleteOrders] = useState([]);
    const [loadingModal, setLoadingModal] = useState(false);
    const [loadingExpire, setLoadingExpire] = useState(false);
    // Removed: productMoreInfo, selectedCountry, isSupplyInfoModalOpen

    // Add these state variables
    const [selectedProduct, setSelectedProduct] = useState(null);

    const navigate = useNavigate(); // Initialize useNavigate

    // Functions to fetch data
    const fetchProducts = async () => {
        try {
            setActiveCardId(false);
            setLoading(true);

            let response;
            if (productTypes === 'qiwi' || productTypes === 'archived') {
                response = await merchantService.fetchUserProductsWithFilterAndPagination(currentPage, itemsPerPage, productTypes === 'archived' ? null : selectedStore, showArchived);
            } else {
                const formattedPageInfo = JSON.stringify(pageInfo);
                response = await merchantService.fetchAllShopifyProductsWithFilterAndPagination(selectedStore, itemsPerPage, formattedPageInfo, direction);

                const { next, previous, explored } = response.pageInfo;
                setPageInfo({ next, previous, explored });

                if (response.totalProductCount) {
                    setTotalProductCount(response.totalProductCount);
                }
            }
            if (initialLoad) {
                if (response.products.length === 0) {
                    setNoActiveProducts(true);
                } else {
                    setNoActiveProducts(false);
                }
                setInitialLoad(false);
            }
            setProducts(response.products);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch products', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchStores = async () => {
        try {
            const storesResponse = await merchantService.getStores();
            setStores(storesResponse);
        } catch (error) {
            console.error("Error fetching stores: ", error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, [currentPage, itemsPerPage, productTypes, selectedStore, showArchived]);

    useEffect(() => {
        fetchStores();
    }, []);

    // Function to open more information by navigating
    const openMoreInformation = (productId) => {
        navigate(`/products/${productId}`);
    };

    // Handle product deletion
    const archiveProductModal = async (product) => {
        try {
            setLoadingModal(true);
            const orders = await merchantService.productDeleteStatus(product.id);
            setProductToDeleteOrders(orders.orders);
            setIsRemoveModalOpen(true);
            setSelectedProduct(product);
        } catch (error) {
            console.error('Failed to fetch product delete status:', error);
        } finally {
            setLoadingModal(false);
        }
    };

    const archiveProduct = async () => {
        const confirmed = window.confirm("Are you sure you want to archive this product? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingRemove(true);
                await merchantService.archiveProduct(selectedProduct.id);
                alert("The product has been archived.");
                fetchProducts();
                setIsRemoveModalOpen(false);
            } catch (error) {
                console.error('Failed to delete product:', error);
            } finally {
                setLoadingRemove(false);
            }
        }
    };

    const expireProduct = async () => {
        const confirmed = window.confirm("Are you sure you want to set this product as expiring? This action cannot be undone.");
        if (confirmed) {
            try {
                setLoadingExpire(true);
                await merchantService.expireProduct(selectedProduct.id);
                alert("The product has been set as expiring.");
                fetchProducts();
                setIsRemoveModalOpen(false);
            } catch (error) {
                console.error('Failed to set product as expiring:', error);
            } finally {
                setLoadingExpire(false);
            }
        }
    };

    const handleChangePage = (event, newPage) => {
        if (productTypes === 'shopify') {
            if (newPage + 1 > currentPage) {
                setDirection("next");
                setCurrentPage(currentPage+1);
            } else {
                setDirection("previous");
                setCurrentPage(currentPage-1);
            }
        } else {
            setCurrentPage(newPage);
        }
    };

    const handleStoreChange = (event) => {
        setSelectedStore(event.target.value);
        setPageInfo({ next: {}, previous: {}, explored: [] });
        setCurrentPage(1);
    };

    const toggleCard = (id) => {
        setActiveCardId(prevId => {
            if (prevId === id) {
                return null;
            } else {
                setSelectedProduct(products.find(p => p.id === id) || null);
                setShowStorePublish(null);
                return id;
            }
        });
    };

    const togglePage = (value) => {
        setSelectedProduct(null);
        setActiveCardId(null);
        setProductTypes(value);
        setInitialLoad(true);
        setPageInfo({ next: {}, previous: {}, explored: [] });
        setCurrentPage(1);
        if (value === 'qiwi') {
            setShowArchived(false);
        } else if (value === 'archived') {
            setShowArchived(true);
        }
    };

    const getProductInfo = (product) => {
        let productInfoContent = [];

        if (product.supplier) {
            productInfoContent.push(<p key="supplier">Supplier: {product.supplier}</p>);
        }

        if (product.Stores && product.Stores.length > 0) {
            productInfoContent.push(
                <div key="stores" className={styles.storeInfo}>
                    Store: &nbsp;
                    <HoverableList list={
                        (product.Stores || []).map(store => {
                            const storeName = store.storeURL || store.shop.replace('.myshopify.com', '');
                            return storeName + (store.expiring && !product.expiring ? ' (Expiring)' : '');
                        })
                    } wantRight={true}>
                    </HoverableList>
                </div>
            );
        }

        if (product.variants && product.variants.length > 0 && product.variants[0].title !== "Default Title") {
            console.log(product)
            productInfoContent.push(
                <div key="variants" className={styles.totalVar}>Total Variants:&nbsp; 
                    <HoverableList 
                        list={
                            product.variants[0].title ?
                            product.variants.map(variant => 
                                variant.title
                            ) :
                            product.variants.map(variant => 
                                variant.variantTitle
                            )
                        }
                    />
                </div>
            );
        }

        if (productTypes === 'qiwi') {
            return (
                <>
                    <div className={styles.productInfoContainer}>
                        {productInfoContent}
                        <button
                            className={styles.supplyInfo}
                            onClick={() => openMoreInformation(product.id)}
                        >
                            More Information
                        </button>
                    </div>
                    {!product.expiring ?
                        <div className={styles.productButtons}>
                            <button onClick={() => archiveProductModal(product)}>Archive</button>
                            <button onClick={() => openPublishModal(product)}>Add to Store</button>
                        </div>
                        :
                        <div className={styles.expiringProduct}><span className="material-symbols-outlined">warning</span>The product is expiring</div>
                    }
                </>
            );
        }
        return (
            <div className={styles.productInfoContainer}>
                {productInfoContent}
            </div>
        );
    };

    const getImages = (product) => {
        const productState = productImageStates[product.id] || { index: 0, isPaused: false };
        const currentImageIndex = productState.index;
    
        const isShopifyOrArchived = productTypes === 'shopify' || productTypes === 'archived';
    
        // Funktion för att skapa bildelement, eventuellt wrappt i en Link
        const createImageElement = (src, alt, isClickable) => {
            const image = <img src={src} alt={alt} className={`${styles.productImage} ${isClickable ? styles.clickable : ''}`} />;
    
            if (isClickable) {
                return (
                    <Link to={`/products/${product.id}`} className={styles.imageLink}>
                        {image}
                    </Link>
                );
            }
    
            return image;
        };
    
        if (product.noVariant && product.noVariant.image) {
            return createImageElement(
                product.noVariant.image,
                product.name,
                !isShopifyOrArchived
            );
        }
    
        const uniqueImages = new Set();
        const variantsWithImages = product.variants ? product.variants.filter(variant => {
            if (variant.image && !uniqueImages.has(variant.image)) {
                uniqueImages.add(variant.image);
                return true;
            }
            return false;
        }) : [];
    
        if (variantsWithImages.length === 0) {
            if (product.image) {
                return createImageElement(
                    product.image,
                    product.name,
                    !isShopifyOrArchived
                );
            }
            return createImageElement(
                productPlaceHolder,
                "No image available",
                !isShopifyOrArchived
            );
        }
    
        if (variantsWithImages.length === 1) {
            return createImageElement(
                variantsWithImages[0].image,
                product.name,
                !isShopifyOrArchived
            );
        }
    
        const currentImage = variantsWithImages[currentImageIndex] ? variantsWithImages[currentImageIndex].image : variantsWithImages[0].image;
    
        return (
            <div className={styles.imageCarousel}>
                { !isShopifyOrArchived ? (
                    <Link to={`/products/${product.id}`} className={styles.imageLink}>
                        <img src={currentImage} alt={`Variant ${currentImageIndex}`} className={`${styles.productImage} ${styles.clickable}`} />
                    </Link>
                ) : (
                    <img src={currentImage} alt={`Variant ${currentImageIndex}`} className={styles.productImage} />
                )}
                <button onClick={() => handlePrevImage(product.id, variantsWithImages)} className={styles.prevButton}>{"<"}</button>
                <button onClick={() => handleNextImage(product.id, variantsWithImages)} className={styles.nextButton}>{">"}</button>
            </div>
        );
    };

    const openPublishModal = async (product) => {
        try {
            const detailedProduct = await merchantService.fetchProduct(product.id);
            setSelectedProductForPublishing(detailedProduct);
            setIsPublishModalOpen(true);
        } catch (error) {
            console.error('Failed to fetch detailed product:', error);
        }
    };

    // Function to go to the next image
    const handleNextImage = (productId, variantsWithImages) => {
        if (!variantsWithImages || variantsWithImages.length === 0) return;

        setProductImageStates((prevState) => {
            const currentIndex = prevState[productId]?.index || 0;
            const newIndex = (currentIndex + 1) % variantsWithImages.length;
            return {
                ...prevState,
                [productId]: { index: newIndex, isPaused: prevState[productId]?.isPaused }
            };
        });
    };

    // Function to go to the previous image
    const handlePrevImage = (productId, variantsWithImages) => {
        if (!variantsWithImages || variantsWithImages.length === 0) return;

        setProductImageStates((prevState) => {
            const currentIndex = prevState[productId]?.index || 0;
            const newIndex = (currentIndex - 1 + variantsWithImages.length) % variantsWithImages.length;
            return {
                ...prevState,
                [productId]: { index: newIndex, isPaused: prevState[productId]?.isPaused }
            };
        });
    };

    return (
        <div className={styles.container}>
            <div className={styles.productOption}>
                <a onClick={() => togglePage('qiwi')} className={productTypes === 'qiwi' ? styles.active : ''}>
                    Products
                </a>
                <a onClick={() => togglePage('shopify')} className={productTypes === 'shopify' ? styles.active : ''}>
                    Shopify Products
                </a>
                <a onClick={() => togglePage('archived')} className={productTypes === 'archived' ? styles.active : ''}>
                    Archived Products
                </a>
                { productTypes !== "archived" &&
                <div className={styles.storeSelect}>
                    <FormControl variant="outlined" className={styles.storeSelect} size="small" disabled={noActiveProducts}>
                        <InputLabel>Store</InputLabel>
                        <Select value={selectedStore} onChange={handleStoreChange} label="Store">
                            <MenuItem value={0}>All</MenuItem>
                            {stores.map((store) => (
                                <MenuItem key={store.id} value={store.id}>
                                    {store.storeURL || store.shop.replace('.myshopify.com', '')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                }
            </div>

            {loading ? 
                <div className={styles.placeHolderContainer}>
                    {Array.from({ length: itemsPerPage }).map((_, index) => (
                        <div key={index} className={`${styles.shimmer} ${styles.cardContainer}`}>
                        </div>
                    ))}
                </div>
                :  
                !products.length > 0 ? (
                    <p className={styles.noActiveProducts}>
                        No {productTypes === 'qiwi' ? "active" : productTypes === 'archived' ? "archived" : "shopify"} products found {!noActiveProducts && ", please check your filters"}
                    </p>
                ) : (
                    <>
                        <div className={styles.gridContainer}>
                            {products.map((product, index) => (
                                <div key={product.id} className={`${styles.cardContainer} ${activeCardId === product.id ? styles.activeCard : ''}`}>
                                    <div className={styles.card}>
                                        <div className={styles.imgContainer}>
                                            {getImages(product)}
                                        </div>
                                    </div>
                                    <div className={`${styles.cardContent} ${showStorePublish === product.id ? styles.storePublishcontainer : ''}`}>
                                        <div className={styles.contentHeader}>
                                            <h2>{product.name}</h2>
                                            <div className={styles.wrapper}>
                                            {activeCardId === product.id ? (
                                                <span className="material-symbols-outlined" onClick={() => toggleCard(null)}>
                                                    keyboard_arrow_down
                                                </span>
                                            ) : (
                                                <span className="material-symbols-outlined" onClick={() => toggleCard(product.id)}>
                                                    keyboard_arrow_up
                                                </span>
                                            )}
                                            </div>
                                        </div>
                                        {getProductInfo(product)}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {productTypes === 'qiwi' || productTypes === 'archived' ? (
                            <PaginationComponent
                                itemsPerPage={itemsPerPage}
                                setItemsPerPage={setItemsPerPage}
                                totalPages={totalPages}
                                currentPage={currentPage}
                                handlePageChange={handleChangePage}
                            />
                        ) : (
                            <div className={styles.pags}>
                                <TablePagination
                                    component="div"
                                    count={totalProductCount}
                                    page={currentPage - 1}
                                    onPageChange={handleChangePage}
                                    rowsPerPage={itemsPerPage}
                                    onRowsPerPageChange={() => {}} // Placeholder
                                    rowsPerPageOptions={[]}
                                    labelRowsPerPage="" 
                                    disabled={itemsPerPage === 10 && totalPages === 1}
                                />
                            </div>
                        )}
                    </>
                )
            }

            {isPublishModalOpen &&
                <PublishModal
                    isOpen={isPublishModalOpen}
                    onRequestClose={() => setIsPublishModalOpen(false)}
                    stores={stores}
                    selectedProduct={selectedProductForPublishing}
                    fetchProducts={fetchProducts}
                    merchantService={merchantService}
                />
            }

            {/* Removed Supply Information Modal */}

            {isRemoveModalOpen &&
                <Modal
                    isOpen={isRemoveModalOpen}
                    onRequestClose={() => setIsRemoveModalOpen(false)}
                    contentLabel="Archive Product"
                    className={styles.modaltwo}
                    overlayClassName={styles.overlay}
                >
                    <h3>Archiving Product{selectedProduct && `: ${selectedProduct.name}`}</h3>
                    {loadingModal ? (
                        <div className={styles.spinnerModal}><LoadingSpinner /></div> 
                    ) : productToDeleteOrders.length > 0 ? (
                        <>
                            <div className={styles.warningMessage}>
                                <span className="material-symbols-outlined">warning</span>
                                <p><strong>WARNING: There are existing orders awaiting for this product. Your product will be set as expiring until it is fulfilled.</strong></p>
                            </div>
                            <p>When you archive this product, it will not be automatically removed from your Shopify store nor will the fulfillment service attached to it.</p>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Order ID</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productToDeleteOrders.map(order => (
                                        <tr key={order.orderId}>
                                            <td>{order.orderId}</td>
                                            <td>{order.status}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <div className={styles.buttons}>
                                <button onClick={() => setIsRemoveModalOpen(false)} className={styles.closeButton}>Close</button>
                                <button 
                                    className={styles.expiringButton} 
                                    onClick={expireProduct} 
                                    disabled={loadingExpire}
                                >
                                    {loadingExpire ? <LoadingButtonDots /> : 'Set as Expiring'}
                                </button>
                            </div>
                        </>
                    ) : (
                        <>
                            <p><strong>Are you sure you want to archive this product? This action cannot be undone.</strong></p>
                            <p>When you archive this product, it will <strong>not</strong> be automatically removed from your Shopify store nor will the fulfillment service attached to it.</p>
                            <div className={styles.buttons}>
                                <button onClick={() => setIsRemoveModalOpen(false)} className={styles.closeButton}>Close</button>
                                <button 
                                    className={styles.deleteButtonModal} 
                                    onClick={archiveProduct} 
                                    disabled={loadingRemove}
                                >
                                    {loadingRemove ? <LoadingButtonDots /> : 'Yes, Archive Product'}
                                </button>
                            </div>
                        </>
                    )}
                </Modal>
            }
        </div>
    );

}

export default ProductManagement;
