import React, { useState } from 'react';
import adminService from '../../services/adminService';
import styles from './CreateSupplier.module.scss';

function CreateSupplierForm() {
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        email: '',
        role: 'Supplier',
        supplierName: '',
        contactNumber: '',
        alias: '',
        provisionProcentage: 0,
        colorHex: '',
    });

    const [error, setError] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
        setError(''); // Clear error when user makes changes
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const passwordStrength = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;

        if (!passwordStrength.test(formData.password)) {
            setError('Password must be at least 7 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character.');
            return;
        }

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match!');
            return;
        }

        try {
            await adminService.createSupplier(formData);
            alert('Supplier created successfully!');
            // Clear form after successful submission
            setFormData({
                password: '',
                confirmPassword: '',
                email: '',
                role: 'Supplier',
                supplierName: '',
                contactNumber: '',
                alias: '',
                provisionProcentage: 0,
                colorHex: '',
            });
            setError('');
        } catch (error) {
            setError('Failed to create supplier!');
        }
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Create Supplier</h1>
            </div>
            <form onSubmit={handleSubmit}>
                <div className={styles.formContainer}>
                    <div>
                        <h4>Supplier information</h4>
                        <br />
                        <label>
                            Supplier Name:
                            <input type="text" name="supplierName" value={formData.supplierName} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Contact Number:
                            <input type="text" name="contactNumber" value={formData.contactNumber} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Alias:
                            <input type="text" name="alias" value={formData.alias} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Color Hex:
                            <input type="text" name="colorHex" value={formData.colorHex} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Provision %:
                            <input type="number" min="0" max="100" name="provisionProcentage" value={formData.provisionProcentage} onChange={handleChange} required />
                        </label>
                    </div>
                    <div>
                        <h4>User information</h4>
                        <br />
                        <label>
                            Email:
                            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Password:
                            <input type="password" name="password" value={formData.password} onChange={handleChange} required />
                        </label>
                        <br />
                        <label>
                            Repeat Password:
                            <input type="password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} required />
                        </label>
                    </div>
                    {error && <p className={styles.error}>{error}</p>}
                </div>
                <button type="submit" className={styles.submitButton}>Create Supplier</button>
            </form>
        </div>
    );
}

export default CreateSupplierForm;
