import React, { useState } from "react";
import Modal from "react-modal";
import styles from "./PasswordChangeModal.module.scss";
import LoadingButtonDots from "./LoadingButtonDots";

Modal.setAppElement("#root");

const PasswordChangeModal = ({
  isOpen,
  onRequestClose,
  onChangePassword,
  loading,
  error,
}) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [localError, setLocalError] = useState("");

  const handlePasswordChange = () => {
    if (password !== confirmPassword) {
      setLocalError("Passwords do not match");
      return;
    }
    const passwordStrength =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;
    if (!passwordStrength.test(password)) {
      setLocalError(
        "Password must be at least 7 characters long, contain at least one uppercase letter, one lowercase letter, one number, and one special character"
      );
      return;
    }
    onChangePassword(password);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={styles.modal}
      overlayClassName={styles.overlay}>
      <h2>Change Password</h2>
      <div className={styles.errorContainer}>{localError}</div>
      <div className={styles.inputs}>
        <input
          type="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className={styles.input}
        />
        <input
          type="password"
          placeholder="Confirm New Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className={styles.input}
        />
        <p className={styles.info}>
          You will be logged out after changing your password.
        </p>
        <div className={styles.buttons}>
          <button
            onClick={handlePasswordChange}
            disabled={loading}
            className={styles.changeButton}>
            {loading ? <LoadingButtonDots /> : "Change Password"}
          </button>
          <button
            onClick={onRequestClose}
            disabled={loading}
            className={styles.cancelButton}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default PasswordChangeModal;
