import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import adminService from '../../services/adminService';
import styles from './Partners.module.scss';
import HoverableOverflowText from '../../components/HoverableOverflowText';

const Partners = () => {
    const [data, setData] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [error, setError] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentPartner, setCurrentPartner] = useState({ id: '', name: '', img: '', link: '', text: '' });
    const [isEditing, setIsEditing] = useState(false);
    const [isImageModalOpen, setIsImageModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    useEffect(() => {
        fetchPartners();
    }, []);
    
    const viewImage = (imageSrc) => {
        setSelectedImage(imageSrc);
        setIsImageModalOpen(true);
    };

    // Fetch partners
    const fetchPartners = async () => {
        try {
            const response = await adminService.fetchPartners();
            setData(response || []);
        } catch (error) {
            console.error('Failed to fetch partners:', error);
            setError('Failed to fetch partners');
            setData([]);
        }
    };

    // Handle opening and closing the modal
    const openModal = (partner = null) => {
        if (partner) {
            setCurrentPartner(partner);
            setIsEditing(true);
        } else {
            setCurrentPartner({ id: '', name: '', img: '', link: '', text: '' });
            setIsEditing(false);
        }
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setCurrentPartner({ id: '', name: '', img: '', link: '', text: '' });
    };

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCurrentPartner((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        setCurrentPartner((prev) => ({
            ...prev,
            imgFile: e.target.files[0], // Spara själva filen
        }));
    };
    

    // Create or edit partner
    const handleSavePartner = async () => {
        try {
            const formData = new FormData();
            formData.append('name', currentPartner.name);
            formData.append('link', currentPartner.link);
            formData.append('text', currentPartner.text);
    
            // Lägg bara till bilden om användaren har laddat upp en ny fil
            if (currentPartner.imgFile) {
                formData.append('image', currentPartner.imgFile); 
            }
    
            if (isEditing) {
                await adminService.editPartner(currentPartner.id, formData);
                setData(data.map(partner => partner.id === currentPartner.id ? { ...currentPartner, img: currentPartner.imgFile ? URL.createObjectURL(currentPartner.imgFile) : currentPartner.img } : partner));
            } else {
                const newPartner = await adminService.createPartner(formData);
                setData([...data, newPartner]);
            }
    
            closeModal();
        } catch (error) {
            console.error('Failed to save partner:', error);
            setError('Failed to save partner');
        }
    };    

    // Delete a partner
    const deletePartner = async (id) => {
        try {
            await adminService.deletePartner(id);
            setData(data.filter(partner => partner.id !== id));
        } catch (error) {
            console.error('Failed to delete partner:', error);
            setError('Failed to delete partner');
        }
    };

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...data].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setData(sortedData);
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1>Partners</h1>
                <button onClick={() => openModal()} className={styles.createPartner}>Create New Partner</button>
            </div>
            {error && <p className={styles.error}>{error}</p>}
            <div className={styles.tableContainer}>
                <table className={styles.table}>
                    <thead className={styles.mainThead}>
                        <tr>
                            <th>Id</th>
                            <th>Image</th>
                            <th onClick={() => handleSort('name')}>
                                <div className={styles.thHeader}>
                                    Name
                                    <div className={styles.sortingArrows}>
                                        <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'name' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                        <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'name' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                    </div>
                                </div>
                            </th>
                            <th>Text</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map(partner => (
                            <tr key={partner.id} className={styles.rows}>
                                <td>{partner.id}</td>
                                <td><img src={partner.img} alt={partner.name} className={styles.partnerImage} onClick={() => viewImage(`/uploads/partnerImage/${partner.image}`)}/></td>
                                <td><a href={partner.link}>{partner.name}</a></td>
                                <td><HoverableOverflowText text={partner.text} /></td>
                                <td className={styles.tableBtns}>
                                    <button onClick={() => openModal(partner)} className={styles.tableBtnEdit}>Edit</button>
                                    <button onClick={() => deletePartner(partner.id)} className={styles.tableBtnDelete}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* Modal for creating and editing partners */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Partner Modal"
                            className={styles.modal}
                            overlayClassName={styles.overlay}>
                <h2>{isEditing ? 'Edit Partner' : 'Create New Partner'}</h2>
                <form>
                    <label>
                        Name
                    </label>
                    <input type="text" name="name" value={currentPartner.name} onChange={handleChange} />
                    <label>Image File</label>
                    <input type="file" name="imgFile" accept=".png, .jpg, .jpeg" onChange={handleFileChange} />
                    <label>
                        Link
                    </label>
                    <input type="text" name="link" value={currentPartner.link} onChange={handleChange} />
                    <label>
                        Text
                    </label>
                    <textarea name="text" value={currentPartner.text} onChange={handleChange} />
                    <div className={styles.modalBtns}>
                    <button type="button" onClick={handleSavePartner} className={styles.modalCreateButton}>{isEditing ? 'Save Changes' : 'Create Partner'}</button>
                    <button type="button" onClick={closeModal} className={styles.modalCloseButton}>Cancel</button>
                    </div>
                </form>
            </Modal>
            <Modal
                isOpen={isImageModalOpen}
                onRequestClose={() => setIsImageModalOpen(false)}
                className={styles.modaltwo}
                overlayClassName={styles.overlaytwo}
            >
                <img src={selectedImage} alt="Selected" className={styles.largeImage} />
            </Modal>
        </div>
    );
};

export default Partners;
