// src/components/FilterComponent.jsx
import React, { useState, useRef, useEffect } from "react";
import { DateRange } from "react-date-range";
import { format } from "date-fns";
import { FormControl, Select, MenuItem } from "@mui/material";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import styles from "./FilterComponent.module.scss";

const FilterComponent = ({
  initialFilters = {},
  onApplyFilters,
  statusOptions = [],
  merchantOptions = [],
  onMerchantChange,
  storeOptions = [],
  searchPlaceholder = "Search...",
  filtersDisabled = false,
}) => {
  const [statusFilter, setStatusFilter] = useState(
    () => initialFilters.statusFilter || "all"
  );
  const [merchantFilter, setMerchantFilter] = useState(
    () => initialFilters.merchantFilter || "all"
  );
  const [storeFilter, setStoreFilter] = useState(
    () => initialFilters.storeFilter || "all"
  );
  const [dateRange, setDateRange] = useState(
    () => initialFilters.dateRange || []
  );
  const [searchQuery, setSearchQuery] = useState(
    () => initialFilters.searchQuery || ""
  );
  const [showDate, setShowDate] = useState(false);
  const [showSearch, setShowSearch] = useState(initialFilters.searchQuery);
  const datepickerRef = useRef(null);
  const selectStyle = {
    backgroundColor: "#edeeef",
    color: "#202331",
    boxShadow: "none",
    border: "none",
    transition: "color 0.3s",
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "&.Mui-focused": {
      backgroundColor: "#000",
      color: "white",
      transition: "background-color 0.3s, color 0.3s",
      "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    },
    "&:not(.Mui-disabled):hover": {
      backgroundColor: "#000",
      color: "white",
      transition: "background-color 0.3s, color 0.3s",
    },
    "& .MuiSvgIcon-root": {
      color: "black",
      transition: "color 0.3s",
    },
    "&.Mui-focused .MuiSvgIcon-root": {
      color: "white",
    },
    "&:not(.Mui-disabled):hover .MuiSvgIcon-root": {
      color: "white",
    },
    "& .MuiMenuItem-root": {
      backgroundColor: "#edeeef",
      color: "#202331",
    },
    "& .MuiMenuItem-root.Mui-selected": {
      backgroundColor: "yellow",
      color: "#202331",
    },
    "& .MuiMenuItem-root:hover": {
      backgroundColor: "pink",
      color: "white",
    },
    "& .MuiMenuItem-root.Mui-selected:hover": {
      backgroundColor: "red",
      color: "white",
    },
  };
  const includeStatus =
    "statusFilter" in initialFilters || statusOptions.length > 0;
  const includeMerchants =
    "merchantFilter" in initialFilters || merchantOptions.length > 0;
  const includeStores =
    "storeFilter" in initialFilters || storeOptions.length > 0;
  const includeDate = "dateRange" in initialFilters;
  const includeSearch = "searchQuery" in initialFilters;

  useEffect(() => {
    setStatusFilter(initialFilters.statusFilter || "all");
    setMerchantFilter(initialFilters.merchantFilter || "all");
    setStoreFilter(initialFilters.storeFilter || "all");
    setDateRange(initialFilters.dateRange || []);
    setSearchQuery(initialFilters.searchQuery || "");
    setShowSearch(initialFilters.searchQuery !== ""); // Set if search should be shown
  }, [initialFilters]);

  // Disable store filter if merchant is 'all'
  const isStoreDisabled = merchantFilter === "all";

  const handleMerchantChange = (e) => {
    const value = e.target.value;
    setMerchantFilter(value);
    setStoreFilter("all");
    onMerchantChange(value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target) &&
        !event.target.closest(`.${styles.dateContainer}`)
      ) {
        setShowDate(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [datepickerRef]);

  const toggleDate = () => {
    setShowDate((prevShowDate) => !prevShowDate);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
  };

  const handleStoreChange = (e) => {
    setStoreFilter(e.target.value);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const clearDates = () => {
    setDateRange([]);
    setShowDate(false);
  };

  const clearFilters = () => {
    setStatusFilter("all");
    setMerchantFilter("all");
    setStoreFilter("all");
    setDateRange([]);
    setSearchQuery("");
    setShowSearch(false);
  };

  const toggleSearch = () => {
    if (showSearch) {
      setSearchQuery("");
    }
    setShowSearch(!showSearch);
  };

  const applyFilterChanges = () => {
    const filters = {
      ...(initialFilters.statusFilter !== undefined && { statusFilter }),
      ...(initialFilters.merchantFilter !== undefined && { merchantFilter }),
      ...(initialFilters.storeFilter !== undefined && { storeFilter }),
      ...(initialFilters.dateRange !== undefined && { dateRange }),
      ...(initialFilters.searchQuery !== undefined && { searchQuery }),
    };
    onApplyFilters(filters);
  };

  return (
    <div className={styles.filterContainer}>
      <div className={styles.filterOptions}>
        {includeMerchants && (
          <FormControl
            size="small"
            disabled={filtersDisabled}
            style={{ height: 30, borderRadius: 30 }}>
            <Select
              style={{ height: 30, borderRadius: 30 }}
              value={merchantFilter}
              onChange={handleMerchantChange}
              sx={selectStyle}>
              <MenuItem key="all-stores" value="all">
                All Merchants
              </MenuItem>
              {merchantOptions.map((merchant) => (
                <MenuItem key={merchant.id} value={merchant.id}>
                  {merchant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Store Filter */}
        {includeStores && (
          <FormControl
            size="small"
            disabled={filtersDisabled || isStoreDisabled} // Disable if no merchant selected
            style={{ height: 30, borderRadius: 30 }}>
            <Select
              style={{ height: 30, borderRadius: 30 }}
              value={storeFilter}
              onChange={handleStoreChange}
              sx={selectStyle}>
              <MenuItem key="all-stores" value="all">
                All Stores
              </MenuItem>
              {storeOptions.map((store) => (
                <MenuItem key={store.id} value={store.id}>
                  {store.storeURL}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {includeStatus && statusFilter.length > 0 && (
          <FormControl
            size="small"
            disabled={filtersDisabled}
            style={{ height: 30, borderRadius: 30 }}>
            <Select
              style={{ height: 30, borderRadius: 30 }}
              value={statusFilter}
              onChange={handleStatusChange}
              sx={selectStyle}>
              {statusOptions.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {/* Date Range Picker */}
        {includeDate && (
          <div className={styles.dateContainer}>
            <button
              className={`${styles.dateBtn} ${
                dateRange.length > 0 && styles.activeDateBtn
              }`}
              onClick={toggleDate}
              disabled={filtersDisabled}>
              <span className="material-symbols-outlined">date_range</span>
            </button>
            {dateRange.length > 0 && (
              <>
                <div className={styles.dates} onClick={toggleDate}>
                  <span>
                    {format(dateRange[0].startDate, "MM/dd/yy")} &rarr;{" "}
                    {format(dateRange[0].endDate, "MM/dd/yy")}
                  </span>
                </div>
                <button onClick={clearDates} className={styles.clearDateButton}>
                  <span className="material-symbols-outlined">close</span>
                </button>
              </>
            )}
            <div
              className={`${styles.datepicker} ${
                showDate ? styles.activeDate : styles.inactiveDate
              }`}
              ref={datepickerRef}>
              <DateRange
                onChange={(item) => setDateRange([item.selection])}
                editableDateInputs={true}
                moveRangeOnFirstSelection={false}
                ranges={
                  dateRange.length > 0
                    ? dateRange
                    : [
                        {
                          startDate: new Date(),
                          endDate: new Date(),
                          key: "selection",
                        },
                      ]
                }
                maxDate={new Date()}
              />
            </div>
          </div>
        )}
        {includeSearch && (
          <div className={styles.searchContainer}>
            <button
              className={`${styles.searchBtn} ${
                showSearch ? styles.activeSearchBtn : styles.inactiveSearchBtn
              }`}
              onClick={toggleSearch}
              disabled={filtersDisabled}>
              <span className="material-symbols-outlined">search</span>
            </button>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={searchPlaceholder}
              className={`${styles.searchInput} ${
                showSearch ? styles.activeSearch : styles.inactiveSearch
              }`}
              disabled={filtersDisabled}
            />
          </div>
        )}

        <button
          onClick={applyFilterChanges}
          className={styles.filterButton}
          disabled={filtersDisabled}>
          Apply
        </button>
        <button
          onClick={clearFilters}
          className={styles.clearButton}
          disabled={filtersDisabled}>
          <span className="material-symbols-outlined">close</span> Clear
        </button>
      </div>
    </div>
  );
};

export default FilterComponent;
