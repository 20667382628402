import React from 'react';
import PaginationComponent from '../../../../components/PaginationComponent';
import styles from '../../Orders.module.scss';
import Tooltip from '@mui/material/Tooltip';
import HoverableOverflowText from '../../../../components/HoverableOverflowText';

const OrdersTable = ({
  orders,
  loading,
  noActiveOrders,
  selectedRows,
  handleSelectAll,
  visibleItems,
  toggleItemDetails,
  itemsPerPage,
  setItemsPerPage,
  totalPages,
  currentPage,
  handlePageChange,
  activeOption,
  filtersApplied,
  fetchMergeData,
  handleRowSelect,
  handlePayClick,
  sortConfig,
  handleSort,
}) => {
  const statusColors = {
    'Awaiting Payment': 'rgba(255, 235, 120, 1)',
    'Awaiting Fulfillment': 'rgba(213, 235, 255, 1)',
    'Partially Fulfilled': 'rgba(255, 214, 164, 1)',
    'Fulfilled': 'rgba(0, 0, 0, .06)',
    'Cancelled': 'rgba(255, 214, 164, 1)',
  };

  const getStatusColor = (status) => {
    return statusColors[status] || '#808080';
  };

  const mergeColors = [
    '#1F77B4', // Dark Blue
    '#FF7F0E', // Dark Orange
    '#2CA02C', // Dark Green
    '#D62728', // Dark Red
    '#9467BD', // Dark Purple
    '#8C564B', // Brown
    '#E377C2', // Pink
    '#7F7F7F', // Gray
    '#BCBD22', // Olive
    '#17BECF', // Teal
    // Lägg till fler färger vid behov
  ];

  const getColorForMergeId = (mergeId) => {
    const index = Math.abs(mergeId % mergeColors.length);
    return mergeColors[index];
  };  

  const createDetailRow = (order) => {
    return (
      <td colSpan="9">
        <table>
          <thead>
            <tr>
              <th className={styles.smallestCell}></th>
              <th>Product</th>
              <th className={styles.smallerCell}>Quantity</th>
              <th>SKU</th>
              <th>Variant</th>
              <th>Total Cost</th>
              <th>Supplier</th>
              <th colSpan={2}>Fulfilled</th>
            </tr>
          </thead>
          <tbody>
            {order.items.map((item, index) => (
              <tr
                key={`item-${order.orderId}-${index}`}
                className={item.isCancelled ? styles.canceledItem : ''}
              >
                <td className={styles.smallestCell}></td>
                <td><a href={`./products/${item.productId}`}><HoverableOverflowText text={item.productName}/></a></td>
                <td className={styles.smallerCell}>{item.quantity}</td>
                <td>{item.SKU}</td>
                <td>{item.variantTitle ? item.variantTitle : '-'}</td>
                <td className={styles.hoverable}>
  <Tooltip
    title={
      <>
        <div>Product Price: {(item.productPrice).toFixed(2)}€</div>
        <div>Shipping Price: {(item.shippingPrice).toFixed(2)}€</div>
        <div>Quantity: {item.quantity}</div>
        <div>Price x Quantity: {(item.quantity * (item.productPrice + item.shippingPrice)).toFixed(2)}€</div>
      </>
    }
  >
    {(item.quantity * (item.productPrice + item.shippingPrice)).toFixed(2)}€
  </Tooltip>
</td>
                <td>{item.supplierName}</td>
                <td colSpan={2}>
                  {item.isFulfilled ? (
                    <div className={styles.checkedCircle}>
                      <span className="material-symbols-outlined">check_circle</span>
                    </div>
                  ) : (
                    <div className={styles.unCheckedCircle}>
                      <span className="material-symbols-outlined">cancel</span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </td>
    );
  };

  return (
    <table className={styles.table}>
      <thead className={styles.mainThead}>
        <tr>
          <th className={styles.smallestCell}>
            <input
              type="checkbox"
              checked={selectedRows.length === orders.length && orders.length > 0}
              onChange={handleSelectAll}
            />
          </th>
          <th onClick={() => handleSort('orderId')}>
            <div className={styles.thHeader}>
              Order ID
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === 'orderId' && sortConfig.direction === 'ascending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === 'orderId' && sortConfig.direction === 'descending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
              </div>
            </div>
          </th>
          <th className={styles.smallerCell} onClick={() => handleSort('itemsCount')}>
            <div className={styles.thHeader}>
              Items
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === 'itemsCount' && sortConfig.direction === 'ascending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === 'itemsCount' && sortConfig.direction === 'descending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('shopifyId')}>
            <div className={styles.thHeader}>
              Shopify ID
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === 'shopifyId' && sortConfig.direction === 'ascending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === 'shopifyId' && sortConfig.direction === 'descending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('status')}>
            <div className={styles.thHeader}>
              Status
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === 'status' && sortConfig.direction === 'ascending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === 'status' && sortConfig.direction === 'descending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
              </div>
            </div>
          </th>
          <th onClick={() => handleSort('totalCost')}>
            <div className={styles.thHeader}>
              Total Cost
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === 'totalCost' && sortConfig.direction === 'ascending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === 'totalCost' && sortConfig.direction === 'descending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
              </div>
            </div>
          </th>
          <th>Store</th>
          <th onClick={() => handleSort('createdAt')}>
            <div className={styles.thHeader}>
              Date
              <div className={styles.sortingArrows}>
                <span
                  className={`${styles.sort_arrow} ${styles.asc} ${
                    sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
                <span
                  className={`${styles.sort_arrow} ${styles.desc} ${
                    sortConfig.key === 'createdAt' && sortConfig.direction === 'descending'
                      ? styles.active
                      : ''
                  }`}
                ></span>
              </div>
            </div>
          </th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          Array.from({ length: 5 }).map((_, index) => (
            <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
              <td colSpan={9}></td>
            </tr>
          ))
        ) : !noActiveOrders ? (
          orders.map((order) => (
            <React.Fragment key={order.orderId}>
              <tr
                className={`${visibleItems[order.orderId] ? styles.droppeddown : styles.rows} ${
                  selectedRows.includes(order.orderId) ? styles.selectedRow : ''
                }`}
              >
                <td className={styles.smallestCell}>
                  <input
                    type="checkbox"
                    checked={selectedRows.includes(order.orderId)}
                    onChange={() => handleRowSelect(order.orderId)}
                  />
                </td>
                <td className={styles.orderIdMerge}>
                  {order.orderId}
                  {order.hasMerges &&
                    order.merges.map((mergeId) => (
                      <button
                        key={mergeId}
                        className={styles.isMergedButton}
                        onClick={() => fetchMergeData(mergeId)}
                        style={{ backgroundColor: getColorForMergeId(mergeId) }}
                      >
                        <span className="material-symbols-outlined">call_merge</span>
                      </button>
                    ))}
                </td>
                <td className={styles.smallerCell}>
                  <button
                    className={styles.toggleRowsButton}
                    onClick={() => toggleItemDetails(order.orderId)}
                  >
                    {order.itemsCount}
                    <span className="material-symbols-outlined">
                      {visibleItems[order.orderId] ? 'arrow_drop_up' : 'arrow_drop_down'}
                    </span>
                  </button>
                </td>
                <td>
                  <a
                    href={`https://admin.shopify.com/store/${order.store.shop.replace('.myshopify.com', '')}/orders/${order.shopifyOrderId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {order.shopifyOrderId}
                  </a>
                </td>
                <td>
                  <span
                    style={{
                      backgroundColor: getStatusColor(order.status),
                    }}
                    className={styles.orderStatus}
                  >
                    {order.status}
                  </span>
                </td>
                <td>
                {order.shippingDiscount > 0 ? (
  <Tooltip
    arrow
    title={`This order had a shipping discount of ${order.shippingDiscount}€ due to multiple products. ${
      order.hasMerges 
        ? 'Since this is a merged order, the discount is distributed across multiple orders in the group, so it cannot be directly subtracted from this individual order.'
        : ''
    }`}
  >
    {`${order.totalCost.toFixed(2)}€ (-${order.shippingDiscount.toFixed(2)}€)`}
  </Tooltip>
) : (
  `${order.totalCost.toFixed(2)}€`
)}

                </td>
                <td>
                  <div style={{ backgroundColor: order.store.color }} className={styles.shopLogo}>
                    {order.store.shortName}
                  </div>
                </td>
                <td>{new Date(order.createdAt).toLocaleDateString()}</td>
                <td>
                  {order.status === 'Awaiting Payment' && (
                    <button className={styles.payBtn} onClick={() => handlePayClick(order.orderId)}>
                      Pay
                    </button>
                  )}
                </td>
              </tr>
              {visibleItems[order.orderId] === 'loading' ? (
                <tr className={styles.detailRow} key={`details-${order.orderId}`}>
                  <td colSpan={9}>
                    <table>
                      <tbody>
                        {Array.from({ length: order.itemsCount + 1 }).map((_, index) => (
                          <tr key={index} className={`${styles.rows} ${styles.placeHolderDetailRow}`}>
                            <td colSpan={9}></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              ) : visibleItems[order.orderId] && order.items && (
                <tr className={styles.detailRow} key={`details-${order.orderId}`}>
                  {createDetailRow(order)}
                </tr>
              )}
            </React.Fragment>
          ))
        ) : activeOption === 'Archived' ? (
          <tr className={styles.rows}>
            <td colSpan={9}>
              <p className={styles.noFound}>
                No archived orders found{filtersApplied && ', check filtering'}
              </p>
            </td>
          </tr>
        ) : (
          <tr className={styles.rows}>
            <td colSpan={9}>
              <p className={styles.noFound}>
                No active orders found{filtersApplied && ', check filtering'}
              </p>
            </td>
          </tr>
        )}
        <tr className={styles.rows}>
          <td colSpan={9}>
            <PaginationComponent
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              totalPages={totalPages}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
            />
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrdersTable;
