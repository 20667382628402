import React, { useState, useEffect } from 'react';
import merchantService from '../../services/merchantService';
import styles from './Partners.module.scss';

const Partners = () => {
    useEffect(() => {
        document.title = 'Partners | Qiwi';
      }, []);   
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchPartners();
    }, []);

    // Fetch partners
    const fetchPartners = async () => {
        try {
            const response = await merchantService.fetchPartners();
            setData(response || []);
        } catch (error) {
            console.error('Failed to fetch partners:', error);
            setData([]);
        }
    };

    return (
        <div className={styles.container}>

            <div className={styles.cardsContainer}>
                {data.length > 0 ? (
                    data.map((partner) => (
                        <div key={partner.id} className={styles.partnerCard}>
                            <div className={styles.partnerImageContainer}>
                            <img src={`/uploads/partnerImages/${partner.image}`} alt={partner.name} className={styles.partnerImage} />
                            </div>
                            <div className={styles.partnerInfo}>
                                <div>
                                <h2 className={styles.partnerName}>{partner.name}</h2>
                                <p className={styles.partnerText}>
                                {partner.text.split('\r\n').map((line, index) => (
                                    <span key={index}>
                                    {line}
                                    <br />
                                    </span>
                                ))}
                                </p>
                                </div>
                                <a href={partner.link} target="_blank" rel="noopener noreferrer" className={styles.partnerLink}>
                                    Visit Partner
                                </a>
                            </div>
                        </div>
                    ))
                ) : (
                    <p>No partners available.</p>
                )}
            </div>
        </div>
    );
};

export default Partners;
