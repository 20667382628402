import axios from "axios";
import { getGlobalErrorNotification } from "../components/ErrorContext"; // Importera global access till errorNotification

const API_URL = process.env.REACT_APP_API_BASE_URL;

const handleError = (error) => {
  const errorNotification = getGlobalErrorNotification(); // Hämta errorNotification globalt
  if (errorNotification) {
    errorNotification(); // Visa "Server error" vid fel
  }
  console.error("API error:", error);
  throw error; // Kasta om felet så att komponenten kan hantera det om det behövs
};

const fetchSourcingDataByPageAndStatus = async (
  status,
  pageNumber,
  pageAmount,
  searchQuery,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await axios.get(
      API_URL + "sourcing-merchant/sourcingDataByPageAndStatus",
      {
        params: {
          status,
          pageNumber,
          pageAmount,
          searchQuery,
          startDate,
          endDate,
          showArchived,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchOrdersByPageAndFilters = async (
  status,
  pageNumber,
  pageAmount,
  searchQuery,
  startDate,
  endDate,
  showArchived
) => {
  try {
    const response = await axios.get(
      API_URL + "orders/fetchAllOrdersByPageAndFilters",
      {
        params: {
          status,
          pageNumber,
          pageAmount,
          searchQuery,
          startDate,
          endDate,
          showArchived,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchOrderDeclines = async (
  currentPage,
  itemsPerPage,
  searchQuery,
  startDate,
  endDate
) => {
  try {
    const response = await axios.get(API_URL + "orders/fetchOrderDeclines", {
      params: {
        currentPage,
        itemsPerPage,
        searchQuery,
        startDate,
        endDate,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createSourcingRequest = async (requestData) => {
  try {
    const response = await axios.post(
      API_URL + "sourcing-merchant/create",
      requestData,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createSourcingRequestWithImage = async (formData) => {
  try {
    const response = await axios.post(
      API_URL + "sourcing-merchant/create",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const addToProducts = async (quotationId, requestId) => {
  try {
    const response = await axios.post(
      `${API_URL}sourcing-merchant/addProduct`,
      {
        requestId: requestId,
        quotationId: quotationId,
      },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

const getStores = async () => {
  try {
    const response = await axios.get(API_URL + `store/getStores`, {
      withCredentials: true,
    });

    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const publishNoVariantProduct = async (productId, storeId) => {
  try {
    const response = await axios.post(
      `${API_URL}product/publishWithNoVariants`,
      {
        productId: productId,
        storeId: storeId,
      },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

const publishProductWithVariants = async (
  productId,
  storeId,
  variantIds,
  shopifyOrderId
) => {
  try {
    const response = await axios.post(
      `${API_URL}product/publishWithVariants`,
      {
        productId: productId,
        storeId: storeId,
        variantIds: variantIds,
        shopifyOrderI: shopifyOrderId,
      },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const connectProductToExisting = async (
  productId,
  storeId,
  shopifyProductId
) => {
  try {
    const response = await axios.post(
      `${API_URL}product/connectToExisting`,
      {
        productId,
        storeId,
        shopifyProductId,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Function to connect a product with variants to an existing one in Shopify
export const connectProductWithVariantsToExisting = async (
  productId,
  storeId,
  shopifyProductId,
  variantShopifyIds
) => {
  try {
    const response = await axios.post(
      `${API_URL}product/connectWithVariantsToExisting`,
      {
        productId,
        storeId,
        shopifyProductId,
        variantShopifyIds,
      },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteStore = async (storeId) => {
  try {
    const response = await axios.delete(`${API_URL}store/delete/${storeId}`, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    handleError(error);
  }
};

const cancelOrder = async (orderId) => {
  try {
    const response = await axios.put(
      `${API_URL}orders/cancelOrder/${orderId}`,
      {},
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

const createOrderManually = async (orderData) => {
  try {
    const response = await axios.post(
      `${API_URL}orders/createOrderManually`,
      {
        orderData: orderData,
      },
      {
        withCredentials: true,
      }
    );
    return response;
  } catch (error) {
    handleError(error);
  }
};

const fetchPaymentsByPageAndFilters = async (
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  showArchived,
  searchQuery
) => {
  try {
    const response = await axios.get(
      API_URL + "payment/fetchPaymentsByPageAndFilters",
      {
        params: {
          currentPage,
          itemsPerPage,
          startDate,
          endDate,
          showArchived,
          searchQuery,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchRefundsByPageAndFilters = async (
  currentPage,
  itemsPerPage,
  startDate,
  endDate,
  searchQuery
) => {
  try {
    const response = await axios.get(
      API_URL + "payment/fetchRefundsByPageAndFilters",
      {
        params: {
          currentPage,
          itemsPerPage,
          startDate,
          endDate,
          searchQuery,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchPaymentsDetails = async (paymentId, showArchived) => {
  try {
    const response = await axios.get(
      `${API_URL}payment/fetchPaymentsDetails/${paymentId}`,
      {
        params: {
          showArchived: showArchived,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchUserProductsWithFilterAndPagination = async (
  currentPage,
  itemsPerPage,
  selectedStore,
  showArchived
) => {
  try {
    const response = await axios.get(
      `${API_URL}product/fetchUserProductsWithFilterAndPagination`,
      {
        params: {
          currentPage,
          itemsPerPage,
          selectedStore,
          showArchived,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchAllShopifyProductsWithFilterAndPagination = async (
  selectedStore,
  itemsPerPage,
  pageInfo,
  direction
) => {
  try {
    const response = await axios.get(
      `${API_URL}product/fetchAllShopifyProductsWithFilterAndPagination`,
      {
        params: {
          selectedStore,
          itemsPerPage,
          pageInfo,
          direction,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const getUserInfo = async () => {
  try {
    const response = await axios.get(`${API_URL}user/fetchUserInfo`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchQuotationsAndRequest = async (requestId) => {
  try {
    const response = await axios.get(
      API_URL + `sourcing-merchant/quotations-and-request/${requestId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const confirmQuotation = async (quotationId, requestId) => {
  try {
    const response = await axios.post(
      `${API_URL}sourcing-merchant/confirm-quotation`,
      { quotationId, requestId },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMerchantOrderItems = async (orderId, showArchived) => {
  try {
    const response = await axios.get(
      `${API_URL}orders/fetchMerchantOrderItems/`,
      {
        params: {
          showArchived: showArchived,
          orderId: orderId,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchOrdersInfo = async () => {
  try {
    const response = await axios.get(`${API_URL}orders/fetchOrdersInfo/`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchSourcingInfo = async () => {
  try {
    const response = await axios.get(
      `${API_URL}sourcing-merchant/fetchSourcingInfo`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const merchantDeleteStatus = async () => {
  try {
    const response = await axios.get(`${API_URL}user/merchantDeleteStatus`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireMerchant = async () => {
  try {
    const response = await axios.get(`${API_URL}user/expireMerchant`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const deleteMerchant = async () => {
  try {
    const response = await axios.delete(`${API_URL}user/deleteMerchant`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const productDeleteStatus = async (productId) => {
  try {
    const response = await axios.get(
      `${API_URL}product/productDeleteStatus/${productId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const archiveProduct = async (productId) => {
  try {
    const response = await axios.delete(
      `${API_URL}product/archiveProduct/${productId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireProduct = async (productId) => {
  try {
    const response = await axios.get(
      `${API_URL}product/expireProduct/${productId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const storeDeleteStatus = async (storeId) => {
  try {
    const response = await axios.get(
      `${API_URL}store/storeDeleteStatus/${storeId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const storeDelete = async (storeId) => {
  try {
    const response = await axios.delete(`${API_URL}store/delete/${storeId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const expireStore = async (storeId) => {
  try {
    const response = await axios.get(`${API_URL}store/expireStore/${storeId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMergeOrders = async () => {
  try {
    const response = await axios.get(`${API_URL}orders/fetchMergeOrders`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMergeRequests = async () => {
  try {
    const response = await axios.get(`${API_URL}orders/fetchMergeRequests`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchMergeByMergeId = async (mergeId) => {
  try {
    const response = await axios.get(
      `${API_URL}orders/fetchMergeByMergeId/${mergeId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchPaymentInfo = async () => {
  try {
    const response = await axios.get(`${API_URL}payment/payment-info`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createMergeOrder = async (fulfillmentOrderIds) => {
  try {
    const response = await axios.post(
      `${API_URL}orders/createMergeOrder`,
      { fulfillmentOrderIds },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createMergeRequest = async (fulfillmentOrderIds) => {
  try {
    const response = await axios.post(
      `${API_URL}orders/createMergeRequest`,
      { fulfillmentOrderIds },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const createCheckoutSession = async (amount) => {
  try {
    const response = await axios.post(
      `${API_URL}stripe/create-checkout-session`,
      { amount },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const checkPaymentStatus = async (sessionId) => {
  try {
    const response = await axios.get(
      `${API_URL}stripe/session-status/${sessionId}`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const payOrders = async (orderIds) => {
  try {
    const response = await axios.post(
      `${API_URL}payment/pay`,
      { orderIds },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateMerchantInfo = async (updatedData) => {
  try {
    const response = await axios.put(
      `${API_URL}user/update-info`,
      { updatedData },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const updateStoreURLandShort = async (storeId, newStoreURL, newShortName) => {
  try {
    const response = await axios.post(
      `${API_URL}store/updateStoreURLandShort`,
      { storeId, newStoreURL, newShortName },
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchPartners = async () => {
  try {
    const response = await axios.get(API_URL + "partner", {
      withCredentials: true,
    });
    return response.data; // Returnerar listan över partners
  } catch (error) {
    handleError(error);
  }
};

const fetchDashboardInfo = async () => {
  try {
    const response = await axios.get(API_URL + "orders/dashboard-info", {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const addCountryToStore = async (storeId, country) => {
  try {
    const response = await axios.post(
      `${API_URL}store/${storeId}/countries`,
      {
        country,
      },
      {
        withCredentials: true, // Skicka cookies (om nödvändigt)
      }
    );
    return response.data; // Det nya landets data (inklusive id och label)
  } catch (error) {
    handleError(error);
  }
};

// Ta bort ett land från en butik
const removeCountryFromStore = async (countryId) => {
  try {
    await axios.delete(`${API_URL}store/${countryId}`, {
      withCredentials: true, // Skicka cookies (om nödvändigt)
    });
    return true; // Bekräftelse på borttagning
  } catch (error) {
    handleError(error);
  }
};

const fetchProduct = async (productId) => {
  try {
    const response = await axios.get(
      `${API_URL}product/${productId}/getProductInfo`,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
const signupAffiliate = async () => {
  try {
    const response = await axios.post(
      `${API_URL}affiliate/signup`,
      {},
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

const fetchAffiliateInformation = async (programId) => {
  try {
    const response = await axios.get(`${API_URL}affiliate/info`, {
      params: {
        programId,
      },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const fetchAffiliatePrograms = async () => {
  try {
    const response = await axios.get(`${API_URL}affiliate/programs`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch affiliate programs:", error);
    throw error;
  }
};

export default {
  fetchAffiliatePrograms,
  fetchAffiliateInformation,
  signupAffiliate,
  fetchOrderDeclines,
  connectProductToExisting,
  connectProductWithVariantsToExisting,
  fetchProduct,
  addCountryToStore,
  removeCountryFromStore,
  updateMerchantInfo,
  fetchDashboardInfo,
  fetchPartners,
  fetchRefundsByPageAndFilters,
  payOrders,
  checkPaymentStatus,
  createCheckoutSession,
  fetchPaymentInfo,
  fetchMergeByMergeId,
  fetchMergeRequests,
  createMergeRequest,
  createMergeOrder,
  fetchMergeOrders,
  storeDeleteStatus,
  storeDelete,
  expireStore,
  productDeleteStatus,
  expireProduct,
  expireMerchant,
  merchantDeleteStatus,
  fetchSourcingInfo,
  fetchOrdersInfo,
  fetchMerchantOrderItems,
  confirmQuotation,
  fetchQuotationsAndRequest,
  deleteMerchant,
  getUserInfo,
  createSourcingRequest,
  createSourcingRequestWithImage,
  addToProducts,
  getStores,
  publishNoVariantProduct,
  publishProductWithVariants,
  deleteStore,
  archiveProduct,
  fetchOrdersByPageAndFilters,
  cancelOrder,
  createOrderManually,
  fetchPaymentsByPageAndFilters,
  fetchPaymentsDetails,
  fetchSourcingDataByPageAndStatus,
  fetchAllShopifyProductsWithFilterAndPagination,
  fetchUserProductsWithFilterAndPagination,
  updateStoreURLandShort,
};
