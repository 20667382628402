import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supplierService from '../../services/supplierService';
import styles from './Sourcing.module.scss';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import Modal from 'react-modal';
import HoverableOverflowText from '../../components/HoverableOverflowText';
import React_Select from 'react-select'; // Använd react-select

const Sourcing = () => {
    useEffect(() => {
        document.title = 'Sourcing | Qiwi';
    }, []);

    const [sourcingData, setSourcingData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [responseDetail, setResponseDetail] = useState(null);
    const [loading, setLoading] = useState(true);
    const [selectedImage, setSelectedImage] = useState(null); // Ny state för vald bild
    const [comparisonData, setComparisonData] = useState([]);
    const [comparisonModal, setComparisonModal] = useState(false);
    const [expiring, setExpiring] = useState(false);
    const [loadingInitialInfo, setLoadingInitialInfo] = useState(true);
    const [sourcingInfo, setSourcingInfo] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [comparisonSelectedCountry, setComparisonSelectedCountry] = useState(null);
    const [comparisonCountryOptions, setComparisonCountryOptions] = useState([]);
      const [appliedFiltersActive, setAppliedFiltersActive] = useState({
        statusFilter: "all",
        dateRange: [],
        searchQuery: "",
      });
      const [appliedFiltersArchived, setAppliedFiltersArchived] = useState({
        dateRange: [],
        searchQuery: "",
      });
      const [options] = useState(['Active', 'Archived']); // Lägg till 'Declined'
      const [activeOption, setActiveOption] = useState(options[0]);

      
    const customStyles = {
        control: (provided) => ({
          ...provided,
          width: 150, // Sätt din önskade fasta bredd här
        }),
      };
    
    const statusOptions = [
        { value: 'all', label: 'All' },
        { value: 'Awaiting Response', label: 'Awaiting Response' },
        { value: 'Pending', label: 'Pending'},
        { value: 'Awaiting Merchant Confirmation', label: 'Awaiting Merchant Confirmation' },
        { value: 'Confirmed', label: 'Confirmed' },
        { value: 'Published In Merchant Store', label: 'Published In Merchant Store' },
        { value: 'Rejected', label: 'Rejected' },
    ];
    
    useEffect(() => {
        const storedExpiring = sessionStorage.getItem('expiring');
        if (storedExpiring !== null) {
            setExpiring(storedExpiring === 'true'); // Konvertera strängen till en boolean
        }
    }, []);

    const fetchSourcingInfo = async () => {
        try {
            setLoadingInitialInfo(true)
            const response = await supplierService.fetchSourcingInfo();
            setSourcingInfo(response);
            setLoadingInitialInfo(false)
        } catch (error) {
            console.error('Failed to fetch orders info:', error);
            setSourcingInfo([]);
        }
    };

    useEffect(() => {
        fetchSourcingInfo();
    }, []);

    const getTimeDifferenceClass = (createdAt, status) => {
        if (status !== 'Pending') return ''; // Endast för 'Pending'

        const now = new Date();
        const createdDate = new Date(createdAt);
        const timeDifferenceInHours = (now - createdDate) / (1000 * 60 * 60); // Milliseconds to hours

        if (timeDifferenceInHours > 48) {
            return styles.red; // Röd
        } else if (timeDifferenceInHours > 24) {
            return styles.orange; // Orange
        } else {
            return styles.green; // Grön
        }
    };

    const getStatusInfo = (status) => {
        switch (status) {
            case 'Pending':
                return { icon: 'pending', color: '#b38c0c', text: 'Pending' };
            case 'Awaiting Merchant Confirmation':
                return { icon: 'preliminary', color: '#f58711', text: 'Awaiting Merchant Confirmation' };
            case 'Confirmed':
            case 'Published In Merchant Store':
                return { icon: 'check_circle', color: '#477b5a', text: status };
            case 'Archived':
                return { icon: 'folder', color: 'gray', text: 'Archived' };
            case 'Rejected':
                return { icon: 'cancel', color: '#c7444c', text: 'Rejected' };
            default:
                return { icon: 'deployed_code_history', color: '#1f3487', text: 'Awaiting Response' }; // För 'Awaiting Response'
        }
    };

    const navigate = useNavigate();

    const fetchData = async () => {
        setLoading(true);
        try {
            const { statusFilter = "all", dateRange, searchQuery } = activeOption === "Active" ? appliedFiltersActive : appliedFiltersArchived;
            const showArchived = activeOption === 'Archived';
            const { requests, totalPages } = await supplierService.fetchSourcingDataByPageAndStatus({
                status: statusFilter,
                pageNumber: currentPage,
                pageAmount: itemsPerPage,
                searchQuery,
                startDate: dateRange.length ? dateRange[0].startDate : null,
                endDate: dateRange.length ? dateRange[0].endDate : null,
                showArchived,
            });
            setSourcingData(requests);
            setTotalPages(totalPages);
        } catch (error) {
            console.error("Error fetching data: ", error);
        } finally {
            setLoading(false);
        }
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setResponseDetail(null);
        setSelectedImage(null); // Stäng bildmodalen också
    };

    useEffect(() => {
        fetchData();
    }, [currentPage, itemsPerPage, appliedFiltersActive, appliedFiltersArchived]);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleActionClick = async (action, requestId) => {
        if (action === 'Deny') {
            try {
                if (window.confirm("Are you sure you want to deny this request?")) {
                await supplierService.denyRequest(requestId);
                fetchData();
                fetchSourcingInfo();
            }
            } catch (error) {
                console.error("Error denying request: ", error);
            }
        } else if (action === 'Respond') {
            navigate(`/supplier/sourcing/create-quotation/${requestId}`);
        }
    };

    const openModal = async (requestId) => {
        try {
            const data = await supplierService.getResponseByIdandSupplier(requestId);
            setResponseDetail(data);

            // Hämta det första landet från antingen noVariant eller variantens shippingPrices
            let initialCountry = null;

            if (data.noVariant && data.noVariant.shippingPrices.length > 0) {
                // Om produkten inte har varianter, välj land från noVariant shippingPrices
                initialCountry = data.noVariant.shippingPrices[0].country;
            } else if (data.variants && data.variants.length > 0) {
                // Om det finns varianter, välj land från den första varianten
                const firstVariant = data.variants[0];
                if (firstVariant.shippingPrices.length > 0) {
                    initialCountry = firstVariant.shippingPrices[0].country;
                }
            }

            // Sätt initialt valt land i huvudmodalen om det finns
            if (initialCountry) {
                setSelectedCountry({ value: initialCountry, label: initialCountry });
            } else {
                setSelectedCountry(null);
            }

            setModalIsOpen(true);
        } catch (error) {
            console.error("Error fetching response detail: ", error);
        }
    };

    const openComparison = async (requestId) => {
        try {
            const data = await supplierService.getComparisonData(requestId);
            setComparisonData(data);

            // Extrahera unika länder från alla quotations
            const countries = new Set();
            data.quotation.forEach(quotation => {
                if (quotation.noVariant && quotation.noVariant.shippingPrices) {
                    quotation.noVariant.shippingPrices.forEach(sp => {
                        if (sp.country) {
                            countries.add(sp.country);
                        }
                    });
                }
                if (quotation.variants) {
                    quotation.variants.forEach(variant => {
                        variant.shippingPrices.forEach(sp => {
                            if (sp.country) {
                                countries.add(sp.country);
                            }
                        });
                    });
                }
            });

            const countryOptions = Array.from(countries).map(country => ({ value: country, label: country }));
            setComparisonCountryOptions(countryOptions);

            // Sätt det första landet som standardval om det finns några
            setComparisonSelectedCountry(countryOptions.length > 0 ? countryOptions[0] : null);

            setComparisonModal(true);
        } catch (error) {
            console.error("Error fetching comparison data: ", error);
        }
    }

    const closeComparison = () => {
        setComparisonModal(false);
        setComparisonData([]);
        setComparisonSelectedCountry(null);
    }

    // Funktion för att öppna modal med större bild
    const openImageModal = (imageSrc) => {
        setSelectedImage(imageSrc);
        setModalIsOpen(true);
    };

    const handleOptionClick = (option) => {
        setActiveOption(option);
          setAppliedFiltersActive({
            statusFilter: "all",
            dateRange: [],
            searchQuery: "",
          });
          setAppliedFiltersArchived({        
          dateRange: [],
          searchQuery: "",
        });
        setCurrentPage(1);
      };
    

    const handleApplyFilters = (filters) => {
        if (activeOption === 'Active'){
            setAppliedFiltersActive(filters);
            } else {
              setAppliedFiltersArchived(filters);
            }
            setCurrentPage(1);
          };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h2>Sourcing Requests</h2>
            </div>
            <div className={styles.infoContainers}>
            <div className={`${styles.infoboxBlue} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
            <span class="material-symbols-outlined">
        deployed_code_history
        </span>
      </div>
      <div className={styles.info}>
        <h2>{sourcingInfo.unrespondedCount}</h2>
        <p>UNANSWERED</p>
      </div>
    </>
  )}
</div>
<div className={`${styles.infoboxOrange} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
      <span class="material-symbols-outlined">
pending
</span>
      </div>
      <div className={styles.info}>
        <h2>{sourcingInfo.pendingCount}</h2>
        <p>PENDING</p>
      </div>
    </>
  )}
</div>
<div className={`${styles.infoBoxColsFlex} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
    <div className={styles.firstInfo}>
    <div>
    <span class="material-symbols-outlined">
star
</span>
      </div>
      <div className={styles.info}>
        <h2>{sourcingInfo.inStoreCount + sourcingInfo.confirmedCount}</h2>
        <p>ACCEPTED</p>
      </div>
    </div>
    <div className={styles.infoBoxCols}>
    <div className={styles.bluespan}>
      </div>
      <div  className={styles.greenspan}>
      <div >
        <span className="material-symbols-outlined">
          reply
        </span>
      </div>
      <div className={styles.info}>
        <p>CONFIRMED</p>
        <h3>{sourcingInfo.confirmedCount}</h3>

      </div>
      </div>
      <div className={styles.darkGreenspan}>
      <div >
      <span class="material-symbols-outlined">
            check
            </span>
      </div>
      <div className={styles.info}>
        <p>IN STORE</p>
        <h3>{sourcingInfo.inStoreCount}</h3>
      </div>
      </div>
      </div>
    </>
  )}
</div>

<div className={`${styles.infoboxYellow} ${loadingInitialInfo && styles.loadinginfobox}`}>
  {!loadingInitialInfo && (
    <>
      <div>
        <span className="material-symbols-outlined">
          equal
        </span>
      </div>
      <div className={styles.info}>
        <h2>{sourcingInfo.totalCount}</h2>
        <p>TOTAL QUOTATIONS</p>
      </div>
    </>
  )}
</div>
            </div>
            <div className={styles.tableContainer}>
                <div className={styles.filterHeader}>
                <div className={styles.optionContainer}>
        {options.map((option) => (
          <button
            key={option}
            onClick={() => handleOptionClick(option)}
            className={`${styles.optionButton} ${
              activeOption === option ? styles.active : ""
            }`}
            >
            {option}
          </button>
        ))}
      </div>
                    <FilterComponent
                              onApplyFilters={handleApplyFilters}
          initialFilters={activeOption === 'Active' ? appliedFiltersActive : appliedFiltersArchived}
          statusOptions={activeOption === 'Active' ? statusOptions : []}
          searchPlaceholder="Search by Product Name..."
                    />
                </div>
                <table className={styles.table}>
                    <thead className={styles.mainThead}>
                        <tr>
                            <th colSpan={1}>Id</th>
                            <th colSpan={2}>Product Info</th>
                            <th>Date</th>
                            <th>Quotation Status</th>
                            <th colSpan={2}>Feedback</th>
                            <th colSpan={2}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            Array.from({ length: 5 }).map((_, index) => (
                                <tr key={index} className={`${styles.rows} ${styles.placeHolderRow}`}>
                                    <td colSpan={9}></td>
                                </tr>
                            ))
                        ) : !sourcingData || sourcingData.length === 0 ? (
                            <tr className={styles.rows}>
                                <td colSpan={9}>Currently no requests</td>
                            </tr>
                        ) : (
                            sourcingData.map(request => (
                                <tr key={request.id} className={styles.rows}>
                                    <td>{request.id}</td>
                                    <td colSpan={2}>
                                        <div className={styles.productInfo}>
                                            {request.image && 
                                                <img 
                                                    src={`/uploads/productImages/${request.image}`} 
                                                    alt={request.productName} 
                                                    className={styles.productImage} 
                                                    onClick={() => openImageModal(`/uploads/productImages/${request.image}`)} 
                                                />
                                            }
                                            <div className={styles.productInfoText}>
                                                <span>{request.productName}</span>
                                                <span>
                                                    <HoverableOverflowText text={request.description} fontSize={14}/>
                                                </span>
                                                <span>
                                                    <a href={request.url} target="_blank" rel="noopener noreferrer">
                                                        {request.url}
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td className={`${getTimeDifferenceClass(request.createdAt, request.status)}`}>
                                            {new Date(request.createdAt).toLocaleString('sv-SE', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: false
                                            })}
                                        </td>
                                    <td className={styles.quotationStatus}>
                                        {activeOption === "Archived" ?
                                        <>
                                        <span class="material-symbols-outlined"
                                        >
                                        archive
                                        </span>
                                        <p
                                        style={{ 
                                            display: 'inline', 
                                            marginLeft: '8px', 
                                        }}
                                        >
                                            Archived
                                        </p>
                                        </>
                                        :
                                        request.quotation && request.quotation.length > 0 && request.quotation[0].status ? (
                                            <>
                                                <span 
                                                    className="material-symbols-outlined" 
                                                    style={{ color: getStatusInfo(request.quotation[0].status).color }}
                                                >
                                                    {getStatusInfo(request.quotation[0].status).icon}
                                                </span>
                                                <p 
                                                    style={{ 
                                                        display: 'inline', 
                                                        marginLeft: '8px', 
                                                        color: getStatusInfo(request.quotation[0].status).color 
                                                    }}
                                                >
                                                    {getStatusInfo(request.quotation[0].status).text}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                <span 
                                                    className="material-symbols-outlined" 
                                                    style={{ color: getStatusInfo('Awaiting Response').color }}
                                                >
                                                    {getStatusInfo('Awaiting Response').icon}
                                                </span>
                                                <p 
                                                    style={{ 
                                                        display: 'inline', 
                                                        marginLeft: '8px', 
                                                        color: getStatusInfo('Awaiting Response').color 
                                                    }}
                                                >
                                                    {getStatusInfo('Awaiting Response').text}
                                                </p>
                                            </>
                                        )}
                                    </td>
                                    <td colSpan={2}>
                                        {request.quotation && request.quotation.length > 0 && (
                                            <HoverableOverflowText text={request.quotation[0].feedback} fontSize={14}/>
                                        )}
                                    </td>
                                    <td colSpan={2}>
                                        {!request.quotation ? (
                                            <div className={styles.buttons}>
                                                {!expiring && (
                                                    <>
                                                        <button 
                                                            onClick={() => handleActionClick('Respond', request.id)} 
                                                            className={styles.respondButton}
                                                        >
                                                            Respond
                                                        </button>
                                                        <button 
                                                            onClick={() => handleActionClick('Deny', request.id)} 
                                                            className={styles.denyButton}
                                                        >
                                                            Deny
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        ) : (
                                            <div className={styles.buttons}>
                                                {activeOption === "Active" &&
                                                <>
                                                {request.quotation && request.quotation[0].status === 'Pending' && !expiring && (
                                                    <>
                                                        <button 
                                                            onClick={() => handleActionClick('Respond', request.id)} 
                                                            className={styles.editButton}
                                                        >
                                                            Edit
                                                        </button>
                                                        <button 
                                                            onClick={() => openComparison(request.id)} 
                                                            className={styles.comparisonButton}
                                                        >
                                                            Comparison
                                                        </button>
                                                    </>
                                                )}
                                                <button 
                                                    onClick={() => openModal(request.id)} 
                                                    className={styles.actionsButton}
                                                >
                                                    Quotation
                                                </button>
                                                </>
                                                }
                                            </div>
                                        )}
                                    </td>
                                </tr>
                            ))
                        )}
                        <tr className={styles.rows}>
                            <td colSpan={9}>
                                <PaginationComponent
                                    itemsPerPage={itemsPerPage}
                                    setItemsPerPage={setItemsPerPage}
                                    totalPages={totalPages}
                                    currentPage={currentPage}
                                    handlePageChange={handlePageChange}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            {/* Comparison Modal */}
            {comparisonModal && comparisonData && (
                <Modal
                    isOpen={comparisonModal}
                    onRequestClose={closeComparison}
                    contentLabel="Quotation Comparison"
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                >
                    <div className={styles.modalContainer}>
                        <div className={styles.detailContainer}>
                            <div className={styles.detailItems}>
                                <h2 className={styles.detailItem}>
                                    Quotation Comparison
                                </h2>
                            </div>
                            <div className={styles.modalTableContainer}>
                                <table className={styles.variantsTable}>
                                    <thead className={styles.table_header}>
                                        <tr>
                                            <th></th>
                                            <th>Product Price</th>
                                            <th>
                                                <div className={styles.shippingCountry}>
                                                Shipping Price
                                            <React_Select
                                    size="small"
                                    value={comparisonSelectedCountry}
                                    onChange={setComparisonSelectedCountry}
                                    options={comparisonCountryOptions}
                                    className={styles.countrySelect}
                                    styles={customStyles}

                                />
                                </div>
                                            </th>
                                            <th>Variant Title</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {comparisonData.quotation.map((quotation, qIndex) => (
                                            <React.Fragment key={`quotation-${qIndex}`}>
                                                {/* Supplier row */}
                                                <tr 
            className={styles.supplierRow}
            style={{ backgroundColor: quotation.colorHex }} // Här tilldelas bakgrundsfärg
        >
            <td colSpan={4}>
                Supplier: {quotation.supplierName}
            </td>
        </tr>

                                                {/* If variants exist */}
                                                {quotation.variants && quotation.variants.length > 0 ? (
                                                    quotation.variants.map((variant, index) => (
                                                        <tr key={`variant-${qIndex}-${index}`} className={styles.rows}>
                                                            <td></td>
                                                            <td>€{variant.productPrice}</td>
                                                            <td>
                                                                €{variant.shippingPrices.find(sp => sp.country === comparisonSelectedCountry?.value)?.shippingPrice || 'N/A'}
                                                            </td>
                                                            <td>{variant.variantTitle || '-'}</td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    /* If noVariant exists */
                                                    quotation.noVariant && (
                                                        <tr key={`novariant-${qIndex}`} className={styles.rows}>
                                                            <td></td>
                                                            <td>€{quotation.noVariant.productPrice}</td>
                                                            <td>
                                                                €{quotation.noVariant.shippingPrices.find(sp => sp.country === comparisonSelectedCountry?.value)?.shippingPrice || 'N/A'}
                                                            </td>
                                                            <td>-</td>
                                                        </tr>
                                                    )
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </Modal>
            )}

            {/* Image Modal */}
            {modalIsOpen && selectedImage && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Image Details"
                    className={styles.modaltwo}
                    overlayClassName={styles.overlaytwo}
                >
                    <img src={selectedImage} alt="Selected" className={styles.largeImage} />
                </Modal>
            )}

            {/* Quotation Modal */}
            {modalIsOpen && responseDetail && (
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Quotation Details"
                    className={styles.modal}
                    overlayClassName={styles.overlay}
                >
                    <div className={styles.modalContainer}>
                        <div className={styles.detailContainer}>
                            <div className={styles.detailItems}>
                                <h3 className={styles.detailItem}>
                                    Quotation
                                </h3>
                                {responseDetail.comments && responseDetail.comments.length > 0 && (
                                    <p className={styles.detailItem}>
                                        <strong>Comments:</strong> {responseDetail.comments}
                                    </p>
                                )}
                            </div>
                            <div className={styles.countrySelectContainer}>
                                <p>Select Country for Shipping:</p>
                                <React_Select
                                    size="small"
                                    value={selectedCountry ? { value: selectedCountry.value, label: selectedCountry.value } : null}
                                    onChange={(option) => setSelectedCountry(option)}
                                    options={Array.from(new Set(
                                        responseDetail.noVariant 
                                            ? responseDetail.noVariant.shippingPrices.map(sp => sp.country)
                                            : responseDetail.variants.flatMap(variant => variant.shippingPrices.map(sp => sp.country))
                                    )).map(country => ({ value: country, label: country }))}
                                    className={styles.countrySelect}
                                />
                            </div>

                            {responseDetail?.variants && responseDetail.variants.length > 0 ? (
                                <div className={styles.modalTableContainer}>
                                    <table className={styles.variantsTable}>
                                        <thead className={styles.table_header}>
                                            <tr>
                                                <th>Product Image</th>
                                                <th>SKU</th>
                                                <th>Product Price</th>
                                                <th className={styles.dynamicShippingTH}>Shipping Price</th>
                                                {Array.from(new Set(responseDetail.variants.flatMap(variant =>
                                                    variant.categoryVariants.map(cv => cv.quotationCategory)
                                                ))).map((category, idx) => (
                                                    <th key={idx}>{category}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {responseDetail.variants.map((variant, index) => (
                                                <tr key={index} className={styles.rows}>
                                                    <td>
                                                        {variant.image && (
                                                            <img 
                                                                src={`/uploads/productImages/${variant.image}`} 
                                                                alt={variant.SKU} 
                                                                className={styles.quotationImage} 
                                                            />
                                                        )}
                                                    </td>
                                                    <td>{variant.SKU}</td>
                                                    <td>€{variant.productPrice}</td>
                                                    <td>
                                                        €{variant.shippingPrices.find(sp => sp.country === selectedCountry?.value)?.shippingPrice || 'N/A'}
                                                    </td>
                                                    {Array.from(new Set(responseDetail.variants.flatMap(variant =>
                                                        variant.categoryVariants.map(cv => cv.quotationCategory)
                                                    ))).map((category, idx) => {
                                                        const categoryVariant = variant.categoryVariants.find(cv => cv.quotationCategory === category);
                                                        return <td key={idx}>{categoryVariant ? categoryVariant.value : ''}</td>;
                                                    })}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ) : (
                                // För No Variants
                                responseDetail?.noVariant && (
                                    <div className={`${styles.tableContainer} ${styles.modalTableContainer}`}>
                                        <table className={styles.detailTable}>
                                            <thead>
                                                <tr className={styles.table_header}>
                                                    {responseDetail.noVariant.image && <th>Product Image</th>}
                                                    <th>Product Price</th>
                                                    <th>Shipping Price</th>
                                                    <th>SKU</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className={styles.rows}>
                                                    {responseDetail.noVariant.image && (
                                                        <td>
                                                            <img 
                                                                src={`/uploads/productImages/${responseDetail.noVariant.image}`} 
                                                                alt={responseDetail.noVariant.SKU} 
                                                                className={styles.quotationImage} 
                                                            />
                                                        </td>
                                                    )}
                                                    <td>€{responseDetail.noVariant.productPrice}</td>
                                                    <td>€{responseDetail.noVariant.shippingPrices.find(sp => sp.country === selectedCountry?.value)?.shippingPrice || 'N/A'}</td>
                                                    <td>{responseDetail.noVariant.SKU}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                </Modal>
            )}

        </div>
    );
};

export default Sourcing;
