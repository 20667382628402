import React, { useEffect, useState } from 'react';
import styles from './SupplierPayments.module.scss';
import adminService from '../../services/adminService';

const SupplierPayments = () => {
  const [suppliers, setSuppliers] = useState([]);
  const [unpaidOrders, setUnpaidOrders] = useState([]);
  const [showUnpaidOrders, setShowUnpaidOrders] = useState(false);
  const [dateRange, setDateRange] = useState({ startOfLastWeek: '', endOfLastWeek: '' });

  const setAsPaid = async (fulfillmentOrderIds) => {
    try {
      await adminService.setSupplierPayment(fulfillmentOrderIds);
      alert('Supplier payment status updated successfully.');
    } catch (error) {
      console.error('Error setting payment status:', error);
      alert('Failed to update payment status.');
    }
  };

  useEffect(() => {
    const getSummary = async () => {
      const data = await adminService.fetchWeeklySummary();
      setSuppliers(data.suppliers || []);
      setUnpaidOrders(data.unpaidFulfillmentOrders || []);
      setDateRange({
        startOfLastWeek: new Date(data.startOfLastWeek).toLocaleDateString(),
        endOfLastWeek: new Date(data.endOfLastWeek).toLocaleDateString()
      });
    };
    getSummary();
  }, []);

  const toggleProducts = (supplierId) => {
    const element = document.getElementById(`supplier-${supplierId}`);
    element.classList.toggle(styles.hidden);
  };

  const toggleUnpaidOrders = () => {
    setShowUnpaidOrders(!showUnpaidOrders);
  };

  return (
    <div className={styles.weeklySummary}>
      <h1 className={styles.title}>Weekly Supplier Summary</h1>
            {unpaidOrders.length > 0 && (
        <div className={styles.unpaidWarning}>
          <p>
            There are {unpaidOrders.length} unpaid orders from earlier weeks.
            <button onClick={toggleUnpaidOrders}>
              {showUnpaidOrders ? 'Hide Details' : 'Show Details'}
            </button>
          </p>
          {showUnpaidOrders && (
            <table className={styles.unpaidOrdersTable}>
              <thead>
                <tr>
                  <th>Order ID</th>
                  <th>Fulfillment Cost</th>
                  <th>Fulfilled At</th>
                </tr>
              </thead>
              <tbody>
                {unpaidOrders.map((order) => (
                  <tr key={order.id}>
                    <td>{order.id}</td>
                    <td>€{order.fulfillmentCost}</td>
                    <td>{new Date(order.fulfilledAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}

      {/* Datum för förra veckan */}
      <div className={styles.dateRange}>
        <p>Summary for: {dateRange.startOfLastWeek} - {dateRange.endOfLastWeek}</p>
      </div>

      {/* Tabell med leverantörernas resultat */}
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Supplier Name</th>
            <th>Total Fulfillment Cost</th>
            <th>Actions</th>
            <th>Products</th>
          </tr>
        </thead>
        <tbody>
          {suppliers.map((supplier) => (
            <React.Fragment key={supplier.id}>
              <tr className={styles.supplierRow}>
                <td>{supplier.name}</td>
                <td><strong>€{supplier.totalFulfillmentCost}</strong> (including -{supplier.totalShippingDiscount}€ shipping discount)</td>
                <td>
                  <button
                    className={styles.paidButton}
                    onClick={() => setAsPaid(supplier.fulfillmentOrderIds)}
                  >
                    Set as Paid
                  </button>
                </td>
                <td>
                  <button
                    className={styles.toggleButton}
                    onClick={() => toggleProducts(supplier.id)}
                  >
                    Toggle Products
                  </button>
                </td>
              </tr>
              <tr
                id={`supplier-${supplier.id}`}
                className={`${styles.productsRow} ${styles.hidden}`}
              >
                <td colSpan="4">
                  <table className={styles.productTable}>
                    <thead>
                      <tr>
                        <th>Product Name</th>
                        <th>SKU</th>
                        <th>Quantity</th>
                        <th>Price Range</th>
                        <th>Shipping Price Range</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supplier.products.map((product, index) => (
                        <tr key={index}>
                          <td>{product.name}</td>
                          <td>{product.sku}</td>
                          <td>{product.quantity}</td>
                          <td>€{product.productPriceRange}</td>
                          <td>€{product.shippingPriceRange}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SupplierPayments;
