import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import Select from 'react-select'; // Select för att byta mellan affiliate-program
import merchantService from '../../services/merchantService'; // Använder merchantService för API-anrop
import styles from './AffiliateDashboard.module.scss'; // CSS från dashboard
import '../../styles/commonElements.scss'; // Laddar in common styles för info boxar

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, ChartDataLabels);

const AffiliateDashboard = () => {
    const [affiliateInfo, setAffiliateInfo] = useState(null); // Håller information om affiliate-program
    const [affiliatePrograms, setAffiliatePrograms] = useState([]); // Håller affiliate-program att växla mellan
    const [selectedProgram, setSelectedProgram] = useState(null); // Det valda affiliate-programmet
    const [selectedProgramLink, setSelectedProgramLink] = useState(null); // Token-länk för valt program
    const [graphData, setGraphData] = useState(null); // Grafdata för de senaste 7 dagarna
    const [loading, setLoading] = useState(true); // Loading state
  
    // Hämtar alla affiliate-program vid sidstart
    const fetchAffiliatePrograms = async () => {
      try {
        const programs = await merchantService.fetchAffiliatePrograms();
        setAffiliatePrograms(programs); // Sätt in programmen i state
      } catch (error) {
        console.error('Error fetching affiliate programs:', error);
      }
    };

  // Hämtar information om affiliate-program när man byter program eller vid sidstart
  const fetchAffiliateInformation = async (programId = null) => {
    setLoading(true);
    try {
      const response = await merchantService.fetchAffiliateInformation(programId);
      setAffiliateInfo(response.affiliateData); // Hämta affiliate data
      setGraphData(response.earningsLastSevenDays || []); // Sätter grafdata för de senaste 7 dagarna
      setLoading(false);
    } catch (error) {
      console.error('Error fetching affiliate information:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAffiliatePrograms();
    fetchAffiliateInformation();
  }, []);

  // Skapar grafdata för de senaste 7 dagarna
  const createGraphData = () => {
    if (!graphData || graphData.length === 0) return null;
    const sortedGraphData = [...graphData].sort((a, b) => new Date(a.date) - new Date(b.date));
    const labels = sortedGraphData.map(data => data.date);
    const earnings = sortedGraphData.map(data => data.totalEarnings);

    return {
      labels,
      datasets: [
        {
          label: 'Earnings (Last 7 Days)',
          data: earnings,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          pointBackgroundColor: '#fff',
          borderWidth: 3,
          fill: true,
        },
      ],
    };
  };

  const graphOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
        align: 'end', // Flyttar legenden till höger
        labels: {
          font: { size: 12 },
          usePointStyle: true,
        },
      },
      datalabels: {
        align: 'top',
        color: '#555',
        font: { size: 12 },
        formatter: value => `${value}€`,
      },
      tooltip: {
        enabled: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: graphData && graphData.length > 0 ? Math.max(...graphData.map(data => data.totalEarnings)) + 2 : 10,
        ticks: { stepSize: 1, padding: 6 },
        grid: {
          drawOnChartArea: true,
          drawTicks: false,
          color: '#e0e0e0',
        },
        border: {
          display: false,
        },
      },
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
          padding: 2,
        },
        offset: true,
      },
    },
    elements: {
      line: {
        tension: 0.4,
      },
      point: {
        radius: 4,
        hoverRadius: 4,
        borderWidth: 2,
        backgroundColor: 'transparent',
        borderColor: '#555',
      },
    },
  };

  // Om det inte finns några affiliate-program visas en registreringsknapp
  if (!loading && !affiliatePrograms.length) {
    return (
      <div className={styles.affiliateDashboard}>
        <div className={styles.signupContainer}>
          <p>Join our affiliate program and earn commission for every order fulfilled through your referrals. <br />You get 0.01€ per order.</p>
          <button
            className={styles.signupButton}
            onClick={async () => {
              const url = await merchantService.signupAffiliate();
              await fetchAffiliatePrograms();
              await fetchAffiliateInformation();
            }}
          >
            Sign Up for Affiliate Program
          </button>
        </div>
      </div>
    );
  }

  // Om affiliate-program finns, visa informationen och grafen
  return (
    <div className={styles.affiliateDashboard}>
<div className={styles.programSelectContainer}>
<div className={styles.programSelector}>
        <Select
          options={affiliatePrograms}
          onChange={option => {
            const selectedValue = option ? option.value : null;
            const selectedLink = option ? option.tokenLink : null;
            setSelectedProgram(selectedValue);
            setSelectedProgramLink(selectedLink); // Uppdatera token-länken
            fetchAffiliateInformation(selectedValue);
          }}
          isClearable
          placeholder="Select Affiliate Program"
        />
      </div>

      {/* Visa token-länken om ett program är valt */}
      <div className={styles.tokenLink}>
      {!loading && affiliatePrograms.length > 0 && !selectedProgram && (
                        <>
                            {affiliatePrograms.map(program => (
                                <p key={program.value}>
                                    <strong>{program.label}:</strong> <a href={program.tokenLink} target="_blank" rel="noopener noreferrer">{program.tokenLink}</a>
                                </p>
                            ))}
                        </>
                    )}
                                        {!loading && selectedProgramLink && selectedProgram && (
                        <p><strong>Referral link: </strong><a href={selectedProgramLink} target="_blank" rel="noopener noreferrer">{selectedProgramLink}</a></p>
                    )}
      </div>
      </div>


      <div className={styles.infoContainer}>
        <div className={`${styles.infoboxYellow} ${loading && styles.loadinginfobox}`}>
          {!loading && (
            <>
              <div>
                <span className="material-symbols-outlined">supervisor_account</span>
              </div>
              <div className={styles.info}>
                <h2>{affiliateInfo?.totalReferrals}</h2>
                <p>Total Referrals</p>
              </div>
            </>
          )}
        </div>

        <div className={`${styles.infoboxGreen} ${loading && styles.loadinginfobox}`}>
          {!loading && (
            <>
              <div>
                <span className="material-symbols-outlined">shopping_cart</span>
              </div>
              <div className={styles.info}>
                <h2>{affiliateInfo?.totalPayments}</h2>
                <p>Affiliated Orders</p>
              </div>
            </>
          )}
        </div>

        <div className={`${styles.infoboxBlue} ${loading && styles.loadinginfobox}`}>
          {!loading && (
            <>
              <div>
                <span className="material-symbols-outlined">attach_money</span>
              </div>
              <div className={styles.info}>
                <h2>{affiliateInfo?.totalEarnings}€</h2>
                <p>Total Earnings</p>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Graf över intäkter från de senaste 7 dagarna */}
      <div className={styles.graph}>
        {!loading && graphData && graphData.length > 0 && (
          <Line data={createGraphData()} options={graphOptions} />
        )}
      </div>
    </div>
  );
};

export default AffiliateDashboard;
