import React, { useState, useEffect } from 'react';
import adminService from '../../services/adminService';
import FilterComponent from '../../components/FilterComponent';
import PaginationComponent from '../../components/PaginationComponent';
import styles from './Payments.module.scss';
import Modal from 'react-modal';

const Payments = () => {
    const [payments, setPayments] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [dateRange, setDateRange] = useState([]);
    const [appliedFilters, setAppliedFilters] = useState({
        dateRange: [],
        searchQuery: "",
      });

    const fetchPayments = async () => {
        try {
            let startDate, endDate;
            if (dateRange.length > 0) {
                startDate = dateRange[0].startDate;
                endDate = dateRange[0].endDate;
            }
            const response = await adminService.fetchAllPaymentsByPageAndFilters(currentPage, itemsPerPage, startDate, endDate, searchQuery);
            setPayments(response.payments || []);
            setTotalPages(response.totalPages);
        } catch (error) {
            console.error('Failed to fetch data:', error);
            setPayments([]);
        }
    };

    useEffect(() => {
        fetchPayments();
    }, [currentPage, itemsPerPage, appliedFilters]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });

        const sortedData = [...payments].sort((a, b) => {
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setPayments(sortedData);
    };


    const handlePageChange = (event, page) => {
        setCurrentPage(page);
    };

    const handleApplyFilters = (filters) => {
        setAppliedFilters(filters);
        setCurrentPage(1);
      };

    return (
        <div className={styles.container}>
                        <div className={styles.header}>
            <h1>Payments</h1>
            </div>
            <div className={styles.tableContainer}>
            <div className={styles.filterHeader}>
                <FilterComponent
          initialFilters={appliedFilters}
          searchPlaceholder="Search by Payment ID..."
          onApplyFilters={handleApplyFilters}
                />
            </div>
                <table className={styles.table}>
                    <thead className={styles.mainThead}>
                    <tr>
                                <th onClick={() => handleSort('paymentId')}>
                                        <div className={styles.thHeader}>
                                            Payment ID
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'paymentId' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'paymentId' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('totalAmount')}>
                                        <div className={styles.thHeader}>
                                            Total Amount
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'totalAmount' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'totalAmount' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('merchantName')}>
                                        <div className={styles.thHeader}>
                                            Merchant
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'merchantName' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'merchantName' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('createdAt')}>
                                        <div className={styles.thHeader}>
                                            Date Issued
                                            <div className={styles.sortingArrows}>
                                                <span className={`${styles.sort_arrow} ${styles.asc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'ascending' ? styles.active : ''}`}></span>
                                                <span className={`${styles.sort_arrow} ${styles.desc} ${sortConfig.key === 'createdAt' && sortConfig.direction === 'descending' ? styles.active : ''}`}></span>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                    </thead>
                    <tbody>
                        {payments.map(payment => (
                        <tr key={payment.paymentId} className={styles.rows}>
                            <td>{payment.paymentId}</td>
                            <td>{payment.merchantName}</td>
                            <td>€{payment.totalAmount}</td>
                            <td>{new Date(payment.createdAt).toLocaleDateString()}</td>
                        </tr>
                        ))}
                                            <tr className={styles.rows}>
                        <td colSpan={4}>
                        <PaginationComponent
                itemsPerPage={itemsPerPage}
                setItemsPerPage={setItemsPerPage}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
            />
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Payments;
